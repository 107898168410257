/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import eventBus from 'events';
import $ from 'jquery';

require('./bootstrap');

require('jquery-validation');

window.Vue = require('vue');
window.EventBus = new Vue();
import moment from 'moment';

import { Form, HasError, AlertError } from 'vform';
window.Form = Form;

import Gate from "./Gate";
Vue.prototype.$gate = new Gate(window.user);

import Swal from 'sweetalert2';

// IMPORT DI DATE PICKER
// https://www.npmjs.com/package/vue-datepicker-next

//import  Calendar from 'vue-datepicker-next';
//import 'vue-datepicker-next/index.css';



//////////// KENDO DATETIME ////////////////////////////////

import '@progress/kendo-ui/js/kendo.datetimepicker'
import '@progress/kendo-theme-default/dist/all.css'
import {
    Calendar,
    DateInput,
    DatePicker,
    DateRangePicker,
    DateTimePicker,
    MultiViewCalendar,
    TimePicker,
    DateinputsInstaller
} from '@progress/kendo-dateinputs-vue-wrapper'

Vue.use(DateinputsInstaller);

Vue.use(require('vue-moment'));
////////////////////////////////////////////////


//////////// ADD VUE-MATERIAL ////////////////////////////////
/*
import { MdButton, MdContent, MdTabs, MdCard, MdBottomBar, MdIcon, MdDialog, MdRadio } from 'vue-material/dist/components'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

Vue.use(MdButton)
Vue.use(MdContent)
Vue.use(MdTabs)
Vue.use(MdCard)
Vue.use(MdBottomBar)
Vue.use(MdIcon)
Vue.use(MdDialog)
Vue.use(MdRadio)
*/
import {MdIcon} from 'vue-material/dist/components'
Vue.use(MdIcon)

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import { Dialog, Window } from '@progress/kendo-vue-dialogs'

Vue.use(VueMaterial)



//////////////////////////////////////////////////

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
window.Swal = Swal;
window.Toast = Toast;

import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, {
    color: 'rgb(143, 255, 199)',
    failedColor: 'red',
    height: '3px'
  });

Vue.component(HasError.name, HasError)
Vue.component(AlertError.name, AlertError)


/**
 * Routes imports and assigning
 */
import VueRouter from 'vue-router';
Vue.use(VueRouter);
import routes from './routes';
import Vue from "vue";

import vue_moment from "vue-moment";

const router = new VueRouter({
    mode: 'history',
    routes
});
// Routes End


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

//Vue.component('test', require('./components/Test'));
// Components
Vue.component('pagination', require('laravel-vue-pagination'));
Vue.component('dashboard', require('./components/Dashboard.vue'));


Vue.component(
    'passport-clients',
    require('./components/passport/Clients.vue').default
);
/*
Vue.component(
    'vehicles',
    require('./components/ev/EVModelsView.vue').default
);
*/
Vue.component(
    'passport-personal-access-tokens',
    require('./components/passport/PersonalAccessTokens.vue').default
);

Vue.component(
    'passport-personal-access-tokens',
    require('./components/passport/PersonalAccessTokens.vue').default
);

Vue.component(
    'not-found',
    require('./components/NotFound.vue').default
);

// Filter Section

Vue.filter('myDate',function(created){
    return moment(created).format('MMMM Do YYYY');
});

Vue.filter('yesno', value => (value ? '<i class="fas fa-check green"></i>' : '<i class="fas fa-times red"></i>'));

// end Filter

Vue.component('example-component', require('./components/ExampleComponent.vue').default);

Vue.component('md-form', require('./components/core/Form.vue').default);
Vue.component('form-row', require('./components/core/FormRow.vue').default);
Vue.component('chart', require('./components/core/Chart.vue').default);
Vue.component('curve-builder', require('./components/core/CurveBuilder.vue').default);
Vue.component('curve-builder-dash', require('./components/core/CurveBuilder_Dash.vue').default);
Vue.component('datetime-picker', require('./components/core/DateTimePicker.vue').default);
Vue.component('typeahead', require('./components/core/TypeAhead.vue').default);
Vue.component('load-area', require('./components/core/LoadArea.vue').default);
Vue.component('load-area-ict', require('./components/core/LoadAreaICT.vue').default);
Vue.component('request-log', require('./components/core/RequestLog.vue').default);
Vue.component('load-area-select', require('./components/core/LoadAreaSelect.vue').default);
Vue.component('application-select', require('./components/core/ApplicationSelect.vue').default);
Vue.component('simulation-panel', require('./components/core/SimulationPanel.vue').default);
Vue.component('simulation-panel-evos', require('./components/core/SimulationPanelEvos.vue').default);
Vue.component('lm-target', require('./components/dev/LMTargetView_Dash.vue').default);
Vue.component('spinner', require('./components/core/Spinner.vue').default);
Vue.component('autocomplete', require('./components/core/Autocomplete.vue').default);
Vue.component('full-calendar', require('./components/core/FullCalendar.vue').default);
Vue.component('scheduler', require('./components/core/Scheduler.vue').default);
Vue.component('request-notification', require('./components/dev/LMChargingRequestNotificationView.vue').default);
Vue.component('api-log', require('./components/ApiLog').default);
Vue.component('countdown', require('./components/core/Countdown.vue').default);


//5GSOLUTIONS
/*
Vue.component('load-area-5GS', require('./components/5GSolutions/LoadArea5GS.vue').default);
Vue.component('load-area-select-5GS', require('./components/5GSolutions/LoadAreaSelect5GS.vue').default);
*/
import store from './store'
import VueI18n from 'vue-i18n';
import en from './i18n/en.json';
import it from './i18n/it.json';

Vue.use(VueI18n);

const messages = { en, it };

const i18n = new VueI18n({
    locale: localStorage.getItem('locale') || 'en', // Default to 'en' or fetch from localStorage
    fallbackLocale: 'en', // Fallback locale if translation not found
    messages, // Localization messages
});

const app = new Vue({
    el: '#app',
    router,
    store:store,
    i18n,
    data: {
        currentLocale: localStorage.getItem('locale') || 'en', // Default to 'en' or fetch from localStorage
    },
    watch: {
        currentLocale(newLocale) {
            this.$i18n.locale = newLocale;
            localStorage.setItem('locale', newLocale); // Save selected locale to localStorage
        }
    },
    components: {
        Calendar,
        DateInput,
        DatePicker,
        DateRangePicker,
        DateTimePicker,
        MultiViewCalendar,
        TimePicker,
        //MdButton,
        //MdContent,
        //MdTabs
    },

});




/*
// require vue
window.Vue = require('vue');

//name the id app
Vue.component('front-page', require('/components/Front.vue').default

//load the front component
 const app = new Vue({
    el: '#app',
});
*/

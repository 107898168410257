import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        application: 'Testing',
        iteration: 0,
        loadArea: {
            id : "",
            start: 0,
            end: 0,
            status: 0,
        },
        simulation: {
            running: false,
            scheduling: false,
            computing: false,
            fast: false
        },
        samplingTime: '',
        timeframe: 'Last 7 Days'
    }
});

export default store

export default [
    { path: '/dashboard', component: require('./components/LoadAreaView').default },
    //{ path: '/5GSolutions', component: require('./components/5GSolutions/LoadAreaView5GS.vue').default },
    { path: '/profile', component: require('./components/Profile.vue').default },
    { path: '/developer', component: require('./components/Developer.vue').default },
    { path: '/users', component: require('./components/ev/Users.vue').default },
    { path: '/products', component: require('./components/product/Products.vue').default },
    { path: '/product/tag', component: require('./components/product/Tag.vue').default },
    { path: '/product/category', component: require('./components/product/Category.vue').default },
    { path: '*', component: require('./components/NotFound.vue').default },
    { path: '/models', component: require('./components/ev/EVModelsView.vue').default },
    { path: '/vehicles', component: require('./components/ev/EVehiclesView.vue').default },
    { path: '/la_update', component: require('./components/dev/LAUpdateView.vue').default },
    { path: '/test2', component: require('./components/Test2.vue').default },
    { path: '/loadarea', component: require('./components/LoadArea.vue').default },
    { path: '/macroloadarea', component: require('./components/MacroLoadArea.vue').default },
    { path: '/pods', component: require('./components/sessions/PodsView.vue').default },
    //{ path: '/load-area-select', component: require('./components/sessions/LoadAreaSelect.vue').default },
    { path: '/la_target', component: require('./components/dev/LMTargetView.vue').default },

    { path: '/charge-points', component: require('./components/ChargePointsView.vue').default },
    { path: '/connectors', component: require('./components/ConnectorsView.vue').default },
    { path: '/ocpp-connectors', component:  require ('./components/OcppConnectorsView.vue').default },
    { path: '/ocpi-connectors', component:  require ('./components/OcpiConnectorsView.vue').default },
    { path: '/connector-type', component:  require ('./components/ConnectorsTypeView.vue').default },
    { path: '/connector-format', component:  require ('./components/ConnectorsFormatView.vue').default },
    { path: '/evses', component: require('./components/EVSEsView.vue').default },
    { path: '/planning', component: require('./components/Services/Planning.vue').default },
    { path: '/analytics', component: require('./components/Services/PlanningAnalitics.vue').default },
    { path: '/analytics/uploadSuccessful', component: require('./components/Services/UploadSuccessful.vue').default },
    { path: '/analytics/downloadSuccessful', component: require('./components/Services/DownloadSuccessful.vue').default },
    { path: '/analytics/runsFailed', component: require('./components/Services/RunsFailed.vue').default },
    { path: '/analytics/runSuccessful', component: require('./components/Services/RunSuccesful.vue').default },

];

// config.js
module.exports = {
    MINUTE: 60,
    MINUTE_MILLISECONDS: 60 * 1000,
    HOUR: 60 * 60,
    HOUR_MILLISECONDS: 60 * 60 * 1000,
    SLOT_SAMPLE: 1,
    COLORS: {
            //CURVES: ['#2b908f', '#90ee7e'],
            SESSIONS:['#f45b5b', '#7798BF', '#aaeeee', '#ff0066', '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
            CURVES: [ '#2b908f', '#f45b5b',  '#00ff00'],
            //SESSIONS: ['#0555FA', '#55BE5A', '#FF5A0F', '#ff0066', '#f45b5b', '#7798BF', '#aaeeee']
    }

}

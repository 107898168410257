<template>

    <div>

        <md-card>

            <md-card-header>
                <div class="pull-left md-title">LM Target</div>

            </md-card-header>

            <md-card-content>

                <div class="form-group">
                    <form-row label="LoadAreaID">
                        <md-field>
                            <select v-model="LoadAreaID" name="LoadAreaID" id="LoadAreaID">
                                <option v-for="(loadarea, index) in LoadAreas" v-bind:value="loadarea.LoadAreaID">{{
                                    loadarea.LoadAreaID }}
                                </option>
                            </select>
                        </md-field>
                    </form-row>
                    <form-row label="StartOfLMR">
                        <kendo-datetimepicker @change="updateStartOfLMR"
                                              :format="'yyyy-MM-dd HH:mm:ss'"
                                              :stepping="Stepping"
                                              :value="new Date()"
                                              label="StartOfLMR"></kendo-datetimepicker>
                    </form-row>
                    <form-row label="TargetLoadCurve">
                        <curve-builder :start-time="Request.startOfLMR" :on-change="updateTargetLoadCurve"
                                       :stepping="Stepping" :steps="TargetSteps"></curve-builder>
                    </form-row>
                    <form-row label="PowerThreshold">
                        <curve-builder :start-time="Request.startOfLMR" :on-change="updatePowerThreshold"
                                       :stepping="Stepping" :steps="ThresholdSteps"></curve-builder>
                    </form-row>

                    <form-row label="PowerThresholdV2G">
                        <curve-builder :start-time="Request.startOfLMR" :on-change="updatePowerThresholdV2G"
                                       :stepping="Stepping" :steps="V2GSteps"></curve-builder>
                    </form-row>
                </div>

            </md-card-content>
            <md-button class="md-raised md-accent pull-right" @click.native="submitData">Submit
            </md-button>
        </md-card>
        <div class="row">
            <textarea class="col-md-6" style="min-height: 150px; background-color: #eeeeee"
                      readonly>{{ Request }}</textarea>
            <textarea class="col-md-6" style="min-height: 150px; background-color: #eeeeee"
                      readonly>{{ Response }}</textarea>
        </div>


    </div>

</template>

<script>
    import moment from 'moment'
    var chart_utils = require('../../utils/chart');
    var curve_utils = require('../../utils/curve');
    var helper_utils = require('../../utils/helper');
    var config = require('../../config');
    import {DateTimePicker} from "@progress/kendo-dateinputs-vue-wrapper";

    export default {
        components: {
            'kendo-datetimepicker': DateTimePicker
        },
        /**
         * Prepare the component (Vue 2.x).
         */
        mounted() {
            this.getLoadAreaIDs();
            this.Request.startOfLMR = moment(new Date()).format();
              EventBus.$on('applicationchanged', () => {
                this.getLoadAreaIDs();
                });
        },
        data() {
            return {
                Request : {
                            AreaType: "1",
                            DSORequestID: "DSO_Request",
                            startOfLMR : "",
                            TargetLoadCurve: "",
                            PowerThreshold: "",
                            LAMinPowerPowerThreshold: "",
                            Priority: 5,
                            energyType: "0",
                            eventDuration: 24
                            },
                Response : {},
                LoadAreas: [],
                LoadAreaID: "",
                TargetSteps: [],
                ThresholdSteps: [],
                V2GSteps: [],
                Stepping: config.SLOT_SAMPLE
                }
         },
          watch: {
            'LoadAreaID': {
                handler (newData, oldData) {
                     this.Request.AreaID = newData;
                     this.fetchLAData(newData);
                 }
            }
         },
         methods: {
            updateStartOfLMR(date){
               this.Request.startOfLMR = moment(date.sender._value).format();
               console.log(this.Request.startOfLMR);
            },
            updateTargetLoadCurve(loadcurve){
               this.Request.TargetLoadCurve = loadcurve;
            },
            updatePowerThreshold(loadcurve){
               this.Request.PowerThreshold = loadcurve;
            },
            updatePowerThresholdV2G(loadcurve){
                this.Request.LAMinPowerPowerThreshold = loadcurve;
            },
            updateRequest(key, value){
                this.Request[key] = value;
            },

            changeSessionView(i){
                this.$refs.bottombar.$children[i+1].setActive(true);
            },
            prepareComponent() {

            },
            getLoadAreaIDs() {
                axios.get('api/LA/ids')
                        .then((response) => {
                            this.LoadAreas = response.data;
                            console.log(this.LoadAreas);
                });
            },
            fetchLAData() {
                axios.get('api/LA/' + this.LoadAreaID+'/Sessions')
                        .then((response) => {
                            this.ThresholdSteps = curve_utils.curveToSteps(response.data.LAPowerThreshold);
                            this.TargetSteps = curve_utils.curveToSteps(response.data.LATargetLoadCurve);
                            this.V2GSteps = curve_utils.curveToSteps(response.data.LAMinPowerThreshold);
                            }, (response) => {
                               helper_utils.notify(response.data);
                            });
            },
            submitData() {
                axios.post('api/CPO/LM_Target', this.Request)
                        .then((response) => {
                        this.Response = response.data;
                            helper_utils.alert(response.data);
                        // success callback
                        }, (response) => {
                        // error callback
                        this.Response = response.data;
                            helper_utils.alert(response.data);
                        });
            }
        }
   }




</script>

<template>
    <form @submit="submit">
            <slot></slot>
    </form>
</template>

<script>
    export default {
        props: {
            onSuccess:{
                required: true,
                default: null
                },
            onError:{
                required: true,
                default: null
                },
            method:{
                required: true,
                default: "post"
                },
            action:{
                required: true,
                default: null
                }
        },
        mounted() {
            console.log('Component ready.')
        },
        methods: {
            submit: function(event) {
                event.preventDefault();

                this.$http[this.method](this.action, new FormData(this.$el)).then((response) => {
                                this.onSuccess(response.data);
                            }, (response) => {
                        // error callback
                            if (response.data.msg === 'ValidationError') {
                                var errors = response.data.data.errors;
                                for (var property in errors) {
                                    if (errors.hasOwnProperty(property)) {
                                        $("[name='"+ property + "']").parent().addClass('md-input-invalid');
                                        $("[name='"+ property + "']").siblings('.md-error').text(errors[property][0]);
                                    }
                                }
                            } else {
                                this.onError(response.data);
                            }

                        })
            }
        }
    }
</script>

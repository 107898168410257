<template>
    <div></div>
</template>

<script>
    var Highcharts = require('highcharts');
    require('highcharts/themes/dark-unica')(Highcharts);
    require('highcharts/modules/exporting')(Highcharts);
    //require('highcharts/export-csv')(Highcharts);

    Highcharts.setOptions({global: {useUTC: false}});

    export default {
        name: "Chart",
        props: {
            series: {
                type: Array,
                required: true
            },
            ytitle: {
                type: String,
                default: 'Power (kW)',
                required: false
            },
            timestamp: {
                type: Number,
                required: false
            }
        },
        data: function () {
            return {
                target: undefined,
                zoom: false
            }
        },
        methods: {
            updatePlotBand: function (ts) {
                if (this.target.xAxis[0].plotLinesAndBands[0] !== undefined) {
                    this.target.xAxis[0].plotLinesAndBands[0].options.from = 0;
                    this.target.xAxis[0].plotLinesAndBands[0].options.to = ts * 1000;
                } else {
                    this.target.xAxis[0].addPlotBand({
                        from: 0,
                        to: ts * 1000,
                        color: 'rgba(255,255,255,0.2)',
                        id: "plot-band",
                        zIndex: 1
                    });
                }

            },
            setExtremes: function (ts) {
                    this.target.xAxis[0].setExtremes((ts - 3600) * 1000, (ts + 3600) * 1000);

            }
        },
        watch: {
            'timestamp': {
                handler(newData, oldData) {
                    //newData += 7200;
                    if (newData !== oldData) {
                        this.updatePlotBand(newData);
                        if (!this.zoom){
                            this.setExtremes(newData);
                        }

                    }
                }
            },
            'ytitle': {
                handler(newData, oldData) {
                    if (newData !== oldData) {
                        this.target.yAxis[0].setTitle({text: newData});
                    }
                }
            },
            'series': {
                handler(newData, oldData) {


                    var oldSeriesLength = oldData.length;
                    var newSeriesLength = newData.length;

                    var i;
                    for (i = 0; i < Math.max(oldSeriesLength, newSeriesLength); i++) {
                        if (i < oldSeriesLength) {
                            if (i < newSeriesLength) {
                                this.target.series[i].update(newData[i], false);
                            }
                            else {
                                this.target.series[this.target.series.length - 1].remove(false);
                            }
                        } else {
                            if (i < newSeriesLength) {
                                this.target.addSeries(newData[i], false);
                            }
                        }
                    }
                    this.target.colorCounter = this.target.series.length - 1;
                    this.target.symbolCounter = this.target.series.length - 1;
                    this.target.redraw();
                }
            }
        },
        mounted: function () {
            var vm = this;
            this.target = Highcharts.chart(this.$el, {
                title: {
                    text: ''
                },
                subTitle: {
                    text: ''
                },
                xAxis: {
                    type: 'datetime',
                    events: {
                        afterSetExtremes: function () {
                            //vm.updatePlotBand(vm.timestamp);
                        }
                    }
                },
                yAxis: {
                    plotLines: [{
                        value: 0,
                        width: 1,
                        color: '#808080'
                    }]
                },
                tooltip: {
                    valueSuffix: ''
                },
                chart: {
                    zoomType: 'x', //,
                    //backgroundColor: '#FFFFFF',
                    //polar: true,
                    //type: 'line'
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            enabled: true
                        },
                        toggleButton: {
                            text: 'Zoom Out',
                            onclick: function () {
                                vm.zoom = !vm.zoom;
                                var button = this.exportSVGElements[2];
                                button.attr({
                                    text: vm.zoom ? "Zoom Out" : "Zoom In"
                                });
                                vm.updatePlotBand(vm.timestamp);// + 7200);
                                if (vm.zoom) {
                                    this.zoomOut();
                                } else {
                                    vm.setExtremes(vm.timestamp);// + 7200);
                                }

                            }
                        }
                    }
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        pointPadding: 0,
                        groupPadding: 0,
                        borderWidth: 1

                    },
                    series: {
                        step: 'left' // or 'center' or 'right'
                    }
                },
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom',
                    borderWidth: 0
                },
                series: this.series,
            });

        },
        beforeDestroy: function () {
            this.target.destroy();
        },
    }

</script>

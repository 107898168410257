<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card" v-if="$gate.isAdmin()">
                        <div class="card-header">
                            <h3 class="card-title">Evse List</h3>
                            <div class="card-tools">
                                <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                                    <i class="fa fa-plus-square"></i>
                                    Add New
                                </button>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th>id</th>
                                    <th>created_at</th>
                                    <th>updated_at</th>
                                    <th>status</th>
                                    <th>status_schedule</th>
                                    <th>capabilities</th>
                                    <th>connectors</th>
                                    <th>floor_level</th>
                                    <th>coordinates</th>
                                    <th>physical_reference</th>
                                    <th>directions</th>
                                    <th>parking_restrictions</th>
                                    <th>images</th>
                                    <th>last_updated</th>
                                    <th>label</th>
                                    <th>ocpp_evse_id</th>
                                    <th>charging_station</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="evse in Evses" :key="Evses.id">
                                    <td class="text-capitalize">{{ evse.id }}</td>
                                    <td class="text-capitalize">{{ evse.created_at }}</td>
                                    <td class="text-capitalize">{{ evse.updated_at }}</td>
                                    <td class="text-capitalize">{{ evse.status }}</td>
                                    <td class="text-capitalize">{{ evse.status_schedule }}</td>
                                    <td class="text-capitalize">{{ evse.capabilities }}</td>
                                    <td class="text-capitalize">{{ evse.connectors }}</td>
                                    <td class="text-capitalize">{{ evse.floor_level }}</td>
                                    <td class="text-capitalize">{{ evse.coordinates }}</td>
                                    <td class="text-capitalize">{{ evse.physical_reference }}</td>
                                    <td class="text-capitalize">{{ evse.directions }}</td>
                                    <td class="text-capitalize">{{ evse.parking_restrictions }}</td>
                                    <td class="text-capitalize">{{ evse.images }}</td>
                                    <td class="text-capitalize">{{ evse.last_updated }}</td>
                                    <td class="text-capitalize">{{ evse.label }}</td>
                                    <td class="text-capitalize">{{ evse.ocpp_evse_id }}</td>
                                    <td class="text-capitalize">{{ evse.charging_station }}</td>
                                    <td>
                                        <a href="#" @click="editModal(evse)">
                                            <i class="fa fa-edit blue"></i>
                                        </a>
                                        /
                                        <a href="#" @click="deleteEvse(Evses.id)">
                                            <i class="fa fa-trash red"></i>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer">
                            <pagination :data="Evses" @pagination-change-page="getResults"></pagination>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>

            <div v-if="!$gate.isAdmin()">
                <not-found></not-found>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" v-show="!editmode">Create New Evse</h5>
                            <h5 class="modal-title" v-show="editmode">Update Evse Info</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <form @submit.prevent="editmode ? updateEvse() : createEvse()">
                            <div class="modal-body">
                                <div class="form-group">
                                    <label>charging_station</label>
                                    <input v-model="form.charging_station" type="text" name="charging_station"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('charging_station') }">
                                </div>
                                <div class="form-group">
                                    <label>created_at</label>
                                    <!-- Input per visualizzare o modificare 'created_at', se necessario -->
                                    <input v-model="form.created_at" type="text" name="created_at"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('created_at') }">
                                </div>
                            </div>
                            <!-- Aggiungi altri campi qui -->
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                                <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
import { DateTimePicker } from '@progress/kendo-dateinputs-vue-wrapper';
import moment from 'moment';

export default {
    components: {
        'kendo-datetimepicker': DateTimePicker
    },
    data() {
        return {
            editmode: false,
            Evses: {},
            form: new Form({
                id: '',
                created_at: '',
                updated_at: '',
                status: '',
                status_schedule: '',
                capabilities: '',
                connectors: '',
                floor_level: '',
                coordinates: '',
                physical_reference: '',
                directions: '',
                parking_restrictions: '',
                images: '',
                last_updated: '',
                label: '',
                ocpp_evse_id: '',
                charging_station: ''
            }),
            mla: [],
            la: []
        };
    },
    filters: {
        moment: function (date) {
            return moment(date).format('YYYY-MM-DD hh:mm:ss');
        }
    },
    methods: {
        newModal() {},

      loadEvses() {
        axios.get('api/EVSE/getEVSEs').then((response) => {
          this.Evses = response.data;
        }).catch((error) => {
          console.error('Error fetching connectors:', error);
        });
      },
        updateEvse() {

        },
        createEvse() {

        },
        deleteEvse(id) {

        },

    },
    created() {
        this.loadEvses();
    }
};
</script>

<template>
    <div>

        <vuetable-pagination-info v-if="paginate" ref="paginationInfo" :css="css.paginationInfo"
        ></vuetable-pagination-info>

        <vuetable-pagination v-if="paginate" ref="pagination"
                             @vuetable-pagination:change-page="onChangePage"
                             :css="css.pagination"></vuetable-pagination>
        <vuetable ref="vuetable"
                  api-url="LogRequest/dt_ajax"
                  pagination-path=""
                  :per-page="limit"
                  :css="css.table"
                  :sort-order="sortOrder"
                  :fields="fields"
                  pagination-component="vuetable-pagination"
                  @vuetable:pagination-data="onPaginationData"
                  @vuetable:loading="onLoading"
                  @vuetable:loaded="onLoaded"
        >

            <template slot="RequestMethod" slot-scope="props">
                <div class="label label-warning" v-html="props.rowData.RequestMethod" v-if="props.rowData.RequestMethod == 'POST'"></div>
                <div class="label label-success" v-html="props.rowData.RequestMethod" v-else-if="props.rowData.RequestMethod == 'GET'"></div>
                <div class="label label-default" v-html="props.rowData.RequestMethod" v-else></div>
            </template>
            <template slot="RequestPath" slot-scope="props">
                <md-chip v-html="props.rowData.RequestPath"></md-chip>
            </template>
            <template slot="RequestBody" slot-scope="props">
                <div class="small" v-json-formatter="props.rowData.RequestBody"></div>
            </template>

            <template slot="ResponseBody" slot-scope="props">
                <div class="small" v-json-formatter="props.rowData.ResponseBody"></div>
            </template>
        </vuetable>

    </div>
</template>

<script>
    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
    //import JsonFormatter from 'json-formatter-js'

    export default {
        components: {
            Vuetable,
            VuetablePagination,
            VuetablePaginationInfo
        },

        props: {
            limit: {
                type: Number,
                required: true,
                default:  100
            },
            paginate: {
                type:Boolean,
                default: false

            }
        },
        mounted() {
            console.log('RequestLog ready.');

        },
        data() {
            return {
                fields: [
                    {
                        name: 'RequestAddress',
                        title: 'RequestAddress',
                        sortField: 'RequestAddress'
                    },
                    {
                        name: '__slot:RequestMethod',
                        title: 'RequestMethod',
                        sortField: 'RequestMethod'
                    },
                    {
                        name: '__slot:RequestPath',
                        title: 'RequestPath',
                        sortField: 'RequestPath'
                    },
                    {
                        name: '__slot:RequestBody',
                        title: 'RequestBody',
                        sortField: 'RequestBody'
                    },
                    {
                        name: '__slot:ResponseBody',
                        title: 'ResponseBody',
                        sortField: 'ResponseBody'
                    },
                    {
                        name: 'created_at',
                        title: 'created_at',
                        sortField: 'created_at'
                    },
                    {
                        name: 'updated_at',
                        title: 'updated_at',
                        sortField: 'updated_at'
                    }
                ],
                css: {
                    table: {
                        tableClass: 'table table-striped table-bordered table-hovered',
                        loadingClass: 'loading',
                        ascendingIcon: 'glyphicon glyphicon-chevron-up',
                        descendingIcon: 'glyphicon glyphicon-chevron-down',
                        handleIcon: 'glyphicon glyphicon-menu-hamburger',
                    },
                    pagination: {
                        infoClass: 'pull-left',
                        wrapperClass: 'md-table-pagination pull-right',
                        activeClass: 'md-accent md-raised',
                        disabledClass: 'disabled',
                        pageClass: 'md-button md-icon-button md-theme-default',
                        linkClass: 'md-button md-icon-button md-theme-default',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    },
                    paginationInfo : {
                        infoClass: 'pull-left',
                    }
                },
                sortOrder: [
                    { field: 'created_at', direction: 'desc' }
                ]
            }
        },
        computed: {},
        methods: {
            onPaginationData(paginationData){
                if (this.paginate){
                this.$refs.pagination.setPaginationData(paginationData)
                this.$refs.paginationInfo.setPaginationData(paginationData)
                }
                },
            onChangePage(page){
                this.$refs.vuetable.changePage(page);
            },
            onLoading(){
                this.$Progress.start();
                console.log("loading");
            },
            onLoaded(){
                this.$Progress.finish();
                console.log("loaded");
            },
            refresh(){
                this.$refs.vuetable.refresh()
            }
        }
    }
</script>

<template>
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card" v-if="$gate.isAdmin()">
            <div class="card-header">
              <h3 class="card-title">Connectors List</h3>
              <div class="card-tools">
                <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                  <i class="fa fa-plus-square"></i>
                  Add New
                </button>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-0">
              <table class="table table-hover">
                <thead>
                <tr>
                  <th>id</th>
                  <th>evse</th>
                  <th>standard</th>
                  <th>format</th>
                  <th>power_type</th>
                  <th>max_voltage</th>
                  <th>max_amperage</th>
                  <th>max_electric_power</th>
                  <th>tariff_ids</th>
                  <th>terms_and_conditions</th>
                  <th>last_updated</th>
                  <th>created_at</th>
                  <th>label</th>
                  <th>ocpp_connector_id</th>
                  <th>connector_type</th>
                  <th>actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="connector in connectors.data" :key="connector.id">
                  <td>{{ connector.id }}</td>
                  <td>{{ connector.evse }}</td>
                  <td>{{ connector.standard }}</td>
                  <td>{{ connector.format }}</td>
                  <td>{{ connector.power_type }}</td>
                  <td>{{ connector.max_voltage }}</td>
                  <td>{{ connector.max_amperage }}</td>
                  <td>{{ connector.max_electric_power}}</td>
                  <td>{{ connector.tariff_ids }}</td>
                  <td>{{ connector.terms_and_conditions }}</td>
                  <td>{{ connector.updated_at | moment}}</td>
                  <td>{{ connector.created_at | moment }}</td>
                  <td>{{ connector.label }}</td>
                  <td>{{ connector.ocpp_connector_id }}</td>
                  <td>{{ connector.connector_type }}</td>

                  <td>
                    <a href="#" @click="editModal(connector)">
                      <i class="fa fa-edit blue"></i>
                    </a>
                    /
                    <a href="#" @click="deleteConnector(connector.id)">
                      <i class="fa fa-trash red"></i>
                    </a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <pagination :data="connectors" @pagination-change-page="getResults"></pagination>
            </div>
          </div>
          <!-- /.card -->
        </div>
      </div>

      <div v-if="!$gate.isAdmin()">
        <not-found></not-found>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" v-show="!editmode">Create New Connector</h5>
              <h5 class="modal-title" v-show="editmode">Update Connector Info</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <form @submit.prevent="editmode ? updateConnector() : createConnector()">
              <div class="modal-body">

                <div class="form-group">
                  <label>evse</label>
                  <input v-model="form.evse" type="text" name="evse"
                         class="form-control" :class="{ 'is-invalid': form.errors.has('evse') }">
                </div>

                <div class="form-group">
                  <label>standard</label>
                  <input v-model="form.standard" type="text" name="standard"
                         class="form-control" :class="{ 'is-invalid': form.errors.has('standard') }">
                </div>
                <div class="form-group">
                  <label>format</label>
                  <input v-model="form.format" type="text" name="format"
                         class="form-control" :class="{ 'is-invalid': form.errors.has('format') }">
                </div>
                <div class="form-group">
                  <label>power_type</label>
                  <input v-model="form.power_type" type="text" name="power_type"
                         class="form-control" :class="{ 'is-invalid': form.errors.has('power_type') }">
                </div>
                <div class="form-group">
                  <label>max_voltage</label>
                  <input v-model="form.max_voltage" type="text" name="max_voltage"
                         class="form-control" :class="{ 'is-invalid': form.errors.has('max_voltage') }">
                </div>
                <div class="form-group">
                  <label>max_amperage</label>
                  <input v-model="form.max_amperage" type="text" name="max_amperage"
                         class="form-control" :class="{ 'is-invalid': form.errors.has('max_amperage') }">
                </div>
                <div class="form-group">
                  <label>max_electric_power</label>
                  <input v-model="form.max_electric_power" type="text" name="max_electric_power"
                         class="form-control" :class="{ 'is-invalid': form.errors.has('max_electric_power') }">
                </div>
                <div class="form-group">
                  <label>tariff_ids</label>
                  <input v-model="form.tariff_ids" type="text" name="tariff_ids"
                         class="form-control" :class="{ 'is-invalid': form.errors.has('tariff_ids') }">
                </div>
                <div class="form-group">
                  <label>terms_and_conditions</label>
                  <input v-model="form.terms_and_conditions" type="text" name="terms_and_conditions"
                         class="form-control" :class="{ 'is-invalid': form.errors.has('terms_and_conditions') }">
                </div>
                <!--
                <div class="form-group">
                  <label>last_updated</label>
                  <kendo-datetimepicker class="form-control"
                                        :format="'yyyy-MM-dd hh:mm:ss'"
                                        v-model="form.last_updated">
                  </kendo-datetimepicker>
                </div>
                <div class="form-group">
                  <label>created_at</label>
                  <kendo-datetimepicker class="form-control"
                                        :format="'yyyy-MM-dd hh:mm:ss'"
                                        v-model="form.created_at">
                  </kendo-datetimepicker>
                </div>
                 -->
                <div class="form-group">
                  <label>label</label>
                  <input v-model="form.label" type="text" name="label"
                         class="form-control" :class="{ 'is-invalid': form.errors.has('label') }">
                </div>

                <div class="form-group">
                  <label>ocpp_connector_id</label>
                  <input v-model="form.ocpp_connector_id" type="text" name="ocpp_connector_id"
                         class="form-control" :class="{ 'is-invalid': form.errors.has('ocpp_connector_id') }">
                </div>
                <div class="form-group">
                  <label>connector_type</label>
                  <input v-model="form.connector_type" type="text" name="connector_type"
                         class="form-control" :class="{ 'is-invalid': form.errors.has('connector_type') }">
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>;
import moment from 'moment';
import { DateTimePicker } from '@progress/kendo-dateinputs-vue-wrapper';
export default {
  components: {
    'kendo-datetimepicker': DateTimePicker
  },
  data() {
    return {
      editmode: false,
      connectors: {},
      form: new Form({
        id: '',
        evse: '',
        standard: '',
        format: '',
        power_type: '',
        max_voltage: '',
        max_amperage: '',
        max_electric_power: '',
        tariff_ids: '',
        terms_and_conditions: '',
        last_updated: '',
        created_at: '',
        label: '',
        ocpp_connector_id: '',
        connector_type: '',
      }),
    };
  },
  filters: {
    moment: function (date) {
      return moment(date).format('YYYY-MM-DD hh:mm:ss');
    }
  },
  methods: {
    getResults(page = 1) {
      axios.get('api/connector?page=' + page)
          .then(response => {
            this.connectors = response.data;
          });
    },
    editModal(connector){
      this.editmode = true;
      this.form.reset();
      $('#addNew').modal('show');
      this.form.fill(connector);
    },
    newModal(){
      this.editmode = false;
      this.form.reset();
      $('#addNew').modal('show');
    },
    // Metodi per il recupero, l'aggiornamento, la creazione e l'eliminazione di Connectors
    loadConnectors() {
      axios.get('api/connector/').then((response) => {
        this.connectors = response.data;
      }).catch((error) => {
        console.error('Error fetching connectors:', error);
      });
    },
    updateConnector() {
      this.form.put('api/connector/'+this.form.id)
          .then((response) => {
            $('#addNew').modal('hide');
            Toast.fire({
              icon: 'success',
              title: 'Connector has been updated'
            });
            this.$Progress.finish();
            this.loadConnectors();
          })
          .catch((error) => {
            console.error('Error updating connector:', error);
            this.$Progress.fail();
            Toast.fire({
              icon: 'error',
              title: 'Error updating connector'
            });
          });
    },
    createConnector() {
      this.form.post('api/connector')
          .then((response)=>{
            $('#addNew').modal('hide');

            Toast.fire({
              icon: 'success',
              title: 'Connector has been created'
            });

            this.$Progress.finish();
            this.loadConnectors();

          })
          .catch(()=>{

            Toast.fire({
              icon: 'error',
              title: 'Some error occured! Please try again'
            });
          })
    },
    deleteConnector(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {

        // Send request to the server
        if (result.value) {
          this.form.delete('api/connector/'+id).then(()=>{
            Swal.fire(
                'Deleted!',
                'Connector has been deleted.',
                'success'
            );
            // Fire.$emit('AfterCreate');
            this.loadConnectors();
          }).catch((data)=> {
            Swal.fire("Failed!", data.message, "warning");
          });
        }
      })
    }
  },
  created() {
    this.loadConnectors();
  }
};
</script>

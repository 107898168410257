<template>
  <section class="content">
    <div class="container-fluid">


      <div class="row-12">
        <div class="card" style="margin-top:10px">
          <div class="card-header">
            <h3 class="card-title">Load area - Filter</h3>
            <div class="subhead"></div>
          </div>
          <div class="card-body">
            <div class="col">
              <label>Select Macro Load Area</label>
              <select class="form-control" v-model="form.mla_identifier" @change="mla_selected">
                <option
                    v-for="la_identifier in form.mla_identifiers"
                    LoadArea.vue :key="la_identifier" >
                  {{ la_identifier }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="row">

        <div class="col-12">

          <div class="card" v-if="$gate.isAdmin()">
            <div class="card-header">
              <h3 class="card-title">Load Area List</h3>

              <div class="card-tools">

                <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                  <i class="fa fa-plus-square"></i>
                  Add New
                </button>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-0">
              <table class="table table-hover">
                <thead>
                <tr>
                  <th>la_identifier</th>
                  <th>MacroLoadArea ID</th>
                  <th>Algorithm</th>
                  <th>class_name</th>
                  <th>Scenario</th>
                  <th>created_at</th>
                  <th>updated_at</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="loadArea in filteredLoadAreas" :key="loadArea.LoadAreaID">
                  <td>{{ loadArea.LoadAreaID }}</td>
                  <td>{{ loadArea.MacroLoadAreaID }}</td>
                  <td>{{ loadArea.algorithm_id }}</td>
                  <td>{{ loadArea.class_name }}</td>
                  <td>{{ loadArea.Scenario }}</td>
                  <td>{{ loadArea.created_at }}</td>
                  <td>{{ loadArea.updated_at }}</td>

                  <td>
                    <a href="#" @click="editModal(loadArea) && openModal()">
                      <i class="fa fa-edit blue"></i>
                    </a>
                    /
                    <a href="#" @click="deleteLA(loadArea.LoadAreaID)">
                      <i class="fa fa-trash red"></i>
                    </a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <pagination :data="loadAreas" @pagination-change-page="getResults"></pagination>
            </div>
          </div>
          <!-- /.card -->
        </div>
      </div>


      <div v-if="!$gate.isAdmin()">
        <not-found></not-found>
      </div>

      <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNewLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="addNewLabel" v-show="!editmode">Create New Load Area</h5>
                        <h5 class="modal-title" id="addNewLabel" v-show="editmode">Update Load Area's Info</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal">
                            <span aria-hidden="true" @click="closeModal">&times;</span>
                        </button>
                    </div>
                    <form @submit.prevent="editmode ? updateLA() : createLA()">
                        <div class="modal-body">
                <div class="form-group">

                  <label>MacroLoadAreaID</label>
                      <input type="text" name="MacroLoadAreaID" class="form-control" :disabled=true :placeholder="form.mla_identifier">
                      </input>
                </div>
                <div v-show="!editmode" class="form-group">
                  <label>la_identifier</label>
                  <input v-model="form.la_identifier" type="text" name="LoadAreaID"
                         class="form-control" :class="{ 'is-invalid': form.errors.has('LoadAreaID') }">
                </div>

                <div class="form-group">
                  <label>Algorithm</label>
                  <select class="form-control" v-model="form.algorithm_id">
                    <option
                        v-for="(la_,index) in la" :key="index"
                        :value="la_.algorithm_id"
                        :selected="index === form">{{ la_.algorithm_id }}</option>
                  </select>
                </div>

                <div class="form-group">
                  <label>Scenario</label>
                  <input v-model="form.Scenario" type="text" name="Scenario"
                         class="form-control" :class="{ 'is-invalid': form.errors.has('Scenario') }">
                </div>

                <div class="form-group">
                  <label>class_name</label>
                  <input v-model="form.class_name" type="text" name="class_name"
                         class="form-control" :class="{ 'is-invalid': form.errors.has('class_name') }">
                </div>

                <div class="form-group">
                  <label>LoadAreaValidityStartDate</label>
                  <kendo-datetimepicker class="form-control"
                                        v-on:open="onOpen"
                                        v-on:close="onClose"
                                        :format="'yyyy-MM-dd hh:mm:ss'"
                                        v-model="form.LoadAreaValidityStartDate"
                  >
                  </kendo-datetimepicker>
                </div>

                <div class="form-group">
                  <label>LoadAreaValidityEndDate</label>
                  <kendo-datetimepicker class="form-control"
                                        :format="'yyyy-MM-dd hh:mm:ss'"
                                        v-model="form.LoadAreaValidityEndDate">
                  </kendo-datetimepicker>
                </div>


              </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeModal">Close</button>
                            <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                            <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import { DateTimePicker } from '@progress/kendo-dateinputs-vue-wrapper';
import moment from 'moment';

export default {
  components: {
    'kendo-datetimepicker': DateTimePicker
  },
  data: function () {
    return {
        editmode: false,
        mla_identifiers: [], // Ensure consistency with your API response naming
        mla_identifier: "", // Assuming this is where you store the selected MLA identifier
        loadAreas: {}, // Assuming this holds the loaded data
        form: new Form({
            mla_identifier: '',
            la_identifier: '',
            LoadAreaID:'',
            MacroLoadAreaID:'',
            LoadAreaValidityStartDate: '',
            LoadAreaValidityEndDate: '',
            LATargetLoadCurve: '',
            LAPowerThreshold: '',
            LAMinPowerThreshold: '',
            algorithm_id: '',
            class_name: '',
            Scenario: '',
            date: null
      }),
    }
  },
  filters: {
    moment: function (date) {
      return moment(date).format('YYYY-MM-DD hh:mm:ss');
    }
  },

  methods: {
    frontEndDateFormat: function () {

    },

    onOpen: function (e) {
      console.log("DateTimePicker is opened");
    },
    onClose: function (e) {
      console.log("DateTimePicker is closed");
    },

    getResults(page = 1) {

      this.$Progress.start();

      axios.get('api/LoadArea').then(({data}) => (this.LoadArea = data.data));

      this.$Progress.finish();
    },
    updateLA() {
      this.$Progress.start();
      // console.log('Editing data');
      console.log('Updating Load Area with ID:', this.form.LoadAreaID);
      this.form.put('api/LoadArea/' + this.form.la_identifier)
          .then((response) => {
            // success
            $('#addNew').modal('hide');
            Toast.fire({
              icon: 'success',
              title: response.data.message
            });
            this.$Progress.finish();
            //  Fire.$emit('AfterCreate');
            this.loadLA();
          })
          .catch(() => {
            this.$Progress.fail();
          });

    },

    editModal(loadArea) {
      this.editmode = true;
      this.form.reset();
      $('#addNew').modal('show');
      this.form.fill(loadArea);
    },

    newModal() {
      if (
          this.form.mla_identifier == ""
      ) {
        Swal.fire({
          title: 'Problem',
          text: "Please Select Macro Load Area",
          confirmButtonColor: '#d33',
          confirmButtonText: 'Ok'
        });
      } else {
        $('#addNew').modal('show');
      }
    },
      closeModal() {
        console.log("Closing");
        $('#addNew').modal('hide');
      },

    deleteLA(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {

        // Send request to the server
        if (result.value) {
          this.form.delete('/api/LoadArea/' + id).then(() => {
            Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
            );
            // Fire.$emit('AfterCreate');
            this.loadLA();
          }).catch((data) => {
            Swal.fire("Failed!", data.message, "warning");
          });
        }
      })
    },
    mla_selected(event) {
      this.form.mla_identifier = event.target.value; // Set selected MLA identifier in form
      this.loadLA(); // Trigger loading of load areas based on selected MLA
    },

    loadLA() {

      if (
          this.$gate.isAdmin()
      ) {
        let url = 'api/LoadArea'

        let params = new URLSearchParams();

        if (this.form.mla_identifier.length > 0) {
          params.append('MLA', this.form.mla_identifier);
        }
        //console.log(this.EVs);

        let url_query = params.toString();
        if (url_query.length > 0) {
          url = url + '?' + url_query;
        }
        axios.get(url)
            .then(response => {
              /*  this.loadAreas = response.data; // Assuming response.data is the array of Load Areas
            })
            .catch(error => {
              console.error('Error fetching load areas:', error);
            */
              if (response.status === 200) {

                // Recupero delle informazioni sui loadAreas
                this.loadAreas = response.data;

                // Aggiunta degli istanti di creazione e modifica
                for (
                    let i = 0;
                    i < this.form.loadAreas.length;
                    i++
                ) {
                  // Per ogni loadAreas aggiustiamo il formato della data
                  this.form.loadAreas[i].updated_at = moment(
                      this.form.loadAreas[i].updated_at
                  ).format('YYYY-MM-DD HH:mm:ss');
                }

              } else {

                // Something went wrong with the HTPP response
                console.error(response);

              }
            });


      }

    },

    update_mlas() {
      axios.get('api/LA/getLoadAreaIdentifiersByMacroLoadAreaIdentifier')
          .then(response => {

            // Check if HTTP response is OK (status === 200)
            if (response.status === 200) {

              // Check if data in the response are okay ( data.success === true )
              if (response.data.success) {

                // Empty the current list of Macro Load Areas
                this.form.mla_identifiers = [""];

                // Empty the current list of Load Area Identifiers By Macro Load Area Identifier
                this.form.la_identifiers_by_mla_identifier = {}

                // Get the new Macro Load Area list
                let mla_identifiers = response.data.data;

                // Cycle in the Macro Load Area list
                for (let mla_identifier in mla_identifiers) {
                  this.form.mla_identifiers.push(mla_identifier);
                  let la_identifiers = mla_identifiers[mla_identifier]
                  la_identifiers.unshift("");
                  this.form.la_identifiers_by_mla_identifier[mla_identifier] = la_identifiers;

                }

              } else {

                // Something went wrong in the data collected in the response
                console.error(response.data);

              }

            } else {

              // Something went wrong with the HTPP response
              console.error(response);

            }

          });
    },


    loadAlgorithm() {
      axios.get("api/Algorithm/list")
          .then(({data}) => (
              this.la = data.data
          ));
    },

    createLA() {
      this.form.post('api/LoadArea')
          .then((response) => {
            $('#addNew').modal('hide');

            Toast.fire({
              icon: 'success',
              title: 'LoadArea has been created'
            });
            this.loadLA();

          })
          .catch(() => {

            Toast.fire({
              icon: 'error',
              title: 'Some error occured! Please try again'
            });
          })
    }
  },

  mounted() {
    this.update_mlas();
    this.loadLA();
    this.loadAlgorithm();
  },
  computed: {
    filteredLoadAreas() {
      if (!this.mla_identifier) {
        return this.loadAreas; // Return all loadAreas if no MLA identifier is selected
      } else {
        return this.loadAreas.filter((loadArea) => {
          return loadArea.MacroLoadAreaID === this.mla_identifier;
        });
      }
    }
  },
  watch: {
    'form.mla_identifier': {
      handler (){
        this.loadLA();
      }
    }
  }
}
</script>

module.exports = {
    kw2soc: function (kw, capacity) {
        return parseFloat((kw * 100 / capacity).toFixed(2));
    },
    soc2kw: function (soc, capacity) {
        return parseFloat((soc / 100 * capacity).toFixed(2));
    },
    log2session: function (log) {
        if (log == null)
            return {};

        return {
            SmartChargingSessionID : log.SmartChargingSessionID,
            ComputedLoadCurve : log.ComputedLoadCurve,
            CurrentCost: log.CurrentCost,
            CurrentState: log.CurrentState,
            ElectricityTariff: log.ElectricityTariff,
            EstimatedStateCurve: log.EstimatedStateCurve,
            ExpectedRechargeCost: log.ExpectedRechargeCost,
            MeterStartTime: log.MeterStartTime,
            POD: { PODID: log.PODID, LoadAreaID: log.LoadAreaID },
            PODID: log.PODID,
            RealEnergyCurve: log.RealEnergyCurve,
            RealLoadCurve: log.RealLoadCurve,
            SOC: log.SOC,
            SmartChargingSessionSettings: {
                MaximumPower: log.MaximumPower,
                MinimumPower: log.MinimumPower,
                BatteryCapacity: log.BatteryCapacity,
                ThreePhaseMode: log.ThreePhaseMode
            },
            UserChargingRequest: {
                DesiredDepartureTime: log.DesiredDepartureTime,
                DesiredEnergyLevel: log.DesiredEnergyLevel,
                InitialSOC: log.InitialSOC,
                Smartness: log.Smartness,
                StartOfCharging: log.StartOfCharging
            },
            isCurrent: log.isCurrent
        }
    },
    notify: function (response){
        var result = {
            title: 'Error',
            message: 'An error occurred',
            type: 'error'
        };
        if (response.ack == 1) {
            result = {
                title: 'Success',
                message: 'Operation completed',
                type: 'success' //Default: 'info', also you can use VueNotifications.type.error instead of 'error'
            }
        } else {
            if (response.data.hasOwnProperty('errors')) {
                result = {
                    title: response.msg,
                    message: response.data.errors[0].message,
                    type: 'error'
                }
            } else if (response.data.hasOwnProperty('error')) {
                result = {
                    title: response.msg,
                    message: response.data.error,
                    type: 'error'
                }
            }
        }
        App.notify(result);
    },
    alert: function (response){
        var result = {
           title: 'An error occurred',
           text: response.data.message,
           type: 'error'
        };
        Swal.fire(result);
    },
    success: function (response){
        var result = {
            title: 'Success',
            type: 'success'
        };
        Swal.fire(result);
    },

    confirm: function(msg, cb){
        swal({
            title: "Warning",
            text: msg,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#f44336",
            confirmButtonText: "Yes!",
            cancelButtonText: "No",
            closeOnConfirm: true
        }, function() {
            if (cb != null && typeof cb == 'function') cb();
        })
    }

}

<template>
    <section class="content">
        <!--
        <div>
            <h2>Planner Activity</h2>
            <div v-if="error" class="error">{{ error }}</div>
            <div v-else>
                <p>Number of updated input.txt and runs succesfull: {{ runsSucessfull }}</p>
                <p>Runs failed: {{ runsFailed }}</p>
                <p>Downloads: {{ downloads }}</p>
            </div>
        </div>
        -->
        <div class="container-fluid">
            <div>
                <div class="row-12">
                    <div class="card" v-if="$gate.isAdmin()">
                        <div class="card-header">
                            <h3 class="card-title">ES-Planning {{$t('panelControl')}}</h3>
                        </div>
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th></th>
                                    <th>Input</th>
                                    <th>{{$t('action')}}</th>
                                    <th>{{$t('updated_at')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        Upload a new input.txt
                                        <i class="fa fa-upload"></i>
                                    </td>
                                    <td><input type="file" @change="onFileChange" required></td>
                                    <td><a href="#" @click.prevent="uploadInput">
                                        Upload
                                        <i class="fa fa-upload"></i>
                                    </a> / <a href="#" @click.prevent="downloadInput">
                                        Download
                                        <i class="fa fa-download"></i>
                                    </a> /
                                        <a href="#" @click.prevent="deleteInput">
                                        <i class="fa fa-trash red"></i>
                                    </a></td>
                                    <td>{{ lastModified.input }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        Upload a new plan.run
                                        <i class="fa fa-upload"></i>
                                    </td>
                                    <td><input type="file" @change="onFileChange" required></td>
                                    <td><a href="#" @click.prevent="uploadPlanRun">
                                        Upload
                                        <i class="fa fa-upload"></i>
                                    </a> /
                                        <a href="#" @click.prevent="downloadPlanRun">
                                        Download
                                        <i class="fa fa-download"></i>
                                    </a> / <a href="#" @click.prevent="deletePlanRun">
                                        <i class="fa fa-trash red"></i>
                                    </a></td>
                                    <td>{{ lastModified.planRun }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        Upload a new Plan.mod
                                        <i class="fa fa-upload"></i>
                                    </td>
                                    <td><input type="file" @change="onFileChange" required></td>
                                    <td><a href="#" @click.prevent="uploadPlanMod">
                                        Upload
                                        <i class="fa fa-upload"></i>
                                    </a> / <a href="#" @click.prevent="downloadPlanMod">
                                        Download
                                        <i class="fa fa-download"></i>
                                    </a> / <a href="#" @click.prevent="deletePlanMod">
                                        <i class="fa fa-trash red"></i>
                                    </a></td>
                                    <td>{{ lastModified.planMod }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row-12">
                    <div class="card" v-if="$gate.isAdmin() || $gate.isPlanner()">
                        <div class="card-header">
                            <h3 class="card-title">ES-Planning</h3>
                        </div>
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th>{{$t('action')}}</th>
                                    <th>Input</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        Upload input.txt compliant with <a href="#" @click.prevent="downloadInput">template file
                                        <i class="fa fa-download"></i>
                                    </a>
                                    </td>
                                    <td><input type="file" @change="onFileChange" required></td>
                                </tr>
                                <tr>
                                    <td>
                                        <a href="#" @click.prevent="runApplication">
                                            {{ $t('runApplication') }}
                                            <i class="fa fa-play green"></i>
                                        </a>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr v-if="success">
                                    <td>
                                        <a href="#" @click="downloadFile" v-if="outputLink">
                                            {{ $t('download') }} output.txt
                                            <i class="fa fa-download"></i>
                                        </a>
                                    </td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            file: null,
            success: '',
            fail: '',
            downloadLink: '/downloadInput',
            outputLink: '/download',
            uploadPlanRunLink: '/uploadPlanRun',
            downloadPlanRunLink: '/downloadPlanRun',
            deletePlanRunLink: '/deletePlanRun',
            downloadPlanModLink: '/downloadPlanMod',
            errorMessage: '',
            runsSucessfull: 0,
            runsFailed: 0,
            downloads: 0,
            error: null,
            lastModified: {
                planRun: '',
                planMod: '',
                input: ''
            },
        };
    },
    methods: {
        onFileChange(e) {
            this.file = e.target.files[0];
        },
        async uploadFile() {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: "You will execute the application!",
                showCancelButton: true,
                confirmButtonColor: '#28a745',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, run the application'
            });

            if (result.value) {
                const formData = new FormData();
                formData.append('input_file', this.file);

                try {
                    const response = await axios.post('/uploadAndRun', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    if (response.data.success) {
                        this.success = this.$t('successESP');
                        Swal.fire(
                            'Run successful',
                            'Output.txt is generated.',
                            'success'
                        );
                    }
                } catch (error) {
                    console.error('Error uploading file:', error);
                    if (error.response && error.response.status === 400 && error.response.data.error) {
                        this.errorMessage = error.response.data.error;
                    } else {
                        this.errorMessage = 'Error on running ESPlanning: ' + 'input.txt might contain syntax error or large instance.';
                    }
                    Swal.fire("Failed!", this.errorMessage, "error")
                }
            } else {
                console.error('Insert an input.txt');
                Swal.fire('Cancelled', 'Application not executed', 'info');
            }
        },
        runApplication() {
            this.uploadFile();
        },
        downloadFile() {
            if (!this.outputLink) return;

            Swal.fire({
                title: 'Download File',
                text: 'Do you want to download the file?',
                showCancelButton: true,
                confirmButtonColor: '#28a745',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, download'
            }).then((result) => {
                if (result.value) {
                    window.location.href = this.outputLink;
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                }
            });
        },
        downloadInput() {
            if (!this.downloadInput) return;

            Swal.fire({
                title: 'Download File',
                text: 'Do you want to download the file?',
                showCancelButton: true,
                confirmButtonColor: '#28a745',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, download'
            }).then((result) => {
                if (result.value) {
                    window.location.href = this.downloadLink;
                }
            });
        },
        async uploadPlanRun() {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: 'You will upload a plan.run',
                showCancelButton: true,
                confirmButtonColor: '#28a745',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, upload plan.run'
            });

            if (result.value) {
                const formData = new FormData();
                formData.append('input_file', this.file);

                try {
                    // Upload file
                    const response = await axios.post('/uploadPlanRun', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    if (response.data.success) {
                        // Set success message
                        this.success = 'Uploaded plan.run';

                        // Show success message
                        Swal.fire(
                            'Upload operation successful!',
                            'plan.run has been modified.',
                            'success'
                        ).then(() => {
                            // Reload the page after showing success message
                            window.location.reload();
                        });
                    } else {
                        // Handle case where server reports failure
                        Swal.fire('Failed!', 'Failed to upload plan.run', 'error');
                    }
                } catch (error) {
                    // Handle network errors or other exceptions
                    console.error('Error uploading file:', error);
                    Swal.fire('Failed!', 'Error uploading file', 'error');
                }
            } else {
                console.error('Insert a plan.run');
                Swal.fire('Cancelled', 'No file uploaded', 'info');
            }
        },
        downloadPlanRun() {
            if (!this.outputLink) return;

            Swal.fire({
                title: 'Download File',
                text: 'Do you want to download the file?',
                showCancelButton: true,
                confirmButtonColor: '#28a745',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, download'
            }).then((result) => {
                if (result.value) {
                    window.location.href = this.downloadPlanRunLink;
                }
            });
        },
        async deletePlanRun() {
            try {
                const result = await Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, delete it!'
                });

                if (result.isConfirmed) {
                    // Send request to delete the file
                    const response = await axios.delete('/deletePlanRun');

                    // Check if deletion was successful
                    if (response.data.success) {
                        // Show success message
                        Swal.fire(
                            'Deleted!',
                            'plan.run has been deleted.',
                            'success'
                        ).then(() => {
                            // Refresh the page after showing success message
                            window.location.reload();
                        });

                        // Uncomment if you have an event to emit after deletion
                        // this.$emit('AfterDelete');
                    } else {
                        // Handle case where deletion failed on server side
                        Swal.fire('Failed!', response.data.error || 'Failed to delete plan.run', 'error');
                    }
                }
            } catch (error) {
                // Handle network errors or other exceptions
                console.error('Error deleting file:', error);
                Swal.fire('Failed!', error.response.data.error || 'Something went wrong.', 'error');
            }
        },


        async uploadPlanMod() {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: 'You will upload a plan.run',
                showCancelButton: true,
                confirmButtonColor: '#28a745',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, upload plan.run'
            });

            if (result.value) {
                const formData = new FormData();
                formData.append('input_file', this.file);

                try {
                    // Upload file
                    const response = await axios.post('/uploadPlanMod', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    if (response.data.success) {
                        // Set success message
                        this.success = 'Uploaded Plan.mod';

                        // Show success message
                        Swal.fire(
                            'Upload operation successful!',
                            'Plan.mod has been modified.',
                            'success'
                        ).then(() => {
                            // Reload the page after showing success message
                            window.location.reload();
                        });
                    } else {
                        // Handle case where server reports failure
                        Swal.fire('Failed!', 'Failed to upload Plan,mod', 'error');
                    }
                } catch (error) {
                    // Handle network errors or other exceptions
                    console.error('Error uploading file:', error);
                    Swal.fire('Failed!', 'Error uploading file', 'error');
                }
            } else {
                console.error('Insert a Plan.mod');
                Swal.fire('Cancelled', 'No file uploaded', 'info');
            }
        },
        downloadPlanMod() {
            if (!this.outputLink) return;

            Swal.fire({
                title: 'Download File',
                text: 'Do you want to download the file?',
                showCancelButton: true,
                confirmButtonColor: '#28a745',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, download'
            }).then((result) => {
                if (result.value) {
                    window.location.href = this.downloadPlanModLink;
                }
            });
        },
        async deletePlanMod() {
            try {
                const result = await Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, delete it!'
                });

                if (result.isConfirmed) {
                    // Send request to delete the file
                    const response = await axios.delete('/deletePlanMod');

                    // Check if deletion was successful
                    if (response.data.success) {
                        // Show success message
                        Swal.fire(
                            'Deleted!',
                            'Plan.mod has been deleted.',
                            'success'
                        ).then(() => {
                            // Refresh the page after showing success message
                            window.location.reload();
                        });

                        // Uncomment if you have an event to emit after deletion
                        // this.$emit('AfterDelete');
                    } else {
                        // Handle case where deletion failed on server side
                        Swal.fire('Failed!', response.data.error || 'Failed to delete Plan.mod', 'error');
                    }
                }
            } catch (error) {
                // Handle network errors or other exceptions
                console.error('Error deleting file:', error);
                Swal.fire('Failed!', error.response.data.error || 'Something went wrong.', 'error');
            }
        },
        async uploadInput() {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: 'You will upload a input.txt',
                showCancelButton: true,
                confirmButtonColor: '#28a745',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, upload input.txt'
            });

            if (result.value) {
                const formData = new FormData();
                formData.append('input_file', this.file);

                try {
                    // Upload file
                    const response = await axios.post('/uploadInput', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    if (response.data.success) {
                        // Set success message
                        this.success = 'Uploaded input.txt';

                        // Show success message
                        Swal.fire(
                            'Upload operation successful!',
                            'input.txt has been uploaded.',
                            'success'
                        ).then(() => {
                            // Reload the page after showing success message
                            window.location.reload();
                        });
                    } else {
                        // Handle case where server reports failure
                        Swal.fire('Failed!', 'Failed to upload input.txt', 'error');
                    }
                } catch (error) {
                    // Handle network errors or other exceptions
                    console.error('Error uploading file:', error);
                    Swal.fire('Failed!', 'Error uploading file', 'error');
                }
            } else {
                console.error('Insert a input.txt');
                Swal.fire('Cancelled', 'No file uploaded', 'info');
            }
        },
        async deleteInput() {
            try {
                const result = await Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, delete it!'
                });

                if (result.isConfirmed) {
                    // Send request to delete the file
                    const response = await axios.delete('/deleteInput');

                    // Check if deletion was successful
                    if (response.data.success) {
                        // Show success message
                        Swal.fire(
                            'Deleted!',
                            'input.txt has been deleted.',
                            'success'
                        ).then(() => {
                            // Refresh the page after showing success message
                            window.location.reload();
                        });

                        // Uncomment if you have an event to emit after deletion
                        // this.$emit('AfterDelete');
                    } else {
                        // Handle case where deletion failed on server side
                        Swal.fire('Failed!', response.data.error || 'Failed to delete input.txt', 'error');
                    }
                }
            } catch (error) {
                // Handle network errors or other exceptions
                console.error('Error deleting file:', error);
                Swal.fire('Failed!', error.response.data.error || 'Something went wrong.', 'error');
            }
        },
        async getPlannerActivity() {
            console.log("getPlannerActivity called");
            try {
                const response = await axios.get('/planner-activity');
                if (response.data) {
                    this.runsSucessfull = response.data.runsSucessfull;
                    this.runsFailed = response.data.runsFailed;
                    this.downloads = response.data.downloads;
                }
            } catch (error) {
                this.error = 'Error fetching user activity. Make sure you are logged in as a planner.';
            }
        },
        async getLastModifiedDate() {
            try {
                const response = await axios.get('/lastModified');
                this.lastModified.planRun = response.data['plan.run'];
                this.lastModified.planMod = response.data['plan.mod'];
                this.lastModified.input = response.data['input.txt'];
            } catch (error) {
                console.error('Error fetching last modified dates:', error);
            }
        },
    },
    created() {
        this.getLastModifiedDate();
        this.getPlannerActivity();
    },
};
</script>
<style>
.green {
    color: green;
}
.red {
    color: red;
}
</style>

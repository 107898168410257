<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card" v-if="$gate.isAdmin()">
                        <div class="card-header">
                            <h3 class="card-title">Connectors Type List</h3>
                            <div class="card-tools">
                                <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                                    <i class="fa fa-plus-square"></i>
                                    Add New
                                </button>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th>id</th>
                                    <th>ocpp</th>
                                    <th>ocpi</th>
                                    <th>icon</th>
                                    <th>label</th>
                                    <th>format</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="connector in connectors.data" :key="connector.id">
                                    <td>{{ connector.id }}</td>
                                    <td>{{ connector.ocpp }}</td>
                                    <td>{{ connector.ocpi }}</td>
                                    <td>{{ connector.icon }}</td>
                                    <td>{{ connector.label }}</td>
                                    <td>{{ connector.format }}</td>
                                    <td>
                                        <a href="#" @click="editModal(connector)">
                                            <i class="fa fa-edit blue"></i>
                                        </a>
                                        /
                                        <a href="#" @click="deleteConnector(connector.id)">
                                            <i class="fa fa-trash red"></i>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer">
                            <pagination :data="connectors" @pagination-change-page="getResults"></pagination>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>

            <div v-if="!$gate.isAdmin()">
                <not-found></not-found>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" v-show="!editmode">Create New Connector</h5>
                            <h5 class="modal-title" v-show="editmode">Update Connector Info</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <form @submit.prevent="editmode ? updateConnector() : createConnector()">
                            <div class="modal-body">
                                <div class="form-group">
                                    <label>ocpp</label>
                                    <input v-model="form.ocpp" type="text" name="ocpp"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('ocpp') }">
                                </div>
                                <div class="form-group">
                                    <label>ocpi</label>
                                    <!-- Input per visualizzare o modificare 'created_at', se necessario -->
                                    <input v-model="form.ocpi" type="text" name="description"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('ocpi') }">
                                </div>

                                <div class="form-group">
                                    <label>icon</label>
                                    <!-- Input per visualizzare o modificare 'created_at', se necessario -->
                                    <input v-model="form.icon" type="text" name="icon"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('icon') }">
                                </div>

                                <div class="form-group">
                                    <label>label</label>
                                    <!-- Input per visualizzare o modificare 'created_at', se necessario -->
                                    <input v-model="form.label" type="text" name="label"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('label') }">
                                </div>

                                <div class="form-group">
                                    <label>format</label>
                                    <!-- Input per visualizzare o modificare 'created_at', se necessario -->
                                    <input v-model="form.format" type="text" name="format"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('format') }">
                                </div>

                            </div>
                            <!-- Aggiungi altri campi qui -->
                            <div class="modal-footer text-left">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeModal">Close</button>
                                <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                                <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>;
import moment from 'moment';

export default {
    data() {
        return {
            editmode: false,
            connectors: {},
            form: new Form({
                id: '',
                value: '',
                description: '',
                version: '',
            }),
        };
    },
    filters: {
        moment: function (date) {
            return moment(date).format('YYYY-MM-DD hh:mm:ss');
        }
    },
    methods: {
        getResults(page = 1) {
            axios.get('api/connector-type?page=' + page)
                .then(response => {
                    this.connectors = response.data;
                });
        },
        editModal(connector){
            this.editmode = true;
            this.form.reset();
            $('#addNew').modal('show');
            this.form.fill(connector);
        },
        newModal(){
            this.editmode = false;
            this.form.reset();
            $('#addNew').modal('show');
        },
        closeModal(){
            $('#addNew').modal('hide');
        },
        // Metodi per il recupero, l'aggiornamento, la creazione e l'eliminazione di Connectors
        loadConnectors() {
            axios.get('api/connector-type/').then((response) => {
                this.connectors = response.data;
            }).catch((error) => {
                console.error('Error fetching connectors:', error);
            });
        },
        updateConnector() {
            //axios.post('api/connector-type/' + this.form.id,this.form).then((data) => {
            this.form.put('api/connector-type/'+this.form.id)
                .then((response) => {
                    $('#addNew').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: 'Connector has been updated'
                    });
                    this.$Progress.finish();
                    this.loadConnectors();
                })
                .catch((error) => {
                    console.error('Error updating connector:', error);
                    this.$Progress.fail();
                    Toast.fire({
                        icon: 'error',
                        title: 'Error updating connector'
                    });
                });
        },
        createConnector() {
            this.form.post('api/connector-type')
                .then((response)=>{
                    $('#addNew').modal('hide');

                    Toast.fire({
                        icon: 'success',
                        title: 'Connector has been created'
                    });

                    this.$Progress.finish();
                    this.loadConnectors();

                })
                .catch(()=>{

                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
        },
        deleteConnector(id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {

                // Send request to the server
                if (result.value) {
                    this.form.delete('api/connector-type/'+id).then(()=>{
                        Swal.fire(
                            'Deleted!',
                            'Connector Type has been deleted.',
                            'success'
                        );
                        // Fire.$emit('AfterCreate');
                        this.loadConnectors();
                    }).catch((data)=> {
                        Swal.fire("Failed!", data.message, "warning");
                    });
                }
            })
        }
    },
    created() {
        this.loadConnectors();
    }
};
</script>

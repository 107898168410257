<template>
    <section class="content">
        <div class="container-fluid">
            <div>
                <div class="mb-3">
                    <label for="userFilter">Filter by Planner:</label>
                    <select v-model="selectedUserId" @change="filterByUser">
                        <option value="">All Planners</option>
                        <option v-for="user in plannerUsers" :key="user.id" :value="user.id">{{ user.name }}</option>
                    </select>
                </div>
                <!-- Analytics Card -->
                <div class="row-12">
                    <div class="card" v-if="$gate.isAdmin()">
                        <div class="card-header">
                            <h3 class="card-title">Analytics</h3>
                        </div>
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th></th>
                                    <th>Activity Type</th>
                                    <th>Total Count</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td></td>
                                    <td>Runs Successful</td>
                                    <td>{{ runsSuccessful }}</td>
                                    <td><button @click="redirectToRunsSuccessful" class="btn btn-primary">More Details</button></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Upload Successful</td>
                                    <td>{{ uploadSuccessful }}</td>
                                    <td><button @click="redirectToUploadSuccessful" class="btn btn-primary">More Details</button></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Runs Failed</td>
                                    <td>{{ runsFailed }}</td>
                                    <td><button @click="redirectToRunsFailed" class="btn btn-primary">More Details</button></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Downloads</td>
                                    <td>{{ downloads }}</td>
                                    <td><button @click="redirectToDownloadSuccessful" class="btn btn-primary">More Details</button></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <!-- Planner Sessions Card -->
                <div class="row-12">
                    <div class="card" v-if="$gate.isAdmin()">
                        <div class="card-header">
                            <h3 class="card-title">Planner Session</h3>
                        </div>
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>User Id</th>
                                    <th>Login At</th>
                                    <th>Logout At</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="session in sessions.data" :key="session.id">
                                    <td>{{ session.id }}</td>
                                    <td>{{ session.user_id }}</td>
                                    <td>{{ session.login_at | moment }}</td>
                                    <td>{{ session.logout_at | moment }}</td>
                                </tr>
                                </tbody>
                            </table>
                            <div class="card-footer">
                                <pagination :data="sessions" @pagination-change-page="getResults"></pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import moment from 'moment'; // Ensure moment.js is imported

export default {
    data() {
        return {
            runsSuccessful: 0,
            uploadSuccessful: 0, // Corrected typo
            runsFailed: 0,
            downloads: 0,
            error: null,
            selectedUserId: '', // Selected user ID for filtering
            sessions: {}, // Sessions should be an array
            plannerUsers: [] // Added plannerUsers array initialization
        };
    },
    filters: {
        moment: function (date) {
            // Assuming date is in UTC, format it for display in local timezone
            return moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss');
        }
    },
    methods: {
        async fetchPlannerUsers() {
            try {
                const response = await axios.get('/api/user/planners');
                if (response.data) {
                    this.plannerUsers = response.data;
                }
            } catch (error) {
                console.error('Error fetching planner users:', error);
            }
        },
        filterByUser() {
            this.getPlannerActivity(1, this.selectedUserId); // Pass 1 for the first page and selectedUserId
        },
        async getPlannerActivity(page = 1, userId = '') {
            try {
                const response = await axios.get('/planning/analytics', {
                    params: {
                        page: page,
                        userId: userId
                    }
                });
                if (response.data) {
                    this.runsSuccessful = response.data.runsCount;
                    this.uploadSuccessful = response.data.uploadsCount;
                    this.runsFailed = response.data.runsFailed;
                    this.downloads = response.data.downloadsCount;
                    this.sessions = response.data.sessions; // Ensure this is an array
                }
            } catch (error) {
                this.error = 'Error fetching user activity. Make sure you are logged in as a planner.';
            }
        },
        getResults(page = 1) {
            this.getPlannerActivity(page, this.selectedUserId); // Pass the selectedUserId as userId
        },
        redirectToUploadSuccessful() {
            this.$router.push('/analytics/uploadSuccessful');
        },
        redirectToRunsSuccessful() {
            this.$router.push('/analytics/runSuccessful');
        },
        redirectToDownloadSuccessful() {
            this.$router.push('/analytics/downloadSuccessful');
        },
        redirectToRunsFailed() {
            this.$router.push('/analytics/runsFailed');
        },
    },
    created() {
        this.getPlannerActivity(); // Fetch initial analytics data
        this.fetchPlannerUsers(); // Fetch users with 'planner' role for dropdown
    },
};
</script>

<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12">

                    <div class="card" v-if="$gate.isAdmin()">
                        <div class="card-header">
                            <h3 class="card-title">MacroLoad Area List</h3>

                            <div class="card-tools">

                                <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                                    <i class="fa fa-plus-square"></i>
                                    Add New
                                </button>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th>MacroLoadArea ID</th>
                                    <th>MacroLoadAreaValidityStartDate</th>
                                    <th>MacroLoadAreaValidityEndDate</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="MLA in MacroLoadArea.data" :key="MLA.MacroLoadAreaID">
                                    <td class="text-capitalize">{{MLA.MacroLoadAreaID}}</td>
                                    <td class="text-capitalize">{{MLA.MacroLoadAreaValidityStartDate}}</td>
                                    <td class="text-capitalize">{{MLA.MacroLoadAreaValidityEndDate}}</td>


                                  <td>
                                    <a href="#" @click="editModal(MLA)">
                                        <i class="fa fa-edit blue"></i>
                                    </a>
                                    /
                                    <a href="#" @click="deleteMLA(MLA.MacroLoadAreaID)">
                                        <i class="fa fa-trash red"></i>
                                    </a>
                                  </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer">
                            <pagination :data="MacroLoadArea" @pagination-change-page="getResults"></pagination>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>


            <div v-if="!$gate.isAdmin()">
                <not-found></not-found>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" v-show="!editmode">Create New MacroLoadArea</h5>
                            <h5 class="modal-title" v-show="editmode">Update MacroLoadArea's Info</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <!-- <form @submit.prevent="createUser"> -->

                        <form @submit.prevent="editmode ? updateMLA() : createMLA()">
                            <div class="modal-body">
                                <div class="form-group">

                                    <label>MacroLoadAreaID</label>
                                    <input v-model="form.MacroLoadAreaID" type="text" name="MacroLoadAreaID"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('MacroLoadAreaID') }">
                                </div>

                                <div class="form-group">
                                    <label>MacroLoadAreaValidityStartDate</label>
                                    <kendo-datetimepicker class="form-control"
                                                          :format="'yyyy-MM-dd hh:mm:ss'"
                                                          v-model="form.MacroLoadAreaValidityStartDate"
                                    >
                                    </kendo-datetimepicker>
                                </div>

                                <div class="form-group">
                                    <label>MacroLoadAreaValidityEndDate</label>
                                    <kendo-datetimepicker class="form-control"
                                                          :format="'yyyy-MM-dd hh:mm:ss'"
                                                          v-model="form.MacroLoadAreaValidityEndDate">
                                    </kendo-datetimepicker>
                                </div>

                                <div class="form-group">
                                    <label>MLATargetLoadCurve</label>
                                    <input v-model="form.MLATargetLoadCurve" type="text" name="MLATargetLoadCurve"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('MLATargetLoadCurve') }">
                                </div>

                                <div class="form-group">
                                    <label>MLAPowerThreshold</label>
                                    <input v-model="form.MLAPowerThreshold" type="text" name="MLAPowerThreshold"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('MLAPowerThreshold') }">
                                </div>


                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeModal">Close</button>
                                <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                                <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { DateTimePicker } from '@progress/kendo-dateinputs-vue-wrapper';
import moment from 'moment';

export default {
    components: {
        'kendo-datetimepicker': DateTimePicker
    },
    data: function () {
        return {
            editmode: false,
            MacroLoadArea : {},
            form: new Form({
                MacroLoadAreaID: '',
                MacroLoadAreaValidityStartDate: '',
                MacroLoadAreaValidityEndDate: '',
                MLATargetLoadCurve: '',
                MLAPowerThreshold: '',


            }),
            mla: [],
        }
    },
    filters: {
        moment: function (date) {
            return moment(date).format('YYYY-MM-DD hh:mm:ss');
        }
    },

    methods: {

        getResults(page = 1) {

            this.$Progress.start();

            axios.get('api/MacroLoadArea').then(({ data }) => (this.MacroLoadArea = data.data));

            this.$Progress.finish();
        },
        updateMLA(){
            this.$Progress.start();
            // console.log('Editing data');
            this.form.put('api/MacroLoadArea/'+this.form.MacroLoadAreaID)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.$Progress.finish();
                    //  Fire.$emit('AfterCreate');

                    this.loadMLA();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

        },
        editModal(MLA){
            this.editmode = true;
            this.form.reset();
            $('#addNew').modal('show');
            this.form.fill(MLA);
        },
        newModal(){
            this.editmode = false;
            this.form.reset();
            $('#addNew').modal('show');
        },
        closeModal(){
            $('#addNew').modal('hide');
        },
        deleteMLA(id){
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {

                // Send request to the server
                if (result.value) {
                    this.form.delete('/api/MacroLoadArea/'+id).then(()=>{
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        );
                        // Fire.$emit('AfterCreate');
                        this.loadMLA();
                    }).catch((data)=> {
                        Swal.fire("Failed!", data.message, "warning");
                    });
                }
            })
        },
        loadMLA(){
            this.$Progress.start();

            if(this.$gate.isAdmin()){
                //console.log(this.EVs);
                axios.get("/api/MacroLoadArea").then(({ data }) => (this.MacroLoadArea = data.data));

            }

            this.$Progress.finish();
        },


        createMLA(){
            this.$Progress.start();
            this.form.post('api/MacroLoadArea')
                .then((response)=>{
                    $('#addNew').modal('hide');

                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });

                    this.$Progress.finish();
                    this.loadMLA();

                })
                .catch(()=>{

                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            this.$Progress.fail();
        }

    },
    mounted() {
        console.log('EV Component mounted.')
    },
    created() {

        this.$Progress.start();
        this.loadMLA();
        this.$Progress.finish();
    }
}
</script>

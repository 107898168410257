<template>


    <div class="p-3 pt-3 row">
        <div class="p-1 pt-1 col-3">
            <label class="control-sidebar-heading">Select Application</label>
            <div class="">
                <select  class="form-control" v-model="Application_data" name="Application" id="Application" @change="select_APP"
                         v-bind:disabled="running || scheduling">
                    <option v-for="(Application, index) in app" v-bind:value="Application">
                        {{Application.description}}
                    </option>
                </select>
            </div>
        </div>

        <div class="p-1 pt-1 col-3">
            <label class="control-sidebar-heading">Select Load Area</label>
            <div class="">
                <select  class="form-control" v-model="LoadArea_data" name="LoadAreaID" id="LoadAreaID" @change="select_LA"
                         v-bind:disabled="running || scheduling">
                    <option  v-for="(loadarea, index) in las" v-bind:value="loadarea">
                        {{loadarea.LoadAreaID}}
                    </option>
                </select>
            </div>
        </div>

        <div class="p-1 pt-1 col-3">
            <label class="control-sidebar-heading">Select Timeframe</label>
            <div class="">
                <select  class="form-control" v-model="Timeframes_data" name="Timeframe" id="Timeframe" @change="select_Timeframe"
                         v-bind:disabled="running || scheduling">
                    <option v-for="(Timeframe, index) in timeframes" v-bind:value="Timeframe">
                        {{Timeframe}}
                    </option>
                </select>
            </div>
        </div>
    </div>

</template>

<script>
import moment from 'moment'
export default {

    mounted() {
        //console.log('LoadAreaSelect ready.');
        this.getApplication();
        console.log('APP', this.Application)
        this.getLoadAreas();
        this.Timeframe = this.$store.state.timeframe;

        //EventBus.$on('applicationchanged', () => {
        //    this.getLoadAreas();
        //    });
    },
    data() {
        return {
            app: [],
            las: [],
            timeframes: [
                'Last 24 Hours', 'Last 7 Days', 'Last 14 Days', 'All'
            ],
            MaxPower: 300,
            LoadArea_data: {},
            Application_data: {},
            Timeframes_data: {}
        }
    },
    computed: {
        LoadAreaID: {
            // getter
            get: function () {
                return this.$store.state.loadArea.id
            },
            // setter
            set: function (newValue) {
                this.$store.state.loadArea.id = newValue
            }
        },
        LoadAreaID_class_name: {
            // getter
            get: function () {
                return this.$store.state.loadArea.class_name
            },
            // setter
            set: function (newValue) {
                this.$store.state.loadArea.class_name = newValue
            }
        },
        LoadAreaID_scenario: {
            // getter
            get: function () {
                return this.$store.state.loadArea.Scenario
            },
            // setter
            set: function (newValue) {
                this.$store.state.loadArea.Scenario = newValue
            }
        },

        Application: {
            // getter
            get: function () {
                return this.$store.state.application
            },
            // setter
            set: function (newValue) {
                this.$store.state.application = newValue
            }
        },

        Timeframe: {
            // getter
            get: function () {
                return this.$store.state.timeframe
            },
            // setter
            set: function (newValue) {
                this.$store.state.timeframe = newValue
            }            },

        running: function () {
            return this.$store.state.simulation.running;
        },
        scheduling: function () {
            return this.$store.state.simulation.scheduling;
        }
    },
    watch: {
        'Application': {
            handler (newData, oldData) {
                this.getLoadAreas();
            }
        },
    },

    methods: {
        select_LA(){
            this.LoadAreaID = this.LoadArea_data.LoadAreaID;
            this.LoadAreaID_class_name = this.LoadArea_data.class_name;
            this.LoadAreaID_scenario = this.LoadArea_data.Scenario;
        },
        getLoadAreas() {

            axios.get('api/LA/ids/' + this.$store.state.application)
                .then(response => {
                    this.las = response.data;
                    if (this.las.length > 0) {
                        this.LoadArea_data = this.las[0];
                        this.LoadAreaID = this.las[0].LoadAreaID;
                        this.LoadAreaID_class_name = this.las[0].class_name;
                        this.LoadAreaID_scenario = this.las[0].Scenario;
                    }
                });
        },

        getApplication() {
            axios.get('api/getApplication')
                .then(response => {
                        this.app = response.data
                        if (this.app.length > 0) {
                            this.Application_data= this.app[0];
                            this.Application = this.app[0].description;
                        }
                    }

                )
        },

        select_APP() {
            this.Application = this.Application_data.description;
            console.log('APP', this.Application)
        },

        select_Timeframe() {
            this.Timeframe = this.Timeframes_data
            this.$store.state.timeframe = this.Timeframe
            console.log(this.$store.state.timeframe)

        },

        setMaxPower() {
            this.$http.post('P_MAX', { Power: this.MaxPower, LoadAreaID: this.LoadAreaID})
                .then(response => {
                    EventBus.$emit("timechanged");
                });
        },
    }
}
</script>

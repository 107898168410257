<template>

    <div>

        <md-card>

            <md-card-header>
                <div class="pull-left md-title">{{ LoadAreaID }}</div>
                <div class="pull-right">
                    <md-button  @click.native="refresh" ><md-icon>refresh</md-icon></md-button>
                </div>
                <div class="pull-right">
                    <md-button-toggle type="radio" class="md-button-group md-primary" md-single>
                        <md-button v-bind:class="toggleIfActive('computed')" @click.native="changeChartType('computed')">
                            Computed
                        </md-button>
                        <md-button v-bind:class="toggleIfActive('real')" @click.native="changeChartType('real')">Real
                        </md-button>
                        <md-button v-bind:class="toggleIfActive('comparison')" @click.native="changeChartType('comparison')">
                            Comparison
                        </md-button>
                        <md-button v-bind:class="toggleIfActive('evolution')" v-show="ChartType.view != 'loadarea'"
                                   @click.native="changeChartType('evolution')">Evolution
                        </md-button>
                    </md-button-toggle>
                </div>
            </md-card-header>

            <md-card-content>
                <chart :series="ChartSeries" :ytitle="ChartLabel" :timestamp="ChartTimestamp" class="chart"></chart>
                <md-bottom-bar ref="bottombar">
                    <md-bottom-bar-item md-icon="multiline_chart" md-active @click.native="changeChartView('loadarea')">All
                    </md-bottom-bar-item>
                    <md-bottom-bar-item md-icon="show_chart" v-for="session in ChargingSessions" v-bind:key="session.id"
                                        @click.native="changeChartView(session.SmartChargingSessionID)">Session {{
                        session.SmartChargingSessionID }}
                    </md-bottom-bar-item>
                </md-bottom-bar>

            </md-card-content>
        </md-card>

        <md-card>

            <md-card-header>
                <div class="md-title">Sessions</div>
            </md-card-header>

            <md-card-content>
                <p class="m-b-none" v-if="ChargingSessions.length === 0">
                    No charging session
                </p>

                <table class="table table-borderless m-b-none" v-if="ChargingSessions.length > 0">
                    <thead>
                    <tr>
                        <th></th>
                        <th>Session ID</th>
                        <th>Start of Charging</th>
                        <th>Departure Time</th>
                        <th>Initial SOC</th>
                        <th>Current SOC</th>
                        <th>Desired SOC</th>
                        <th>Current Cost</th>
                        <th>Final Cost</th>
                        <th></th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr v-for="(session, index) in ChargingSessions">
                        <td>
                            <md-button class="md-icon-button md-raised"
                                       v-bind:style="{ 'background-color': Colors[index]}"
                                       @click.native="changeSessionView(index)">
                                <md-icon v-bind:style="{ 'color': 'white'}">show_chart</md-icon>
                            </md-button>
                        </td>

                        <td style="vertical-align: middle;">
                            {{ session.SmartChargingSessionID }}
                        </td>

                        <td style="vertical-align: middle;">
                            {{ session.UserChargingRequest.StartOfCharging | moment("DD/MM/YYYY HH:mm:ss") }}
                        </td>

                        <td style="vertical-align: middle;">
                            {{ session.UserChargingRequest.DesiredDepartureTime | moment("DD/MM/YYYY HH:mm:ss") }}
                        </td>

                        <td style="vertical-align: middle;">
                            {{ SOC(session.UserChargingRequest.InitialSOC,
                            session.SmartChargingSessionSettings.BatteryCapacity) }}
                        </td>

                        <td style="vertical-align: middle;">
                            {{ SOC(session.CurrentState,
                            session.SmartChargingSessionSettings.BatteryCapacity) }}
                        </td>

                        <td style="vertical-align: middle;">
                            {{ SOC(session.UserChargingRequest.DesiredEnergyLevel,
                            session.SmartChargingSessionSettings.BatteryCapacity) }}
                        </td>

                        <td style="vertical-align: middle;">
                            {{ session.CurrentCost }}
                        </td>

                        <!-- Delete Button -->
                        <td style="vertical-align: middle;">
                            {{ session.ExpectedRechargeCost }}
                        </td>

                        <td>
                            <md-menu md-direction="top left">
                                <md-button class="md-icon-button" md-menu-trigger>
                                    <md-icon>info</md-icon>
                                </md-button>

                                <md-menu-content>
                                    <md-menu-item><md-icon>battery_full</md-icon><small><span><b>{{ session.SmartChargingSessionSettings.BatteryCapacity }}kWh</b></span></small></md-menu-item>
                                    <md-menu-item><md-icon>vertical_align_top</md-icon><small><span><b>{{ session.SmartChargingSessionSettings.MaximumPower }}kW</b></span></small></md-menu-item>
                                    <md-menu-item><md-icon>vertical_align_bottom</md-icon><small><span><b>{{ session.SmartChargingSessionSettings.MinimumPower }}kW</b></span></small></md-menu-item>
                                    <md-menu-item><md-icon>settings_input_component</md-icon><small><b><span v-if="session.SmartChargingSessionSettings.ThreePhaseMode">3-phases</span><span v-else>1-phase</span></b></small></md-menu-item>

                                </md-menu-content>
                            </md-menu>

                            <md-button v-if="hasRole('admin')|hasRole('superadmin')" @click.native="remoteStop(session.SmartChargingSessionID)"><md-icon>stop</md-icon></md-button>


                        </td>
                    </tr>
                    </tbody>
                </table>
            </md-card-content>
        </md-card>


    </div>

</template>

<script>
    var chart_utils = require('../../utils/chart');
    var curve_utils = require('../../utils/curve');
    var helper_utils = require('../../utils/helper');
    var config = require('../../config');

    export default {

        props: {
            loadareadata:{
                type: Object,
                required: true,
                 default: function () { return {} }
            },
            timestamp: {
                type: Number,
                required: false
            }
        },

        watch: {
        'timestamp': {
        handler (newData, oldData) {
                if (newData !== oldData) {
                this.ChartTimestamp = newData;
                }
                }
        },
        'loadareadata': {
            handler (newData, oldData) {
                if (newData !== oldData) {
                this.ChargingSessions = newData.ChargingSessions;
                this.PowerThresholdCurve = newData.PowerThresholdCurve;
                this.TargetLoadCurve = newData.TargetLoadCurve;
                this.LoadAreaID = newData.LoadAreaID;
                this.MaxPowerLoadCurve = newData.MaxPowerLoadCurve;

                if (this.checkForValidSessionView()) { this.updateChart(); }
                else { // RESET VIEW IF SESSION DOESN'T EXIST
                    this.changeChartView('loadarea');
                    this.changeSessionView(-1);}
                }
                }
        }, deep: true},

        ready() {

        },
        /**
         * Prepare the component (Vue 2.x).
         */
        mounted() { },
        data() {
            return {
                LoadAreaID: "",
                ChargingSessions: [],
                TargetLoadCurve: [],
                PowerThresholdCurve: [],
                MaxPowerLoadCurve: [],
                ChartSeries: [],
                ChartLabel: "",
                ChartTimestamp: 0,
                ChartType: { view: 'loadarea', type: 'computed' },
                Colors : config.COLORS.SESSIONS,
                _Timer : null
                }
         },
         methods: {
            formatDate(date) {
                return moment(date).format("ll")
            },
            toggleIfActive: function (type) {
                return { 'md-toggle': this.ChartType.type === type  }
            },
            changeSessionView(i){
                this.$refs.bottombar.$children[i+1].setActive(true);
            },
            SOC(kw, capacity) {
                var result = "";
                result += helper_utils.kw2soc(kw, capacity) + "%";
                result += " (" + kw + " kWh)";
                return result;
            },
            checkForValidSessionView(){
                 if (this.ChartType.view === 'loadarea') return true;
                 for (var i = 0; i < this.ChargingSessions.length; i++){
                   if (this.ChartType.view == this.ChargingSessions[i].SmartChargingSessionID)   return true;
                }
                return false;
            },
            changeChartView(s) {
                if (this.ChartType.view != s) {
                this.ChartType.view = s;
                if (this.ChartType.view === 'loadarea' && this.ChartType.type === 'evolution')
                    this.changeChartType('computed');
                else
                    this.updateChart();
                }
            },
            changeChartType(t) {
                if (this.ChartType.type != t) {
                this.ChartType.type = t;
                this.updateChart();
                }
            },
            refresh(){
                EventBus.$emit("timechanged");
            },
            remoteStop(sessionId){
                swal({
                    title: "Remote Stop",
                    text: "You'll stop the charging session.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#f44336",
                    confirmButtonText: "Continue",
                    closeOnConfirm: false,
                    showLoaderOnConfirm: true,
                }, function(isConfirm) {
                    if (!isConfirm) return;
                    this.$http.post('Session/' + sessionId + '/remoteStop', {})
                        .then((response) => {
                            helper_utils.alert(response.data);
                            // success callback
                        }, (response) => {
                            // error callback
                            helper_utils.alert(response.data);
                        });
                }.bind(this));
            },
            updateChart(){
                            var tmp_series = [];

                            if (this.ChartType.type !== 'evolution'){
                                //tmp_series.push(chart_utils.line('PowerThreshold', curve_utils.curveToSerie(this.PowerThresholdCurve), {color:config.COLORS.CURVES[0]}));
                                //tmp_series.push(chart_utils.line('TargetLoadCurve', curve_utils.curveToSerie(this.TargetLoadCurve), {color:config.COLORS.CURVES[1]}));

                                tmp_series.push({
                                    type: "line",
                                    name: "EVSE Target",
                                    id: "EVSE Target",
                                    dashStyle: 'dash',
                                    marker: {
                                        enabled: false
                                    },
                                    data: curve_utils.curveToSerie(this.TargetLoadCurve),
                                    color: '#7798BF'
                                });

                                tmp_series.push(chart_utils.line('Building Consumption', curve_utils.curveToSerie(this.PowerThresholdCurve), {color:config.COLORS.CURVES[0]}));
                                tmp_series.push(chart_utils.line('Max Power', curve_utils.curveToSerie(this.MaxPowerLoadCurve), {color:config.COLORS.CURVES[1]}));


                                this.ChartLabel = "Power (kW)";
                            } else {
                                this.ChartLabel = "Energy (kWh)";
                            }

                            //this.ChartTimestamp = moment().unix();

                            var i;
                                    switch (this.ChartType.type){
                                            case 'evolution':
                                                for (i = 0; i < this.ChargingSessions.length; i++){
                                                    if (this.ChartType.view == this.ChargingSessions[i].SmartChargingSessionID)
                                                        tmp_series.push(chart_utils.area(this.ChargingSessions[i].SmartChargingSessionID, curve_utils.energyCurveToSerie(this.ChargingSessions[i].EstimatedStateCurve), {color:config.COLORS.SESSIONS[i], pointPlacement: +0.5}));
                                                }
                                                break;
                                            case 'computed':
                                                for (i = 0; i < this.ChargingSessions.length; i++){
                                                    if (this.ChartType.view == 'loadarea' ||  this.ChartType.view == this.ChargingSessions[i].SmartChargingSessionID)
                                                        tmp_series.push(chart_utils.column(this.ChargingSessions[i].SmartChargingSessionID, curve_utils.curveToSerie(this.ChargingSessions[i].ComputedLoadCurve), {color:config.COLORS.SESSIONS[i], pointPlacement: +0.5}));
                                                }
                                                break;
                                            case 'real':
                                                for (i = 0; i < this.ChargingSessions.length; i++){
                                                    if (this.ChartType.view == 'loadarea' ||  this.ChartType.view == this.ChargingSessions[i].SmartChargingSessionID)
                                                        tmp_series.push(chart_utils.column(this.ChargingSessions[i].SmartChargingSessionID, curve_utils.curveToSerie(this.ChargingSessions[i].RealLoadCurve), {pointPadding: 0.1, color:config.COLORS.SESSIONS[i], pointPlacement: +0.5}));
                                                }
                                                break;
                                            case 'comparison':
                                                var real_to_aggregate = [];
                                                var computed_to_aggregate = [];
                                                for (i = 0; i < this.ChargingSessions.length; i++){
                                                if (this.ChargingSessions[i].ComputedLoadCurve != null && this.ChartType.view == 'loadarea') {
                                                        computed_to_aggregate.push(this.ChargingSessions[i].ComputedLoadCurve.LoadCurve);
                                                    }
                                                else if (this.ChartType.view == this.ChargingSessions[i].SmartChargingSessionID) {
                                                        tmp_series.push(chart_utils.area('Computed', curve_utils.curveToSerie(this.ChargingSessions[i].ComputedLoadCurve), {color:config.COLORS.SESSIONS[i]}));
                                                    }
                                                if (this.ChargingSessions[i].RealLoadCurve != null && this.ChartType.view == 'loadarea') {
                                                        real_to_aggregate.push(this.ChargingSessions[i].RealLoadCurve.LoadCurve );
                                                    }
                                                else if (this.ChartType.view == this.ChargingSessions[i].SmartChargingSessionID){
                                                        tmp_series.push(chart_utils.column("Real", curve_utils.curveToSerie(this.ChargingSessions[i].RealLoadCurve), {pointPadding: 1, color:config.COLORS.SESSIONS[i], pointPlacement: 0}));
                                                    }
                                                }
                                                if (this.ChartType.view == 'loadarea') {
                                                    tmp_series.push(chart_utils.area('Computed (Aggregated)', curve_utils.arrayToSerie(curve_utils.aggregate(computed_to_aggregate)), {color: config.COLORS.CURVES[0], pointPlacement: +0.5}));
                                                    tmp_series.push(chart_utils.column('Real (Aggregated)', curve_utils.arrayToSerie(curve_utils.aggregate(real_to_aggregate)), {pointPadding: 1, color: config.COLORS.CURVES[0], pointPlacement: 0}));
                                                }
                                                break;
                                    }
                            this.ChartSeries = tmp_series;
            },
            prepareComponent() {

            }



        },
  beforeDestroy: function() {

  }
}



</script>

<template>
    <section class="content">
        <div class="container-fluid">

            <!-- PoDs filter -->
            <div class="row-12">
                <div class="card" style="margin-top:10px">
                    <div class="card-header">
                        <h3 class="card-title">Point of delivery - Filter</h3>
                        <div class="subhead"></div>
                    </div>
                    <div class="card-body">
                        <div class="col">
                            <label >Select Macro Load Area</label>
                            <select class="form-control" v-model="form.mla_identifier" @change="mla_selected" placeolder="Select LA">
                                <option
                                    v-for="id in form.mla_identifiers"
                                 PodsView.vue   :key="id"

                                >{{ id }}</option>

                            </select>
                        </div>
                        <div class="col mt-2" v-if="this.form.mla_identifier.length > 0">
                            <label>Select Load Area</label>
                            <select class="form-control" v-model="form.la_identifier" @change="la_selected">
                                <option
                                    v-for="id in form.la_identifiers"
                                    :key="id"
                                >{{ id }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <!-- PoDs List -->
            <div class="row-12">
                    <div class="card" v-if="$gate.isAdmin()">
                        <div class="card-header">
                            <h3 class="card-title">Point of delivery - List</h3>
                            <div class="card-tools">
                                <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                                    <i class="fa fa-plus-square"></i>
                                    Add New
                                </button>
                            </div>
                        </div>
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th>Macro Load Area</th>
                                    <th>Load Area</th>
                                    <th>Charging Station</th>
                                    <th>EVSE</th>
                                    <th>Connector</th>
                                    <th>Status</th>
                                    <th>Updated</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="pod in form.PODs" v-model="form.PODs" :key="pod.PODID">
                                    <td>{{pod.macro_load_area_identifier}}</td>
                                    <td>{{pod.load_area_identifier}}</td>
                                    <td>{{pod.charging_station_id}}</td>
                                    <td>{{pod.evse_id}}</td>
                                    <td>{{pod.connector_id}}</td>
                                    <td>{{pod.OCPPStatus}}</td>
                                    <td>{{pod.updated_at}}</td>
                                    <td>
                                        <a href="#" @click="remoteStart(pod.PODID)">
                                            Remote Start
                                            <i class="fa fa-play green"></i>
                                        </a>
                                        /
                                        <a href="#" @click="deletePOD(pod.PODID)">
                                            <i class="fa fa-trash red"></i>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
            </div>

            <!-- Not Found -->
            <div v-if="!$gate.isAdmin()">
                <not-found></not-found>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Create New POD</h5>

                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>



                        <form @submit="">
                            <div class="modal-body">
                                <div class="form-group">
                                    <label>MLA</label>
                                    <input type="text" class="form-control" :disabled=true :placeholder="form.mla_identifier">
                                    </input>
                                </div>
                                <div class="form-group">
                                    <label>LA</label>
                                    <input type="text" class="form-control" :disabled=true v-model="form.POD.la" :placeholder="form.la_identifier">
                                    </input>
                                </div>
                                <div class="form-group">
                                    <label>ID</label>
                                    <input v-model="form.POD.id" type="text" name="podid"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('plate') }">
                                    <has-error :form="form" field="plate"></has-error>
                                </div>
                                <div class="form-group">
                                    <label>EVSEID</label>
                                    <input v-model="form.POD.evse_id" type="text" name="evse_id"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('evse_id') }">
                                    <has-error :form="form" field="evse_id"></has-error>
                                </div>

                                <div class="form-group">
                                    <label>SOCKETID</label>
                                    <input v-model="form.POD.socket_id" type="text" name="socket_id"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('socket_id') }" autocomplete="false">
                                    <has-error :form="form" field="socket_id"></has-error>
                                </div>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary" @click="addPOD">Create</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
import moment from "moment";

let helper_utils = require('../../utils/helper');
export default {
    name: "PodsView",

    components: {
    },
    data () {
        return {
            editmode: false,
            tags : {},
            group: {
                resources: ["PODs"],
                orientiation: "horizontal"
            },
            form: new Form({
                Total: 0,
                CurrentPage: 1,
                mla_identifiers: [],
                la_identifiers_by_mla_identifier: {},
                la_identifiers: [],
                la_identifier: "",
                mla_identifier: "",
                ChargingStationID: "",
                SortName: "name",
                SortType: "desc",
                PODs: [],
                POD:{
                    id:"",
                    macro_load_area_identifier:"",
                    load_area_identifier:"",
                    charging_station_id:"",
                    evse_id:"",
                    connector_id:"",
                    ocpp_status:"",
                    ChargingState:"",
                },
                CurrDate: moment().seconds(0),
                Status: "" // Aggiungi il campo Status
            })
        }
    },
    mounted() {
         this.update_mlas();
         this.update_pods();
         this.getPODsConnector();
         this.getPODsEvse();
    },
    computed: {
        LoadAreaID: function () {
            return this.$store.state.loadArea.id
        },
        PODEvents: function() {
            let result = [];
            for (let i = 0; i < this.form.PODs.length; i++){
                let pod = {name: this.form.PODs[i].PODID, appointments:[]}
                let reservations = this.form.PODs[i].Reservations;
                for (let j = 0; j < reservations.length; j++){
                    let reservation = reservations[j];
                    pod.appointments.push({ title: reservation.User.name, start: moment(reservation.start_time).format('HH:mm'), end:  moment(reservation.end_time).format('HH:mm')})
                }
                result.push(pod)
            }
            return result;
        },
        StartInterval: function(){
            return moment(this.form.CurrDate).startOf('day');
        },
        EndInterval: function(){
            return this.StartInterval.add(24,'hours');
        }
    },
    watch: {
        'LoadAreaID': {
            handler (newData, oldData) {
                this.update_pods();
            }
        },
    },
    methods: {
        moveNext(){
            this.form.CurrDate = moment(this.form.CurrDate).add(24,'hours');
        },
        movePrev(){
            this.form.CurrDate = moment(this.form.CurrDate).subtract(24,'hours');
        },
        updateDate(date){
            this.form.CurrDate = date;
            this.update_pods();
        },
        openDialog(ref) {
            this.$refs[ref].open();
        },
        closeDialog(ref) {
            this.$refs[ref].close();
        },

        // -------------------------------------------------------------------------------------------------------------
        // Recupero della lista di POD
        // -------------------------------------------------------------------------------------------------------------

        update_pods() {

            let url = 'api/LA/getPODs';

            let params = new URLSearchParams();

            if ( this.form.mla_identifier.length > 0 ) {
                params.append('MLA', this.form.mla_identifier);
            }

            if ( this.form.la_identifier.length > 0 ) {
                params.append('LA', this.form.la_identifier);
            }

            let url_query = params.toString();
            if ( url_query.length > 0 ) {
                url = url + '?' + url_query;
            }

            axios.get(url)
                .then((response) => {

                    // Check if HTTP response is OK (status === 200)
                    if ( response.status === 200 ) {

                        // Recupero delle informazioni sui POD
                        this.form.PODs = response.data.data;

                        // Aggiunta degli istanti di creazione e modifica
                        for (
                            let i = 0;
                            i < this.form.PODs.length;
                            i++
                        ) {
                            // Per ogni POD aggiustiamo il formato della data
                            this.form.PODs[i].updated_at = moment(
                                this.form.PODs[i].updated_at
                            ).format('YYYY-MM-DD HH:mm:ss');
                        }

                    } else {

                        // Something went wrong with the HTPP response
                        console.error(response);

                    }

                });

        },


        // -------------------------------------------------------------------------------------------------------------
        // Recupero dei connettori e degli evse associati ai pod
        // -------------------------------------------------------------------------------------------------------------

        getPODsConnector() {
            //console.log('getPODsConnector()');
            for (let i = 0; i < this.form.PODs.length; i++) {
                axios.get('api/POD/getConnector/' + this.form.PODs[i].PODID).then((response) => {
                    this.form.PODs[i].ConnectorID = response.data;
                });
            }
        },

        getPODsEvse() {
            //console.log('getPODsEvse()');
            for (let i = 0; i < this.form.PODs.length; i++) {
                axios.get('api/POD/getEvse/' + this.form.PODs[i].PODID).then((response) => {
                    this.form.PODs[i].EVSEID = response.data;
                });
            }
        },

        // -------------------------------------------------------------------------------------------------------------
        // Monitora e calcola lo stato delle POD
        // -------------------------------------------------------------------------------------------------------------

        // -------------------------------------------------------------------------------------------------------------
        // Recupero della singola POD
        // -------------------------------------------------------------------------------------------------------------
        getPOD(podid) {
            axios.get('POD/getReservations/' + podid +
                '?start_time=' + this.StartInterval.format() +
                '&end_time=' + this.EndInterval.format())
                .then(response => {
                    response = response.data;
                    this.form.SelectedPOD = response.data;
                });

        }, //get pod reservation return void in old ca

        selectPOD(idx) {
            this.getPOD(this.form.PODs[idx].PODID);
        },

        deletePOD(id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {

                // Send request to the server
                if (result.value) {
                    this.form.delete('api/POD/'+id).then(()=>{
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        );
                        // Fire.$emit('AfterCreate');
                        this.update_pods();
                    }).catch((data)=> {
                        Swal.fire("Failed!", data.message, "warning");
                    });
                }
            })

        },

        addPOD(){

            // Questo array associativo lega il nome del campo come nel database
            // con il relativo campo del form
            let pod = {
                "PODID": this.form.POD.id,
                "ChargingStationID": this.form.POD.charging_station_id,
                "EVSEID": this.form.POD.evse_id,
                "ConnectorID": this.form.POD.connector_id,
                "LoadAreaID": this.form.la_identifier,
                "OCPPStatus": this.form.POD.ocpp_status,
            };

            axios.post('api/POD', pod).then((result) => {

                        Swal.fire(
                            'Added!',
                            'The POD has been added.',
                            'success'
                        );
                        // Fire.$emit('AfterCreate');
                        this.update_pods();
                    }).catch((data)=> {
                        Swal.fire("Failed!", data.message, "warning");
                    });


        },

        onUserCreateSuccess: function (data) {
            this.closeDialog('add_user_dialog');
            this.getUsers();
        },  //getUser method not defined in old ca

        onUserCreateError: function (data) {
            helper_utils.alert(data);
        },

        remoteStart(podId){
            swal({
                title: "Remote Start",
                text: "You'll start the charging session.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f44336",
                confirmButtonText: "Continue",
                closeOnConfirm: false,
                showLoaderOnConfirm: true,
            }, function(isConfirm) {
                if (!isConfirm) return;
                this.http.post('POD/' + podId + '/remoteStart', {})
                    .then((response) => {
                        helper_utils.alert(response.data);
                        // success callback
                    }, (response) => {
                        //console.log(response);
                        // error callback
                        helper_utils.alert(response.data);ù
                    });
            }.bind(this));
        },  //remote start not found in controller

        // -------------------------------------------------------------------------------------------------------------
        // Questa funzione:
        // 1) recupera le Macro Load Area dal backend
        // 2) popola il menù a tendina
        // -------------------------------------------------------------------------------------------------------------
        update_mlas(){
            axios.get('api/LA/getLoadAreaIdentifiersByMacroLoadAreaIdentifier')
                .then(response => {

                    // Check if HTTP response is OK (status === 200)
                    if ( response.status === 200 ) {

                        // Check if data in the response are okay ( data.success === true )
                        if ( response.data.success ) {

                            // Empty the current list of Macro Load Areas
                            this.form.mla_identifiers = [""];

                            // Empty the current list of Load Area Identifiers By Macro Load Area Identifier
                            this.form.la_identifiers_by_mla_identifier = {}

                            // Get the new Macro Load Area list
                            let mla_identifiers = response.data.data;

                            // Cycle in the Macro Load Area list
                            for ( let mla_identifier in mla_identifiers ) {
                                this.form.mla_identifiers.push(mla_identifier);
                                let la_identifiers = mla_identifiers[mla_identifier]
                                la_identifiers.unshift("");
                                this.form.la_identifiers_by_mla_identifier[mla_identifier] = la_identifiers;

                            }

                        } else {

                            // Something went wrong in the data collected in the response
                            console.error(response.data);

                        }

                    } else {

                        // Something went wrong with the HTPP response
                        console.error(response);

                    }

                });
        },

        // -------------------------------------------------------------------------------------------------------------
        // Evento chiamato quando viene selezionata una Macro Load Area
        // -------------------------------------------------------------------------------------------------------------
        mla_selected(
            event
        ) {
            // Recuperiamo l'identificatore della Macro Load Area che è stata selezionata
            this.form.mla_identifier = event.target.value;

            // Svuotiamo la lista degli identificatori delle load area
            this.form.la_identifiers = this.form.la_identifiers_by_mla_identifier[this.form.mla_identifier]

            // Annulliamo l'identificatore della Load Area
            this.form.la_identifier = ""

            // Aggiorniamo la lista dei Point of Delivery
            this.update_pods();

        },

        // -------------------------------------------------------------------------------------------------------------
        // Evento chiamato quando viene selezionata una Load Area
        // -------------------------------------------------------------------------------------------------------------
        la_selected(
            event
        ) {
            // Recuperiamo l'identificatore della Macro Load Area che è stata selezionata
            this.form.la_identifier = event.target.value;

            // Aggiorniamo la lista dei Point of Delivery
            this.update_pods();
        },

        // -------------------------------------------------------------------------------------------------------------
        // Metodo per la apparizione del modale di inserimento di una PoD
        // -------------------------------------------------------------------------------------------------------------
        newModal(){
            if(
                this.form.mla_identifier == "" ||
                this.form.la_identifier ==""
            ) {
                Swal.fire({
                    title: 'Problem',
                    text: "Please Select Macro Load Area and Load Area before add",
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                });
            } else {
                $('#addNew').modal('show');
            }
        },


    },

}
</script>

<style scoped>

</style>

<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row-12">

                <div class="col-12">

                    <div class="card" v-if="$gate.isAdmin()">
                        <div class="card-header">
                            <h3 class="card-title">LA Update</h3>

                        </div>
                        <!-- /.card-header -->
                        <div class="card-body p-0">

                            <div class="form-group" >
                                <div class="form-row">
                                    <div class="col-sm-2" id="la_update">
                                        <label class="offset-2 mt-2" for="la_update">Select LA</label>
                                    </div>

                                    <div class="col">
                                        <select class="form-control" v-model="form.LoadAreaID" @change="updateLoadAreaID">
                                            <option
                                                v-for="id in form.LoadAreaIDs"
                                                :key="id"

                                            >{{ id }}</option>

                                        </select>
                                    </div>
                                </div>



                                <div class="form-row mt-4" id="pod-update2">
                                    <div class="col-sm-2" id="pod-update">
                                        <label class="offset-2 mt-2" for="pod-update2">Select POD</label>
                                    </div>

                                    <form class="col-md-4" ref="form_addpod" @submit="submitForm">
                                        <div class="md-input-container" >


                                            <input class="form-control" name="podID" v-model="form.PodID"
                                                   required placeholder="POD ID" id="pod-input">

                                        </div>

                                        <button class="btn btn-lg " type="submit">
                                            <i class="fa fa-plus-circle red"></i>
                                        </button>
                                    </form>

                                    <div class="clearfix"/>
                                    <ul class="list-group">
                                        <li v-for="(podid, index) in form.PODList" class=" list-group-item split-text"
                                            style="line-height: 40px;">
                                            POD ID: {{ podid.PODID }}
                                            <button class="btn btn-primary btn-sm" @click="removePOD(index)">
                                                <i class="fa fa-trash red"></i>
                                            </button>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <!--table class="table table-hover">
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Type</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Email Verified?</th>
                                    <th>Created</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </table-->
                        </div>
                        <!-- /.card-body -->
                        <button type="submit" class="btn btn-sm btn-primary" @click="submitData">
                            <i class="fa fa-plus-square"></i>
                            SUBMIT
                        </button>


                    </div>
                    <!-- /.card -->
                </div>


            </div>


            <div v-if="!$gate.isAdmin()">
                <not-found></not-found>

            </div>

            <div class="row">
                <textarea class="col-md-6" style="min-height: 150px; background-color: #eeeeee"
                          readonly>{{ form.Request }}</textarea>
                <textarea class="col-md-6" style="min-height: 150px; background-color: #eeeeee"
                          readonly>{{ form.Response }}</textarea>
            </div>


        </div>

    </section>
</template>

<script>
var helper_utils = require('../../utils/helper');
export default {
    name: "LAUpdateView",
    data () {
        return {
            editmode: false,
            form: new Form({
                MacroLoadAreaID: "MLA1",
                LoadAreaID: "",
                PODList: [],
                ChargingStationIDList: [],
                Request : { },
                Response : {},
                LoadAreaIDs : [],
                LoadAreas: [],
                PodID: ''
            })
        }
    },
    methods: {

        updateLoadAreaID(event){
            this.form.LoadAreaID =event.target.value;
            this.updatePODList();
            this.updateRequest();
        },
        getLoadAreaIDs(){
            this.$Progress.start();

            axios.get('api/LA/getPodsLoadAreaID').then(( data ) => {
                //console.log(typeof data);
                let response;
                response = data.data;


                this.form.LoadAreas = response.data;

                this.form.LoadAreaIDs = [];
                //console.log(this.form.LoadAreas);

                //takes load areas by id and put them in LoadAreasIDs

                for (var id in this.form.LoadAreas) {
                    this.form.LoadAreaIDs.push(this.form.LoadAreas[id].LoadAreaID);
                };
                //console.log(this.form);
                //console.log(this.LoadAreaIDs);
            });

            this.$Progress.finish();
        },
        updatePODList(){
            var tmp = [];
            for (var i=0; i < this.form.LoadAreas.length; i++){

                var LoadArea = this.form.LoadAreas[i];

                if (LoadArea.LoadAreaID === this.form.LoadAreaID){

                    this.form.MacroLoadAreaID = LoadArea.MacroLoadAreaID;

                    for (var j in LoadArea.PODList){
                        tmp.push({PODID: LoadArea.PODList[j].PODID});
                    }
                }
            }

            this.form.PODList = tmp;
            console.log(this.form)

        },
        updateRequest(){
            this.form.Request = { MacroLoadAreaUpdateList : [
                    { MacroLoadAreaID : this.form.MacroLoadAreaID,
                        LAList : [{ LoadAreaID: this.form.LoadAreaID,
                            PODList: this.form.PODList,
                            ChargingStationID: this.form.ChargingStationIDList }]
                    }
                ]}
        },
        removePOD(i){
            this.form.PODList.splice(i, 1);
            this.updateRequest();
        },
        addPOD(){
            this.form.PODList.push({PODID:this.form.PodID});
            this.$refs.form_addpod.reset();
            this.form.PodID = null;
            this.updateRequest();
        },
        submitForm(e) {
            e.preventDefault();
            this.addPOD();
            this.updateRequest();
        },
        submitData() {
            this.$Progress.start();
            console.log(this.form.Request);
            axios.post('/api/CPO/LA_Update', this.form.Request)
                .then((data) => {
                    console.log(data);
                    let response = data.data;
                    this.form.Response = response;
                    helper_utils.alert(response);

                }).catch((error)  => {
                // error callback

                this.form.Response = error.data;
                console.log(error);
                helper_utils.alert(error.data);
            });
            this.$Progress.finish();
        }

    },
    mounted() {
        console.log('EV models Component mounted.')
    },
    created() {

        this.$Progress.start();
        this.getLoadAreaIDs();
        this.$Progress.finish();

        EventBus.$on('applicationchanged', () => {
            this.getLoadAreaIDs();
        });
    }
}
</script>

<style scoped>

</style>


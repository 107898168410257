<template>
    <div class="Typeahead">
        <md-input-container>
            <md-input type="text"
                      :placeholder="placeholder"
                      autocomplete="off"
                      v-model="query"
                      @keydown.down="down"
                      @keydown.up="up"
                      @keydown.enter="hit"
                      @keydown.esc="reset"
                      @blur="reset"
                      @input="findMatches(true)"/>
            <md-button  v-show="isEmpty" @click.native="findMatches(false)" class="md-icon-button" v-bind:style="{ 'position': 'absolute', 'right': 0, 'bottom': '-2px'}"><md-icon>arrow_drop_down</md-icon></md-button>
            <md-button  v-show="isDirty" @click.native="reset" class="md-icon-button" v-bind:style="{ 'position': 'absolute', 'right': 0, 'bottom': '-2px'}"><md-icon>cancel</md-icon></md-button>

        </md-input-container>
        <ul v-show="hasItems">

            <li v-for="(item, index) in items" :class="activeClass(index)" @mousedown="hit" @mousemove="setActive(index)">
                <span class="name" v-text="item"></span>
            </li>
        </ul>
    </div>
</template>


<script>
export default {
props: {
            source:{
                type: Array,
                required: true,
                 default: function () { return [] }
            },
            placeholder:{
                type: String,
                required: true,
            },
            onChange: {
                required: false,
                default: null
            }
            },
data () {
    return {
      items: [],
      query: '',
      current: -1,
      loading: false,
      selectFirst: true,
      minChars: 3
    }
  },

  computed: {
    hasItems () {
      return this.items.length > 0
    },

    isEmpty () {
      return !this.query
    },

    isDirty () {
      return !!this.query
    }
  },

  methods: {
    findMatches(reset) {

     if (reset) {
     if (!this.query) {
        return this.reset()
     }
     }

    //   if (this.minChars && this.query.length < this.minChars) {
    //    return
    //  }

    // an array that will be populated with substring matches
    this.items = [];

    if (this.query.length == 0) {
    this.items = this.source
    return;
    }


    // regex used to determine if a string contains the substring `q`
    var substrRegex = new RegExp(this.query, 'i');

    this.loading = true;
    var vm = this;
    // iterate through the pool of strings and for any string that
    // contains the substring `q`, add it to the `matches` array
        $.each(this.source, function(i, str) {
         if (substrRegex.test(str)) {
         vm.items.push(str);
      }
    });
    this.current = -1
    this.loading = false

    if (this.selectFirst) {
            this.down()
    }

    this.onChange(this.query);

    },

    reset () {
      this.items = []
      this.query = ''
      this.loading = false
    },

    setActive (index) {
      this.current = index
    },

    activeClass (index) {
      return {
        active: this.current === index
      }
    },

    hit () {

      if (this.current !== -1) {
        this.query = this.items[this.current];
        this.onChange(this.query);
        this.items = [];
      }
    },

    up () {
      if (this.current > 0) {
        this.current--
      } else if (this.current === -1) {
        this.current = this.items.length - 1
      } else {
        this.current = -1
      }
    },

    down () {
      if (this.current < this.items.length - 1) {
        this.current++
      } else {
        this.current = -1
      }
    },
  }
}


</script>


<style scoped>
.Typeahead {
  position: relative;
}

.fa-times {
  cursor: pointer;
}
i {
  float: right;
  position: relative;
  top: 30px;
  right: 29px;
  opacity: 0.4;
}
ul {
  position: absolute;
  padding: 0;
  margin-top: 8px;
  min-width: 100%;
  background-color: #fff;
  list-style: none;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0,0,0, 0.25);
  z-index: 1000;
}
li {
  padding: 10px 16px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}
li:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
li:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: 0;
}
span {
  display: block;
  color: #2c3e50;
}
.active {
  background-color: #3f51b5;
}
.active span {
  color: white;
}
.name {
  font-weight: 700;
  font-size: 18px;
}
.screen-name {
  font-style: italic;
}


</style>
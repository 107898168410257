module.exports = {
    area : function (name, curve, options) {
        return  {
            type: "area",
            name: name,
            id: name,
            data: curve,
            pointPlacement: 0,
            lineWidth: 0,
            marker: {
                enabled: true,
            },
            step: 'left', // or 'center' or 'right'
            stacking: null,
            pointPadding: 0,
            groupPadding: 0,
            borderWidth: 1,
            shadow: false,
            fillOpacity: 0.5,
            xAxis: 0,
            yAxis: 0,
            zIndex: 0,
            color: options.color
        };
    },
    point : function (name, curve, options) {
        return  {
            type: "line",
            name: name,
            id: name,
            data: curve,
            pointPlacement: 0,
            lineWidth: 0,
            marker: {
                enabled: true
            },
            step: 'left', // or 'center' or 'right'
            stacking: 'normal',
            stack : 0,
            pointPadding: 0,
            groupPadding: 0,
            borderWidth: 1,
            shadow: false,
            fillOpacity: 0.5,
            xAxis: 0,
            yAxis: 0,
            zIndex: 0,
            color: options.color
        };
    },
    column : function (name, curve, options) {
        return  {
            type: "column",
            name: name,
            id: name,
            data: curve,
            pointPlacement: options.pointPlacement,
            marker: {
                enabled: false
            },
            step: 'left', // or 'center' or 'right'
            stacking: 'normal',
            stack : 0,
            pointPadding: options.pointPadding | 0,
            groupPadding: 0,
            borderWidth: 1,
            shadow: false,
            fillOpacity: 0.5,
            xAxis: 0,
            yAxis: 0,
            zIndex: 0,
            color: options.color
        };
    },
    line: function (name, curve, options) {
        return {
            type: "line",
            name: name,
            id: name,
            data: curve,
            step: 'left',
            pointPlacement: 0,
            lineWidth: 2,
            marker: {
                enabled: false
            },
            stacking: null,
            pointPadding: 0,
            groupPadding: 0,
            borderWidth: 1,
            shadow: false,
            fillOpacity: 0.5,
            zIndex: 1,
            color: options.color
        }
    }
}

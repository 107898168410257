<template>
    <div class="row form-row">
        <div class="col-md-2 form-row-label">
            <span> {{ label }} </span>
        </div>
        <div class="col-md-10 form-row-input"  style="background-color:white;">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            label:{
                type: String,
                required: true
                }
        },
        mounted() {
            console.log('FormRow ready.')
        }
    }
</script>

<template>
    <div>




        <vuetable ref="vuetable"
                  api-url="api/api_activity/get_all"
                  pagination-path=""
                  :per-page="limit"
                  :css="css.table"
                  :sort-order="sortOrder"
                  :fields="fields"
                  pagination-component="vuetable-pagination"
                  @vuetable:pagination-data="onPaginationData"
                  @vuetable:loading="onLoading"
                  @vuetable:loaded="onLoaded"
        >


            <!--template slot="RequestMethod" scope="props">
                <div class="label label-warning" v-html="props.rowData.RequestMethod" v-if="props.rowData.RequestMethod == 'POST'"></div>
                <div class="label label-success" v-html="props.rowData.RequestMethod" v-else-if="props.rowData.RequestMethod == 'GET'"></div>
                <div class="label label-default" v-html="props.rowData.RequestMethod" v-else></div>
            </template-->
            <!--template slot="RequestPath" scope="props">
                <md-chip v-html="props.rowData.RequestPath"></md-chip>
            </template-->
            <div slot="response" slot-scope="props">
                <!--div class="small" json-formatter-js="props.rowData.RequestBody"></div-->
                <vue-json-pretty :path="'res'" :deepCollapseChildren=true :deep= 1 :data="JSON.parse(props.rowData.response)"> </vue-json-pretty>
            </div>

            <div slot="api_header" slot-scope="props">
                <!--div class="small" json-formatter-js="props.rowData.RequestBody"></div-->
                <vue-json-pretty :path="'res'" :deepCollapseChildren=true :deep= 0 :data="JSON.parse(props.rowData.api_header)"> </vue-json-pretty>
            </div>

            <div slot="api_body" slot-scope="props">
                <!--div class="small" json-formatter-js="props.rowData.RequestBody"></div-->
                <vue-json-pretty :path="'res'" :deepCollapseChildren=true :deep= 0 :data="JSON.parse(props.rowData.api_body)"> </vue-json-pretty>
            </div>

            <!--template slot="ResponseBody" scope="props">
                <div class="small" v-json-formatter="props.rowData.ResponseBody"></div>
            </template-->
        </vuetable>

        <div class="form-group">
        <vuetable-pagination-info v-if="paginate" ref="paginationInfo" :css="css.paginationInfo"
        ></vuetable-pagination-info>

        <vuetable-pagination v-if="paginate" ref="pagination"
                             @vuetable-pagination:change-page="onChangePage"
                             :css="css.pagination"></vuetable-pagination>
        </div>

    </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import moment from 'moment';



export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetablePaginationInfo,
        VueJsonPretty
    },

    props: {
        limit: {
            type: Number,
            required: true,
            default:  100
        },
        paginate: {
            type:Boolean,
            default: false

        }
    },
    mounted() {
        //console.log('RequestLog ready.');

    },
    data() {
        return {
            fields: [
                {
                    name: 'ip',
                    title: 'RequestAddress',
                    sortField: 'RequestAddress'
                },
                {
                    name: 'type',
                    title: 'RequestMethod',
                    sortField: 'RequestMethod'
                },
                {
                    name: 'path',
                    title: 'RequestPath',
                    sortField: 'RequestPath'
                },
                {
                    name: 'key',
                    title: 'RequestKey',
                    sortField: 'key'
                },
                {
                    name:'api_header',
                    title: 'RequestHeader',
                    sortField: 'api_header'
                },
                {
                    name: 'api_body',
                    title: 'RequestBody',
                    sortField: 'RequestBody',
                    formatter (value){
                        //console.log(value);
                        return JSON.stringify(value);
                    }
                },
                {
                    name: 'response',
                    title: 'ResponseBody',
                    sortField: 'ResponseBody'
                },
                {
                    name: 'created_at',
                    title: 'created_at',
                    sortField: 'created_at',
                    formatter (value){
                        return moment(value).format('DD/MM/YYYY HH:mm:ss');
                    }
                }
            ],
            css: {
                table: {
                    tableClass: 'table table-striped table-bordered table-hovered',
                    loadingClass: 'loading',
                    ascendingIcon: 'glyphicon glyphicon-chevron-up',
                    descendingIcon: 'glyphicon glyphicon-chevron-down',
                    handleIcon: 'glyphicon glyphicon-menu-hamburger',
                },
                pagination: {
                    infoClass: 'pull-left',
                    wrapperClass: 'pagination justify-content-end',
                    activeClass: 'page-item',
                    disabledClass: 'disabled',
                    pageClass: 'page-link',
                    linkClass: 'page-link',
                    icons: {
                        first: '',
                        prev: '',
                        next: '',
                        last: '',
                    },
                },
                paginationInfo : {
                    infoClass: 'form-control pull-right',
                }
            },
            sortOrder: [
                { field: 'created_at', direction: 'desc' }
            ]
        }
    },
    computed: {},
    methods: {
        onPaginationData(paginationData){
            if (this.paginate){
                //console.log("pagination data");
                //console.log(paginationData);
                this.$refs.pagination.setPaginationData(paginationData)
                this.$refs.paginationInfo.setPaginationData(paginationData)
            }
        },
        onChangePage(page){
            this.$refs.vuetable.changePage(page);
        },
        onLoading(){
            this.$Progress.start();
            //console.log("loading");
        },
        onLoaded(){
            this.$Progress.finish();
            //console.log("loaded");
        },
        refresh(){
            this.$refs.vuetable.refresh()
        }
    }
}
</script>

<template>

    <div>

        <div class="container-fluid">

            <md-card-header>
                <h3> LM Target</h3>

            </md-card-header>

            <md-card-content>

                <div class="form-group">
                    <form-row label="LoadAreaID">
                            <select class="form-control" v-model="LoadAreaID" name="LoadAreaID" id="LoadAreaID">
                                <option v-for="(loadarea, index) in LoadAreas" v-bind:value="loadarea.LoadAreaID">{{
                                        loadarea.LoadAreaID }}
                                </option>
                            </select>
                    </form-row>
                    <form-row label="StartOfLMR">
                        <kendo-datetimepicker @change="updateStartOfLMR"
                                              :format="'yyyy-MM-dd HH:mm:ss'"
                                              :stepping="Stepping"
                                              :value="new Date()"
                                              label="StartOfLMR"></kendo-datetimepicker>
                    </form-row>
                    <div>
                        <div>
                                <label> TargetLoadCurve</label>
                                <curve-builder-dash :start-time="Request.startOfLMR" :on-change="updateTargetLoadCurve"
                                                    :stepping="Stepping" :steps="TargetSteps"></curve-builder-dash>

                        </div>

                        <div>
                                <label> PowerThreshold</label>
                                <curve-builder-dash :start-time="Request.startOfLMR" :on-change="updatePowerThreshold"
                                                    :stepping="Stepping" :steps="ThresholdSteps"></curve-builder-dash>
                        </div>
                        <div v-show="this.class_name == 'MCAAlgorithm'">
                            <label> PowerThreshold V2G </label>
                            <curve-builder-dash :start-time="Request.startOfLMR" :on-change="updateMinPowerThreshold"
                                                :stepping="Stepping" :steps="ThresholdSteps_v2g"></curve-builder-dash>
                        </div>
                    </div>

                </div>

            </md-card-content>
            <md-button class="md-raised md-accent pull-right" @click.native="submitData">Submit
            </md-button>
        </div>


    </div>

</template>

<script>
import moment from 'moment'
var chart_utils = require('../../utils/chart');
var curve_utils = require('../../utils/curve');
var helper_utils = require('../../utils/helper');
var config = require('../../config');
import {DateTimePicker} from "@progress/kendo-dateinputs-vue-wrapper";

export default {
    components: {
        'kendo-datetimepicker': DateTimePicker
    },
    /**
     * Prepare the component (Vue 2.x).
     */
    mounted() {
        this.getLoadAreaIDs();
        this.getStartOfLMR();
        this.LoadAreaID = this.$store.state.loadArea.id;
        //this.Request.startOfLMR = moment(new Date()).format();
        EventBus.$on('applicationchanged', () => {
            this.getLoadAreaIDs();
        });
    },
    data() {
        return {
            Request : {
                AreaType: "1",
                DSORequestID: "DSO_Request",
                startOfLMR : "",
                TargetLoadCurve: "",
                PowerThreshold: "",
                MinPowerThreshold: "",
                Priority: 5,
                energyType: "0",
                eventDuration: 24
            },
            Response : {},
            LoadAreas: [],
            LoadAreaID: "",
            class_name: "",
            TargetSteps: [],
            ThresholdSteps: [],
            ThresholdSteps_v2g: [],
            Stepping: config.SLOT_SAMPLE,
            SAMPLING_TIME: ''
        }
    },
    watch: {
        'LoadAreaID': {
            handler (newData, oldData) {
                this.Request.AreaID = newData;
                this.fetchLAData(newData);
            }
        }
    },

    methods: {
        getStartOfLMR(){
            if (this.$store.state.samplingTime === 1)
            {
                this.Request.startOfLMR = moment(new Date()).format();
            }
            else {
                this.Request.startOfLMR = this.getRoundedDatetime(this.$store.state.samplingTime)
            }
        },

        getRoundedDatetime(samplingtime) {
            if (samplingtime  === 5) {
                let date = new Date();
                let minutes = date.getMinutes()
                //console.log(minutes.toString())
                if (minutes.toString().length == 2)
                {
                    let second_digit = minutes.toString()[1]
                    if (second_digit >= 0 && second_digit < 5 ) {
                        //console.log('minutes:', minutes)
                        //console.log('first_digit:', minutes.toString()[0])
                        let str = minutes.toString()[0] + '0';
                        let new_minutes = Number(str);
                        //console.log('LM_TARGET: before set:', date)
                        date.setMinutes(new_minutes);
                        date.setSeconds(0);
                        //console.log('LM_TARGET: before set:', date)
                        return moment(date).format()
                    }
                    else {
                        //console.log('minutes:', minutes)
                        //console.log('first_digit:', minutes.toString()[0])
                        let str = minutes.toString()[0] + '5';
                        let new_minutes = Number(str);
                        //console.log('LM_TARGET: before set:', date)
                        date.setMinutes(new_minutes);
                        date.setSeconds(0);
                        //console.log('LM_TARGET: before set:', date)
                        return moment(date).format()                }
                }
                else if (minutes.toString().length === 1) {
                    let unique_digit = minutes.toString()[0]
                    if (unique_digit >= 0 && unique_digit < 5 ) {
                        //console.log('minutes:', minutes)
                        //console.log('first_digit:', minutes.toString()[0])
                        let str = '0';
                        let new_minutes = Number(str);
                        //console.log('LM_TARGET: before set:', date)
                        date.setMinutes(new_minutes);
                        date.setSeconds(0);
                        //console.log('LM_TARGET: before set:', date)
                        return moment(date).format()
                    }
                    else {
                        console.log('minutes:', minutes)
                        console.log('first_digit:', minutes.toString()[0])
                        let str = '5';
                        let new_minutes = Number(str);
                        console.log('LM_TARGET: before set:', date)
                        date.setMinutes(new_minutes);
                        date.setSeconds(0);
                        console.log('LM_TARGET: before set:', date)
                        return moment(date).format()                }
                }

            }
        },

        updateStartOfLMR(date){
            this.Request.startOfLMR = moment(date.sender._value).format();
            console.log(this.Request.startOfLMR);
        },
        updateTargetLoadCurve(loadcurve){
            this.Request.TargetLoadCurve = loadcurve;
        },
        updatePowerThreshold(loadcurve){
            this.Request.PowerThreshold = loadcurve;
        },
        updateMinPowerThreshold(loadcurve){
            this.Request.MinPowerThreshold = loadcurve;
        },
        updateRequest(key, value){
            this.Request[key] = value;
        },

        changeSessionView(i){
            this.$refs.bottombar.$children[i+1].setActive(true);
        },
        prepareComponent() {

        },
        getLoadAreaIDs() {
            axios.get('api/LA/ids/' + this.$store.state.application)
                .then((response) => {
                    this.LoadAreas = response.data;
                    console.log(this.LoadAreas);
                });
        },

        findSlicingindex2(arr, days)
        {
            var arr_timestamp = []
            arr.forEach(element => arr_timestamp.push(element.timestamp))
            var goal = Math.round(Date.now()/1000) - days*60*60*24;
            var closest = arr_timestamp.reduce(function(prev, curr) {
                return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
            });
            //console.log('CLOSEST', new Date(closest * 1000))
            console.log(arr_timestamp.indexOf(closest))
            //console.log('TIMESTAMP SELECTED', new Date(arr[arr_timestamp.indexOf(closest)].timestamp*1000))
            return Math.max(arr_timestamp.length - arr_timestamp.indexOf(closest),10)
        },

        sliceCurve(curve) {

            this.timeframe_threshold = {'Last 24 Hours' : 1,
                'Last 7 Days' : 7,
                'Last 14 Days' : 14,
            }

            var middle_target = this.findSlicingindex2(curve.LATargetLoadCurve.LoadCurve, this.timeframe_threshold[this.$store.state.timeframe])
            var middle_threshold = this.findSlicingindex2(curve.LAPowerThreshold.LoadCurve, this.timeframe_threshold[this.$store.state.timeframe])


            this.LATarget = {'LoadCurve': curve.LATargetLoadCurve.LoadCurve.slice(-middle_target)}
            //console.log(-this.timeframe_target[this.$store.state.timeframe])
            //console.log('notslice', curve.LATargetLoadCurve.LoadCurve)
            //console.log('slice', this.LATarget)
            this.LAThreshold = {'LoadCurve': curve.LAPowerThreshold.LoadCurve.slice(-middle_threshold)}
        },

        fetchLAData() {
            axios.get('api/LA/' + this.LoadAreaID+'/Sessions', { params: {timeframe: this.$store.state.timeframe}})
                .then((response) => {

                    if (this.$store.state.timeframe == 'All' || response.data.LAPowerThreshold == null || response.data.LATargetLoadCurve == null)
                    {
                        this.ThresholdSteps = curve_utils.curveToSteps(response.data.LAPowerThreshold);
                        this.TargetSteps = curve_utils.curveToSteps(response.data.LATargetLoadCurve);
                        this.ThresholdSteps_v2g = curve_utils.curveToSteps(response.data.LAMinPowerThreshold);
                        this.class_name = response.data.class_name;
                    }
                    else {
                        console.log(this.$store.state.timeframe)
                        this.sliceCurve(response.data)
                        this.ThresholdSteps = curve_utils.curveToSteps(this.LAThreshold);
                        console.log(this.LATarget)
                        this.TargetSteps = curve_utils.curveToSteps(this.LATarget);
                        this.ThresholdSteps_v2g = curve_utils.curveToSteps(response.data.LAMinPowerThreshold);
                        this.class_name = response.data.class_name;
                    }
                }, (response) => {
                    helper_utils.notify(response.data);
                });
        },
        submitData() {
            axios.post('api/LA/LM_Target_Testing', this.Request)
                .then((response) => {
                    this.Response = response.data;
                    EventBus.$emit("timechanged");
                    helper_utils.success()
                }).catch((error) => {
                // error callback
                helper_utils.alert(error.response);
            });
        },

        submitData_cpo() {
            axios.post('api/CPO/LM_Target', this.Request)
                .then((response) => {
                    this.Response = response.data;
                    EventBus.$emit("timechanged");
                    helper_utils.success()
                }).catch((error) => {
                // error callback
                helper_utils.alert(error.response);
            });
        },




    }
}




</script>

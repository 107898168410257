<template>

    <section class="content">
      <div class="container-fluid">
        <!--load-area-ict :loadareadata="LoadAreaData" :timestamp="CurrentTimestamp" v-if="LoadAreaID === 'LoadAreaICT'"/>
        <load-area :loadareadata="LoadAreaData" :timestamp="CurrentTimestamp" v-else /-->
          <load-area :loadareadata="LoadAreaData" :timestamp="CurrentTimestamp" :application="this.$store.state.application"/>
          <api-log v-if="$gate.isAdmin()" ref="requestlog" :limit="10" :paginate="true"></api-log>

      </div>
    </section>

</template>

<script>
    var chart_utils = require('../utils/chart');
    var curve_utils = require('../utils/curve');
    var helper_utils = require('../utils/helper');
    var config = require('../config');
    import moment from 'moment'
    export default {
        /**
         * Prepare the component (Vue 2.x).
         */
        mounted() {
            //console.log('LoadAreaView ready.');

            this.getSamplingTime();
            //this.fetchData();
            this._Timer = setInterval(function () {
                    this.fetchData();
                }.bind(this), 30000);



            EventBus.$on('simstatechanged', function (state) {
                if (!state) {
                    clearInterval(this._Timer);
                    console.log('simstatechanged');
                    this._Timer = setInterval(function () {
                        this.fetchData();
                    }.bind(this), 30000); //update interval, use this to set the fecthData interval
                                                  //default value: 30000, se to increse the update interval: 100000

                } else {
                    clearInterval(this._Timer);
                }
                }.bind(this));

            EventBus.$on('timechanged', () => {
                console.log('timechangeddetected');
                this.fetchData();
                });

        },
        computed: {
            LoadAreaID: function () {
                    return this.$store.state.loadArea.id
                    //return "LA1";
                },

            Timeframe: function () {
                return this.$store.state.timeframe
                //return "LA1";
            },
            },
         watch: {
            'LoadAreaID': {
                handler (newData, oldData) {
                     this.fetchData();
                 }
            },
             'Timeframe': {
                 handler (newData, oldData) {
                     this.fetchData();
                 }
             }
         },
        data() {
            return {
                LoadAreaData: {},
                CurrentTimestamp: 0,
                timestampStatus: '',
                storeStatus: null,
                }
         },
         methods: {
            getSamplingTime()
            {
                axios.get('api/LA/getSamplingTime')
                    .then((response) => {
                        this.$store.state.samplingTime = response.data;
                    });
            },

             refresh(){
                 this.$refs.requestlog.refresh()
             },
            prepareComponent() {
                this.fetchData();
            },

             findSlicingindex2(arr, days)
             {
                 var arr_timestamp = []
                 arr.forEach(element => arr_timestamp.push(element.timestamp))
                 var goal = Math.round(Date.now()/1000) - days*60*60*24;
                 var closest = arr_timestamp.reduce(function(prev, curr) {
                     return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
                 });
                 //console.log('CLOSEST', new Date(closest * 1000))
                 //console.log(arr_timestamp.indexOf(closest))
                 //console.log('TIMESTAMP SELECTED', new Date(arr[arr_timestamp.indexOf(closest)].timestamp*1000))
                 return Math.max(arr_timestamp.length - arr_timestamp.indexOf(closest),10)
             },

             sliceCurve(curve) {

                 this.timeframe_threshold = {'Last 24 Hours' : 1,
                     'Last 7 Days' : 7,
                     'Last 14 Days' : 14,
                 }

                 var index_target = this.findSlicingindex2(curve.LATargetLoadCurve.LoadCurve, this.timeframe_threshold[this.$store.state.timeframe])
                 var index_threshold = this.findSlicingindex2(curve.LAPowerThreshold.LoadCurve, this.timeframe_threshold[this.$store.state.timeframe])


                 this.LATarget = {'LoadCurve': curve.LATargetLoadCurve.LoadCurve.slice(-index_target)}
                 //console.log(-this.timeframe_target[this.$store.state.timeframe])
                 //console.log('notslice', curve.LATargetLoadCurve.LoadCurve)
                 //console.log('slice', this.LATarget)
                 this.LAThreshold = {'LoadCurve': curve.LAPowerThreshold.LoadCurve.slice(-index_threshold)}
             },

            fetchData() {

                if (this.LoadAreaID == null || this.LoadAreaID == '')
                    return;

                axios.get('api/LA/' + this.LoadAreaID + '/Sessions', { params: {timeframe: this.$store.state.timeframe}}
                )
                        .then(async response => {


                            if (this.$store.state.timeframe == 'All' || response.data.LAPowerThreshold == null || response.data.LATargetLoadCurve == null) {

                                var data = {
                                    ChargingSessions: response.data.SmartChargingSessions,
                                    PowerThresholdCurve: response.data.LAPowerThreshold,
                                    TargetLoadCurve: response.data.LATargetLoadCurve,
                                    MaxPowerLoadCurve: response.data.MacroLoadArea.MLAPowerThreshold,
                                    MinPowerThresholdCurve: response.data.LAMinPowerThreshold,
                                    LoadAreaID: response.data.LoadAreaID
                                }
                            } else {
                                this.t1 = Date.now() / 1000
                                this.sliceCurve(response.data)
                                this.t2 = Date.now() / 1000
                                //console.log('seconds for slicing curve: ', this.t2 -this.t1)
                                var data = {
                                    ChargingSessions: response.data.SmartChargingSessions,
                                    PowerThresholdCurve: this.LAThreshold,
                                    TargetLoadCurve: this.LATarget,
                                    MaxPowerLoadCurve: response.data.MacroLoadArea.MLAPowerThreshold,
                                    MinPowerThresholdCurve: response.data.LAMinPowerThreshold,
                                    LoadAreaID: response.data.LoadAreaID
                                }
                            }

                            this.scs_to_pod = {}
                            for (let session_data in response.data.SmartChargingSessions) {
                                let session_id = response.data.SmartChargingSessions[session_data].SmartChargingSessionID
                                let pod_id = response.data.SmartChargingSessions[session_data].PODID

                                await axios.get('api/POD/' + pod_id).then(response => {

                                    this.scs_to_pod[session_id] = response.data



                                })

                            }
                            data["PodData"] = this.scs_to_pod

                            this.CurrentTimestamp = response.data.Timestamp;
                            //console.log('LOADAREAVIEW: New current TIMESTAMP',this.CurrentTimestamp);
                            this.LoadAreaData = data;
                            this.storeStatus = response.data.status
                            //console.log("store status is ", this.storeStatus)

                            this.t3 = Date.now() / 1000
                            this.computeExtremes(data.ChargingSessions);
                            this.t4 = Date.now() / 1000
                            //console.log('seconds for computing extremes: ', this.t4 -this.t3)

                        });
            },
             computeStartingTimeforSimulation(cs) {
                // this is the case for SAMPLING TIME = 5m
                 // indeed, if samplingtime = 5m, then I will adjust the starting time only for the Simulation
                 var start = Math.floor(moment(cs.UserChargingRequest.StartOfCharging).unix() / (this.$store.state.samplingTime * config.MINUTE)) * (this.$store.state.samplingTime * config.MINUTE);
                 var date = new Date(start*1000)
                 let minutes = date.getMinutes()
                 if (minutes.toString().length === 2)
                 {
                     let second_digit = minutes.toString()[1]
                     if (second_digit > 3 || second_digit < 8) {
                         let str = minutes.toString()[0] + '8';
                         let new_minutes = Number(str);
                         date.setMinutes(new_minutes);
                     }
                     else if (second_digit >= 0 || second_digit < 3)
                     {
                         let str = minutes.toString()[0] + '3';
                         let new_minutes = Number(str);
                         date.setMinutes(new_minutes);
                     }
                     else if (second_digit === 9) {
                         let str = minutes.toString()[0] + '8';
                         let new_minutes = Number(str);
                         date.setMinutes(new_minutes);
                     }
                 }
                 else if (minutes.toString().length === 1)
                 {
                     let unique_digit = minutes.toString()[0]
                     if (unique_digit > 3 || unique_digit < 8) {
                         let str = '8';
                         let new_minutes = Number(str);
                         date.setMinutes(new_minutes);
                     }
                     else if (unique_digit >= 0 || unique_digit < 3)
                     {
                         let str = '3';
                         let new_minutes = Number(str);
                         date.setMinutes(new_minutes);
                     }
                     else if (unique_digit === 9) {
                         let str = '8';
                         let new_minutes = Number(str);
                         date.setMinutes(new_minutes);
                     }                 }
                 return date/1000;

             },

            computeExtremes(chargingsessions){
                if (this.$store.state.samplingTime === 1)
                {
                    for (var i = 0; i < chargingsessions.length; i++){
                        var cs = chargingsessions[i];
                        var start = Math.floor(moment(cs.UserChargingRequest.StartOfCharging).unix() / (this.$store.state.samplingTime * config.MINUTE)) * (this.$store.state.samplingTime * config.MINUTE);
                        var end = moment(cs.UserChargingRequest.DesiredDepartureTime).unix();
                        this.$store.state.loadArea.start = this.$store.state.loadArea.start == 0 ? start : Math.min(this.$store.state.loadArea.start, start);
                        this.$store.state.loadArea.end = Math.max(this.$store.state.loadArea.end, end);
                    }
                }
                else if (this.$store.state.samplingTime === 5 || this.$store.state.simulation.running === true) {
                    for (var i = 0; i < chargingsessions.length; i++){
                        var cs = chargingsessions[i];
                        var start = this.computeStartingTimeforSimulation(cs)
                        var end = moment(cs.UserChargingRequest.DesiredDepartureTime).unix();
                        this.$store.state.loadArea.start = this.$store.state.loadArea.start == 0 ? start : Math.min(this.$store.state.loadArea.start, start);
                        // COMMENT THIS. THIS IS ONLY FOR CHARGEADVISOR-EVOS
                        this.$store.state.loadArea.start = new Date()/1000;
                        //console.log(new Date())
                        this.$store.state.loadArea.end = Math.max(this.$store.state.loadArea.end, end);
                    }
                    //console.log('store.state.start: ', this.$store.state.simulation.running, new Date(this.$store.state.loadArea.start*1000));
                }
            }


        },
  beforeDestroy: function() {
    clearInterval(this._Timer);
    this.$store.state.loadArea.start = 0;
    this.$store.state.loadArea.end = 0;
  }
}



</script>




/**
 * A datetime picker control.
 *
 * @param model
 *    the model bind to the control, which must be a two way binding variable.
 * @param type
 *    the optional type of this datetime picker control. Available values are
 *    - "datetime": Indicating that this control is a datetime picker,
 *    - "date": Indicating that this control is a date picker,
 *    - "time": Indicating that this control is a time picker.
 *    Default value is "datetime".
 * @param language
 *    the optional language code used to localize the control, which must be
 *    a valid language code supported by the moment.js library. If it is not set,
 *    and the [vue-i18n](https://github.com/Haixing-Hu/vue-i18n) plugin is used,
 *    the component will use the language code `$language` provided by the
 *    [vue-i18n](https://github.com/Haixing-Hu/vue-i18n) plugin; otherwise, the
 *    component will use the default value "en-US".
 * @param datetimeFormat
 *    the optional format of the datetime this component should display, which
 *    must be a valid datetime format of the moment.js library. This property
 *    only works when the "type" property is "datetime". Default value is
 *    "YYYY-MM-DD HH:mm:ss".
 * @param dateFormat
 *    the optional format of the date this component should display, which
 *    must be a valid datetime format of the moment.js library. This property
 *    only works when the "type" property is "date". Default value is
 *    "YYYY-MM-DD".
 * @param timeFormat
 *    the optional format of the time this component should display, which
 *    must be a valid datetime format of the moment.js library. This property
 *    only works when the "type" property is "time". Default value is
 *    "HH:mm:ss".
 * @param name
 *    the optional name of the selection control.
 * @param onChange
 *    the optional event handler triggered when the value of the datetime picker
 *    was changed. If this parameter is presented and is not null, it must be a
 *    function which accept one argument: the new date time, which is a moment
 *    object.
 */

<template>
    <md-input class='md-has-value'>
        <md-input></md-input>
    </md-input>

    <!--<div class='input-group date'>
     <input class='form-control' :name='name' type='text' />

    </div>-->
</template>

<script>

/**
 * The array of names of the tooltip messages of the datetime picker.
 *
 * This is a constant and should not be modified.
 */
var DATETIME_PICKER_TOOLTIPS = [
    "today", "clear", "close",
    "selectMonth", "prevMonth", "nextMonth",
    "selectYear", "prevYear", "nextYear",
    "selectDecade", "prevDecade", "nextDecade",
    "prevCentury", "nextCentury",
    "pickHour", "incrementHour", "decrementHour",
    "pickMinute", "incrementMinute", "decrementMinute",
    "pickSecond", "incrementSecond", "decrementSecond",
    "togglePeriod", "selectTime"
];

var DEFAULT_LANGUAGE = "en-US";
/**
 * The default language used by this component.
 */


require('../../libs/bootstrap-datetimepicker.min')


export default {
    props: {
        label:{
            type: String,
            required: false,
            default: "Label"
        },
        defaultDate: {
            required: false,
            default: function () { return moment().seconds(0); }
        },
        type: {
            type: String,
            required: false,
            default: "datetime"
        },
        language: {
            type: String,
            required: false,
            default: ""
        },
        datetimeFormat: {
            type: String,
            required: false,
            default: "YYYY-MM-DD HH:mm:ss"
        },
        dateFormat: {
            type: String,
            required: false,
            default: "YYYY-MM-DD"
        },
        timeFormat: {
            type: String,
            required: false,
            default: "HH:mm:ss"
        },
        name: {
            type: String,
            required: false,
            default: ""
        },
        onChange: {
            required: false,
            default: null
        },
        stepping: {
            required: false,
            default: 1
        },
        disabled:{
            type: Boolean,
            required: false,
            default: false
        },
    },
    watch: {
        'defaultDate': {
        handler (newData, oldData) {
                 if (newData !== oldData && newData != null) {
                  this.control.date(newData);

                }
            }
         },
         'disabled': {
         handler (newData, oldData) {
             if (newData != oldData) {
                  if (newData) this.control.disable();
                  else this.control.enable();
            }
         }
        }
    },
    data : function() {
      return {
        control: undefined
      }
    },
    beforeCreate: function() {
        this.control = null;
    },
    mounted() {
        var options = {
            stepping: this.stepping,
            sideBySide: true,
            showTodayButton: true,
            defaultDate: this.defaultDate,
            showClose: true,
            allowInputToggle: true
        };
        // set the locale
        var language = this.language;
        if (language === null || language === "") {
            if (this.$language) {
                language = this.$language;
            } else {
                language = DEFAULT_LANGUAGE;
            }
        }
        options.locale = this.getLanguageCode(language);
        // set the format
        switch (this.type) {
            case "date":
                options.format = this.dateFormat;
                break;
            case "time":
                options.format = this.timeFormat;
                break;
            case "datetime":
            default:
                options.format = this.datetimeFormat;
                break;
        }
        // use the vue-i18n plugin for localize the tooltips
        if (this.$i18n && this.$i18n.datetime_picker) {
            var messages = this.$i18n.datetime_picker;
            var tooltips = $.fn.datetimepicker.defaults.tooltips;
            for (var i = 0; i < DATETIME_PICKER_TOOLTIPS.length; ++i) {
                var name = DATETIME_PICKER_TOOLTIPS[i];
                if (messages[name]) {
                    tooltips[name] = messages[name];    // localize
                }
            }
            options.tooltips = tooltips;
        }
        // create the control
        $(this.$el).datetimepicker(options);

        this.control = $(this.$el).data("DateTimePicker");
        // set the date to the current value of the model
        this.onChange(this.control.date())

        if (this.disabled) {
          this.control.disable();
        } else this.control.enable();

        var me = this;
        $(this.$el).on("dp.change", function () {
                       if (me.onChange) {
                        me.onChange(me.control.date());
                    }
        });


    },
    methods: {
        /**
         * Gets the language code from the "language-country" locale code.
         *
         * The function will strip the language code before the first "-" of a
         * locale code. For example, pass "en-US" will returns "en". But for some
         * special locales, the function reserves the locale code. For example,
         * the "zh-CN" for the simplified Chinese and the "zh-TW" for the
         * traditional Chinese.
         *
         * @param locale
         *    A locale code.
         * @return
         *    the language code of the locale.
         */
        getLanguageCode: function(locale) {
            if (locale === null || locale.length === 0) {
                return "en";
            }
            if (locale.length <= 2) {
                return locale;
            } else {
                switch (locale) {
                    case "zh-CN":
                    case "zh-TW":
                    case "ar-MA":
                    case "ar-SA":
                    case "ar-TN":
                    case "de-AT":
                    case "en-AU":
                    case "en-CA":
                    case "en-GB":
                    case "fr-CA":
                    case "hy-AM":
                    case "ms-MY":
                    case "pt-BR":
                    case "sr-CYRL":
                    case "tl-PH":
                    case "tzm-LATN":
                    case "tzm":
                        return locale.toLowerCase();
                    default:
                        // reserve only the first two letters language code
                        return locale.substr(0, 2);
                }
            }
        }
    }
};
</script>

<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12">

                    <div class="card" v-if="$gate.isAdmin()">
                        <div class="card-header">
                            <h3 class="card-title">EV Models List</h3>

                            <div class="card-tools">

                                <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                                    <i class="fa fa-plus-square"></i>
                                    Add New
                                </button>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th>Manufacturer</th>
                                    <th>Model</th>
                                    <th>Max Power(kW)</th>
                                    <th>Min Power(kW)</th>
                                    <th>Battery Capacity</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="EV_model in EV_models.data" :key="EV_models.id">
                                    <td class="text-capitalize">{{EV_model.manufacturer}}</td>
                                    <td class="text-capitalize">{{EV_model.model}}</td>
                                    <td class="text-capitalize">{{EV_model.max_power}}</td>
                                    <td class="text-capitalize">{{EV_model.min_power}}</td>
                                    <td>{{EV_model.battery_capacity}}</td>
                                    <td>


                                        <a href="#" @click="editModal(EV_model)">
                                            <i class="fa fa-edit blue"></i>
                                        </a>
                                        /
                                        <a href="#" @click="deleteEVModel(EV_model.id)">
                                            <i class="fa fa-trash red"></i>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer">
                            <pagination :data="EV_models" @pagination-change-page="getResults"></pagination>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>


            <div v-if="!$gate.isAdmin()">
                <not-found></not-found>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" v-show="!editmode">Create New EV</h5>
                            <h5 class="modal-title" v-show="editmode">Update EV's Info</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <!-- <form @submit.prevent="createUser"> -->

                        <form @submit.prevent="editmode ? updateEVModel() : createEVModel()">
                            <div class="modal-body">
                                <div class="form-group">
                                    <label>Manufacturer</label>
                                    <input v-model="form.manufacturer" type="text" name="manufacturer"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('manufacturer') }">
                                    <has-error :form="form" field="manufacturer"></has-error>
                                </div>

                                <div class="form-group">
                                    <label>Model</label>
                                    <input v-model="form.model" type="text" name="model"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('model') }">
                                    <has-error :form="form" field="manufacturer"></has-error>
                                </div>
                                <div class="form-group">
                                    <label>Max_power</label>
                                    <input v-model="form.max_power" type="text" name="max_power"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('max_power') }">
                                    <has-error :form="form" field="email"></has-error>
                                </div>

                                <div class="form-group">
                                    <label>Min_power</label>
                                    <input v-model="form.min_power" type="text" name="min_power"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('min_power') }">
                                    <has-error :form="form" field="password"></has-error>
                                </div>

                                <div class="form-group">
                                    <label>Battery_capacity</label>
                                    <input v-model="form.battery_capacity" type="text" name="battery_capacity"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('battery_capacity') }">
                                    <has-error :form="form" field="battery_capacity"></has-error>
                                </div>

                                <div class="form-group">
                                    <label>Phase type</label>
                                    <select name="three_phase_mode" v-model="form.three_phase_mode" id="three_phase_select" class="form-control" :class="{ 'is-invalid': form.errors.has('three_phase_mode') }">
                                        <option value="">Select Phase</option>
                                        <option value="1">Three Phase</option>
                                        <option value="0">Single Phase</option>
                                    </select>
                                    <has-error :form="form" field="type"></has-error>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                                <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>



<script>
export default {
    data () {
        return {
            editmode: false,
            EV_models : {},
            form: new Form({
                id: '',
                model: '',
                manufacturer: '',
                min_power: '',
                max_power: '',
                battery_capacity: '',
                three_phase_mode: ''
            })
        }
    },
    methods: {

        getResults(page = 1) {

            this.$Progress.start();

            axios.get('api/EV/models').then(({ data }) => (this.EV_models = data.data));

            this.$Progress.finish();
        },
        updateEVModel(){
            this.$Progress.start();
            // console.log('Editing data');
            this.form.put('api/EV/models/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.$Progress.finish();
                    //  Fire.$emit('AfterCreate');

                    this.loadEVModels();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

        },
        editModal(EV){
            this.editmode = true;
            this.form.reset();
            $('#addNew').modal('show');
            this.form.fill(EV);
        },
        newModal(){
            this.editmode = false;
            this.form.reset();
            $('#addNew').modal('show');
        },
        deleteEVModel(id){
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {

                // Send request to the server
                if (result.value) {
                    this.form.delete('api/EV/models/'+id).then(()=>{
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        );
                        // Fire.$emit('AfterCreate');
                        this.loadEVModels();
                    }).catch((data)=> {
                        Swal.fire("Failed!", data.message, "warning");
                    });
                }
            })
        },
        loadEVModels(){
            this.$Progress.start();

            if(this.$gate.isAdmin()){
                //console.log(this.EVs);
                axios.get("/api/EV/models").then(({ data }) => (this.EV_models = data.data));

            }

            this.$Progress.finish();
        },

        createEVModel(){

            this.form.post('api/EV/models')
                .then((response)=>{
                    $('#addNew').modal('hide');

                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });

                    this.$Progress.finish();
                    this.loadEVModels();

                })
                .catch(()=>{

                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
        }

    },
    mounted() {
        console.log('EV models Component mounted.')
    },
    created() {

        this.$Progress.start();
        this.loadEVModels();
        this.$Progress.finish();
    }
}
</script>

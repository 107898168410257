<template>
    <div>

        <div class="card-body table-responsive p-0">
            <div>
                <chart :series="ChartSeries"></chart>
            </div>


            <table class="table table-borderless m-b-none">
                <div class="row-md-6">

                    <tbody>

                    <tr v-bind:style="{ 'background-color': '#c7ddef'}">
                        <!--  <form ref="form_addstep" @submit="submitForm"> -->

                        <td>
                            <div class="col">
                                <label>Value (Kw)</label>
                                <input name="stepInputKW" v-model="StepInputKW" class="form-control"
                                       required type="number" step="any" ></input>
                            </div>
                        </td>

                        <td>
                            <div class="col">
                                <label>Duration (min)</label>
                                <input name="stepInputDuration" v-model="StepInputDuration"
                                       required type="number" class="form-control"
                                       :step="stepping"></input>
                            </div>
                        </td>

                        <td>
                            <div class="col">
                                <md-button class="md-icon-button md-raised md-accent" @click.native="addStep()">
                                    <i class="fas fa-plus" v-bind:style="{ 'color': 'white'}"></i>
                                </md-button>
                            </div>
                        </td>

                        <!-- </form> -->
                    </tr>


                    </tbody>


                </div>

                <div class="row-md-6">

                    <tbody>

                    <tr v-bind:style="{ 'background-color': '#c7ddef'}">
                        <!-- <form ref="form_addstep_csv" @submit="submitFormCSV"> -->

                        <td>
                            <div class="col">
                                <label>Comma separated value (Kw)</label>
                                <input name="csvStepInputKW" v-model="CSVStepInputKW" class="form-control"
                                       required type="text" ></input>
                            </div>
                        </td>

                        <td>
                            <div class="col">
                                <label>Duration (min)</label>
                                <input name="csvStepInputDuration" v-model="CSVStepInputDuration"
                                       required type="number" class="form-control"
                                       :step="stepping"></input>
                            </div>
                        </td>

                        <td>
                            <div class="col">
                                <md-button class="md-icon-button md-raised md-accent" @click.native="addStepCSV()">
                                    <i class="fas fa-plus" v-bind:style="{ 'color': 'white'}"></i>
                                </md-button>
                            </div>
                        </td>

                        <!-- </form> -->
                    </tr>
                    </tbody>
                </div>
            </table>

            <table class="table table-borderless m-b-none col-md-6 mt-4">
                <div class="row-md-6 no-padding">
                    <tbody>
                    <tr v-for="(step, index) in steps">
                        <td>
                            <div class="input-group">
                                <input required v-model="steps[index].kw"  type="number" class="form-control"></input>
                            </div>
                        </td>

                        <td>
                            <div class="input-group">
                                <input required v-model="steps[index].min"  type="number" :step="stepping" class="form-control"></input>
                            </div>
                        </td>

                        <td>
                            <md-button class="md-icon-button md-primary" @click.native="removeStep(index)">
                                <i class="fas fa-trash" v-bind:style="{ 'color': 'red'}"></i>
                            </md-button>
                        </td>
                    </tr>
                    </tbody>
                </div>

                <div class="col-md-1">
                    <md-button class="md-button md-raised md-accent" @click.native="clearSteps()" v-if="this.steps.length > 0">
                        <i class="fas fa-trash" v-bind:style="{ 'color': 'white'}"></i>
                        <span>clear all</span>
                    </md-button>
                </div>

            </table>

        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    var chart_utils = require('../../utils/chart');
    var curve_utils = require('../../utils/curve');
    var config = require('../../config');

    export default {
        props: {
            startTime:{
                type: String,
                required: false
            },
            stepping:{
                required: false,
                default: 1
            },
            steps: {
                type: Array,
                required: false,
                default: function () { return [] }
            },
            onChange: {
                required: false,
                default: null
            }
        },
        data() {
            return {
                StepInputKW: 0,
                StepInputDuration: 0,
                CSVStepInputKW: 0,
                CSVStepInputDuration: 0,
                ChartSeries: [],
                }
         },
         watch: {
            'startTime': {
                handler (newData, oldData) {
                     this.updateSeries();
                 }
            },
            'steps': {
                handler (newData, oldData) {
                     this.updateSeries();
                 },
                 deep: true
            }
         },
         methods: {
            submitForm(e) {
                e.preventDefault();
                this.addStep();
            },
             submitFormCSV(e) {
                 e.preventDefault();
                 this.addStepCSV();
             },
            addStep(){
                this.steps.push({ kw: this.StepInputKW,  min: this.StepInputDuration});
                this.StepInputKW = null;
                this.StepInputDuration= null;
                //this.$refs.form_addstep.reset();
            },
            addStepCSV(){
                let csvSteps = this.CSVStepInputKW.split(',');
                for (var i = 0; i < csvSteps.length; i++){
                    this.steps.push({ kw: csvSteps[i].trim(),  min: this.CSVStepInputDuration});
                }
                 this.CSVStepInputKW = null;
                 this.StepInputDuration= null;
                 //this.$refs.form_addstep_csv.reset();
             },
            removeStep(i){
                this.steps.splice(i, 1);
            },
            clearSteps(){
             this.steps.splice(0, this.steps.length);
            },
            updateSeries(){
                            var offset = 0;
                            var tmp_series = [];
                            var tmp_data = [];

                            var start = moment(this.startTime).unix();
                            for (var i = 0; i < this.steps.length; i++){
                                var step_min = parseInt(this.steps[i].min) || 0;
                                var step_kw = parseFloat(this.steps[i].kw) || 0;

                                var slots = Math.floor(step_min / config.SLOT_SAMPLE);
                                for (var j = 0; j < slots; j++) {
                                        tmp_data.push({ timestamp: (start + offset), power: step_kw });
                                        offset += config.SLOT_SAMPLE * config.MINUTE;

                                        if (i == this.steps.length - 1 && j == slots - 1)
                                            tmp_data.push({ timestamp: (start + offset), power: step_kw});
                                }
                            }
                            var loadCurve = { LoadCurve: tmp_data};
                            this.ChartSeries = [ chart_utils.line('PowerThreshold', curve_utils.curveToSerie(loadCurve), {color:config.COLORS.CURVES[0]})];
                            this.onChange(loadCurve);

            }
         },
        mounted() {
            console.log('CurveBuilder ready.');
        }
    }


</script>

<template>
        <div class="form-group mt-2">
            <div class="pl-3 pt-lg-1">
            <select class="form-control center-block pl-6" v-model="ApplicationID" name="ApplicationID" id="ApplicationID"
                        v-bind:disabled="running || scheduling">
                <option v-for="(application, index) in applications" v-bind:value="application.app_id" style="background-color: #5cd08d">{{
                    application.description }}
                </option>
            </select>
            </div>
        </div>
</template>

<script>
    export default {
        mounted() {
            console.log('ApplicationSelect ready.');
            this.getApplications();
        },
        data() {
            return {
                ApplicationID: 0,
                applications: []
                }
        },
        watch: {
        'ApplicationID': {
            handler (newData, oldData) {
                 if (oldData !== 0 && newData !== oldData) {
                   this.set();
                }
                }
            }
        },
        computed: {
            running: function () {
                return this.$store.state.simulation.running;
            },
            scheduling: function () {
                return this.$store.state.simulation.scheduling;
            }
        },
        methods: {
            getApplications() {
                axios.get('api/Application')
                        .then(response => {
                            this.applications = response.data.apps;
                            this.ApplicationID = response.data.current;
                        });
            },
            set() {
                axios.post('api/Application/set', { app_id: this.ApplicationID } )
                        .then(response => {
                            this.ApplicationID = response.data.current;
                            EventBus.$emit("applicationchanged");
                        });
            }
        }
    }
</script>

var config = require('../config');

module.exports = {
    roundToMinute: function (timestamp) {
        var result = Math.floor(timestamp / (config.MINUTE));
        return result * config.MINUTE;
    },

    aggregate: function (curves) {

        var aggregated = [];

        for (var x = 0; x < curves.length; x++) {
            if (curves[x] !== null && curves[x] !== undefined) {
                for (var i = 0; i < curves[x].length; i++) {

                    var currentTimestamp = this.roundToMinute(curves[x][i].timestamp);

                    if (aggregated[currentTimestamp] !== undefined) {
                        aggregated[currentTimestamp] += curves[x][i].power;
                    } else {
                        aggregated[currentTimestamp] = curves[x][i].power;
                    }
                }
            }

        }
        return aggregated;
    },
    merge: function (curves) {

        var aggregated = [];

        for (var x = 0; x < curves.length; x++) {
            if (curves[x] !== null && curves[x] !== undefined) {
                for (var i = 0; i < curves[x].length; i++) {
                    var currentTimestamp = this.roundToMinute(curves[x][i].timestamp);
                    aggregated[currentTimestamp] = curves[x][i].power;
                }
            }

        }
        return aggregated;
    },
    energyCurveToSerie: function (loadcurve) {

        if (loadcurve == null)
            return [];

        var curve = loadcurve.EnergyCurve;
        var processed = [];
        var i;
        for (i = 0; i < curve.length; i++) {
            processed.push([this.roundToMinute(curve[i].timestamp) * 1000, curve[i].energy]);
        }
        return processed;
    },

    getAvg: function (list) {
        const total = list.reduce((acc, c) => acc + c, 0);
        return total / list.length;
    },


    curveToSerie: function (loadcurve) {

        if (loadcurve == null)
            return [];

        var curve = loadcurve.LoadCurve;
        var processed = [];
        var i;


        var dict = {};



        for (i = 0; i < curve.length; i++) {
            //console.log(dict)

            // se il timestamp arrotondato al minuto è stato già salvato, pusho il power relativo a quel minuto
            if (this.roundToMinute(curve[i].timestamp) in dict)
            {
                power_list.push(curve[i].power)
                dict[this.roundToMinute(curve[i].timestamp)] = power_list
            }
            //
            // altrimenti creo una nuova chiave per un nuovo timestamp arrotondato al minuto
            else {
                var power_list = []
                power_list.push(curve[i].power)
                dict[this.roundToMinute(curve[i].timestamp)] = power_list
                // se ho creato una nuova chiave, calcolo la media dei power relativi al minuto precedente.
                if (i!==0)
                {
                    if (Array.isArray(dict[this.roundToMinute(curve[i-1].timestamp)]))
                    {
                        dict[this.roundToMinute(curve[i-1].timestamp)] = this.getAvg(dict[this.roundToMinute(curve[i-1].timestamp)])
                        processed.push([this.roundToMinute(curve[i-1].timestamp) * 1000, dict[this.roundToMinute(curve[i-1].timestamp)]])
                    }
                }
            }
            // calcolo la media dell'ultima chiave del dict
            if (i === curve.length - 1) {
                dict[this.roundToMinute(curve[i].timestamp)] = this.getAvg(dict[this.roundToMinute(curve[i].timestamp)])
                processed.push([this.roundToMinute(curve[i].timestamp) * 1000, dict[this.roundToMinute(curve[i-1].timestamp)]])
            }
        }


        /*
        for (i = 0; i < curve.length; i++) {
            processed.push([this.roundToMinute(curve[i].timestamp) * 1000, curve[i].power]);
        }
        */



        return processed;
    },
    curveToSteps: function (loadcurve) {

        if (loadcurve == null)
            return [];

        var curve = loadcurve.LoadCurve;
        var processed = [];
        var i;
        var ts_ini = -1;
        var kw_ini = -1;

         if (curve.length > 0) {
            ts_ini = curve[0].timestamp;
            kw_ini = curve[0].power;


            for (i = 0; i < curve.length - 1; i++) {
                var point = curve[i];
                var next_point = curve[i + 1];

                if (point.power != next_point.power) {
                    processed.push({kw: kw_ini, min: Math.floor((next_point.timestamp - ts_ini) / 60)});
                    ts_ini = next_point.timestamp;
                    kw_ini = next_point.power;
                }
            }
            if (curve.length > 1)
                processed.push({kw: kw_ini, min: Math.floor((next_point.timestamp - ts_ini) / 60)});

        }

        return processed;
    },
    arrayToSerie: function (curve) {

        if (curve == null)
            return {};

        var processed = [];
        for (var timestamp in curve) {
            processed.push([parseInt(timestamp) * 1000, curve[timestamp]]);
        }
        return processed;
    }

}

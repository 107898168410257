<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row-12">
                <div class="card" style="margin-top:10px">
                    <div class="card-header">
                        <h3 class="card-title">Charge Points - Filter</h3>
                        <div class="subhead"></div>
                    </div>
                    <div class="card-body">
                        <div class="col">
                            <label for="dropdown1">Menu A</label>
                            <select v-model="selectedItemA" @change="fetchItemData('A')">
                                <option v-for="item in itemsA" :value="item.id" :key="item.id">{{ item.name }}</option>
                            </select>
                        </div>
                        <div>
                            <label for="dropdown2">Menu B</label>
                            <select v-model="selectedItemB" @change="fetchItemData('B')">
                                <option v-for="item in itemsB" :value="item.id" :key="item.id">{{ item.name }}</option>
                            </select>
                        </div>
                        <div>
                            <label for="dropdown3">Menu C</label>
                            <select v-model="selectedItemC" @change="fetchItemData('C')">
                                <option v-for="item in itemsC" :value="item.id" :key="item.id">{{ item.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row-12">
                    <div class="card" v-if="$gate.isAdmin()">
                        <div class="card-header">
                            <h3 class="card-title">Charging Station - List</h3>
                            <div class="card-tools">
                                <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                                    <i class="fa fa-plus-square"></i>
                                    Add New
                                </button>
                            </div>
                        </div>
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th>id</th>
                                    <th>ocpp_version</th>
                                    <th>LoadAreaID</th>
                                    <th>ocpp_charge_point_id</th>
                                    <th>label</th>
                                    <th>actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="item in selectedItems" :key="item.id">
                                    <td>{{ item.id }}</td>
                                    <td>{{ item.ocpp_version }}</td>
                                    <td>{{ item.LoadAreaID }}</td>
                                    <td>{{ item.ocpp_charge_point_id }}</td>
                                    <td>{{ item.label }}</td>
                                    <td>
                                        <a href="#" @click="editModal(item)">
                                            <i class="fa fa-edit blue"></i>
                                        </a>/
                                        <a href="#" @click="deleteConnector(item.id)">
                                            <i class="fa fa-trash red"></i>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            selectedItemA: null,
            selectedItemB: null,
            selectedItemC: null,
            itemsA: [],
            itemsB: [],
            itemsC: [],
            selectedItems: []
        };
    },
    mounted() {
        // Fetch dropdown data on component mount
        this.fetchDropdownData('A');
        this.fetchDropdownData('B');
        this.fetchDropdownData('C');
        // Fetch initial charge point data
        this.fetchItemData();
    },
    methods: {
        fetchDropdownData(menu) {
            // Simulated API call to fetch dropdown data
            // Replace with your actual API call to fetch dropdown data
            // Example: axios.get(`/api/dropdown/${menu}`)
            //   .then(response => {
            //     this[`items${menu}`] = response.data;
            //   })
            //   .catch(error => {
            //     console.error(`Error fetching ${menu} dropdown data:`, error);
            //   });

            // Simulated data for demonstration
            // Replace with actual logic to fetch data
            if (menu === 'A') {
                this.itemsA = [{ id: 1, name: 'Item A1' }, { id: 2, name: 'Item A2' }];
            } else if (menu === 'B') {
                this.itemsB = [{ id: 1, name: 'Item B1' }, { id: 2, name: 'Item B2' }];
            } else if (menu === 'C') {
                this.itemsC = [{ id: 1, name: 'Item C1' }, { id: 2, name: 'Item C2' }];
            }
        },
        fetchItemData() {
            // Simulated API call to fetch charge point data
            // Replace with your actual API call to fetch charge point data
            axios.get('api/charge-point/')
                .then(response => {
                    this.selectedItems = response.data;
                    console.log('Fetched charge point data:', this.selectedItems);
                })
                .catch(error => {
                    console.error('Error fetching charge point data:', error);
                });
        },
        newModal() {
            // Placeholder method for opening new modal
            console.log('Opening new modal');
        },
        editModal(item) {
            // Placeholder method for opening edit modal
            console.log('Editing item:', item);
        },
        deleteConnector(id) {
            // Placeholder method for deleting connector
            console.log('Deleting connector with ID:', id);
        }
    },
    created() {
        // Correct placement of created hook outside methods
        this.fetchItemData();
    }
};
</script>

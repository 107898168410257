<template>


    <div>
            <h2 v-if="this.application=='Production'"> Dashboard </h2>
            <h2 v-if="this.application=='Testing'"> Simulation </h2>
        <md-card>

            <md-card-header>

                        <!--div class="pull-left md-title">{{ LoadAreaID }}</div-->
                        <load-area-select></load-area-select>

                        <div class="p-2 btn-group btn-group-toggle" data-toggle="buttons">
                            <label class="btn btn-secondary active">
                                <input type="radio" name="options" id="option1" autocomplete="off" @click="changeChartType('computed')" checked >Computed
                            </label>

                            <label class="btn btn-secondary">
                                <input type="radio" name="options" id="option2" autocomplete="off" @click="changeChartType('real')">Real
                            </label>

                            <label class="btn btn-secondary">
                                <input type="radio" name="options" id="option3" autocomplete="off" @click="changeChartType('comparison')">Comparison
                            </label>

                            <label class="btn btn-secondary" v-show="ChartType.view != 'loadarea'">
                                <input type="radio" name="options" id="option4" autocomplete="off" @click="changeChartType('evolution')"
                                       >Evolution
                            </label>
                        </div>

                        <md-button class="mt-2" @click.native="refresh" ><i class="fas fa-sync-alt fa-lg" v-bind:style="{ 'color': 'black'}"></i></md-button>

                <div class="float-right">
                    <div class="container-fluid">
                        <div class="row">

                            <div v-show="scheduling"  class="spinner-border text-warning"  role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div v-show="running"  class="spinner-border text-success"  role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <label v-show="scheduling" v-bind:style="{ 'color': '#ffbf00'}">Scheduling ongoing: {{class_name}} </label>
                            <label style="padding: 5px" v-show="running" v-bind:style="{ 'color': 'green'}">Simulation: {{class_name}} </label>
                            <label v-show="!running && !scheduling" v-bind:style="{ 'color': 'red'}"> {{class_name}} not running </label>
                        </div>
                        <div>
                            <countdown v-show="running && !computing && !simulation" v-bind:style="{ 'color': 'green'}"></countdown>
                            <span v-show="running && computing" v-bind:style="{ 'color': 'green'}">Algorithmic Computation ongoing</span>
                        </div>
                    </div>
                </div>


            </md-card-header>

            <md-card-content>

                <chart :series="ChartSeries" :ytitle="ChartLabel" :timestamp="ChartTimestamp" class="chart"></chart>
                <md-bottom-bar ref="bottombar">
                    <md-bottom-bar-item md-icon="multiline_chart" md-active @click.native="changeChartView('loadarea')">All
                    </md-bottom-bar-item>
                    <md-bottom-bar-item md-icon="show_chart" v-for="(session,index) in ChargingSessions" v-bind:key="session.id"
                                        @click.native="changeChartView(session.SmartChargingSessionID)">Session {{
                        session.SmartChargingSessionID }}
                    </md-bottom-bar-item>
                </md-bottom-bar>

            </md-card-content>
        </md-card>

        <md-card v-if="this.application == 'Production'">

            <md-card-content>
                <div class="row">
                    <div class="col-1"></div>
                    <div class="md-card col-4">
                        <label> Load Area Settings</label>
                        <div>
                                <md-button class="md-button md-raised md-accent btn-lg btn-block" @click="showDialog_LA_Target = true">
                                    <span>Set Curves</span>
                                </md-button>
                        </div>
                    </div>

                    <div class="col-2">
                    </div>

                    <div class="md-card col-4">
                        <label> Download csv file</label>
                        <div style="position: center; padding-bottom: 3px">
                                <md-button class="md-button md-raised md-accent btn-lg btn-block align-content-center" @click="downloadResults()">
                                    <span>Download Results</span>
                                </md-button>
                            <select  class="form-control col-12 align-content-center" v-model="Sessions_data" name="SmartChargingSessionID" id="SmartChargingSessionID" @change="select_Session">
                                <option v-for="(session, index) in sessions" v-bind:value="session">
                                    {{session.SmartChargingSessionID}}
                                </option>
                            </select>
                        </div>

                        <div class="md-card row-4">
                            <label> Download logs file</label>
                            <div style="position: center; padding-bottom: 3px">
                                <md-button class="md-button md-raised md-accent btn-lg btn-block align-content-center" @click="downloadLogs()">
                                    <span>Download Logs</span>
                                </md-button>
                                <select  class="form-control col-12 align-content-center" v-model="Sessions_data" name="SmartChargingSessionID" id="SmartChargingSessionID" @change="select_Session">
                                    <option v-for="(session, index) in sessions" v-bind:value="session">
                                        {{session.SmartChargingSessionID}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="col-1"></div>

                </div>
            </md-card-content>
        </md-card>

        <md-card v-if="this.application == 'Testing'">
            <md-card-header>
                <div class="pull-left md-title">Load Area Tools</div>
            </md-card-header>

            <md-card-content>
                <div class="row">
                    <div class="col-3"><label> Load Area Settings</label></div>
                    <div class="col-6"><label> Simulation</label></div>
                    <div class="col-3"><label> Scenarios</label></div>
                </div>
            <div class="row align-items-end" style="position: relative">

              <div class="col-md-3 card" style="position: relative">
                <div class="row-md-2 mt-4">
                    <md-button class="md-button md-raised md-accent btn-lg btn-block" @click="showDialog_LA_Target = true">
                        <span>Set Curves</span>
                    </md-button>
                </div>

                <div class="row-md-2 mt-3">
                    <md-button class="md-button md-raised md-accent btn-lg btn-block" @click.native="LoadCurves(1, 0)" v-if="$gate.isAdmin()">
                        <span>Load Curves</span>
                    </md-button>
                </div>

                  <div class="row-md-2 mt-3">
                      <md-button class="md-button md-raised md-accent btn-lg btn-block" @click="showDialog = true">
                          <span>Add Session</span>
                      </md-button>
                  </div>
              </div>
                <div class="col-md-6" style="position: relative; bottom: 0px;" >

                        <div></div>
                        <simulation-panel :interval="5"></simulation-panel>

                </div>
                <div class="col-md-3 card" style="position: relative; bottom: 0">
                    <div class="row-md-2 mt-4">
                        <label> Scenario 1</label>
                        <div></div>
                        <md-button class="md-button md-raised md-accent btn-lg btn-block" @click.native="LoadCurves(1.5, 0)" v-if="$gate.isAdmin()">
                            <span>Best Case Scenario</span>
                        </md-button>
                    </div>

                    <div class="row-md-2 mt-3">
                        <label>Scenario 2</label>
                        <div></div>
                        <md-button class="md-button md-raised md-accent btn-lg btn-block" @click.native="LoadCurves(0.5, 1)" v-if="$gate.isAdmin()">
                            <span>Worst Case Scenario</span>
                        </md-button>
                    </div>
                </div>
            </div>
            </md-card-content>

        </md-card>

        <md-card v-if="this.application == '5G-Solutions'">
            <md-card-header>
                <div class="pull-left md-title">Load Area Tools</div>
            </md-card-header>

            <md-card-content>
                <div class="row">
                    <div class="col-3"><label> Load Area Settings</label></div>
                    <div class="col-6"><label> Simulation</label></div>
                    <div class="col-3"><label> Scenarios</label></div>
                </div>
                <div class="row align-items-end" style="position: relative">

                    <div class="col-md-3 card" style="position: relative">
                        <div class="row-md-2 mt-4">
                            <md-button class="md-button md-raised md-accent btn-lg btn-block" @click="showDialog_LA_Target = true">
                                <span>Set Curves</span>
                            </md-button>
                        </div>

                        <div class="row-md-2 mt-3">
                            <md-button class="md-button md-raised md-accent btn-lg btn-block" @click.native="LoadCurves(1, 0)" v-if="$gate.isAdmin()">
                                <span>Load Curves</span>
                            </md-button>
                        </div>

                        <div class="row-md-2 mt-3">
                            <md-button class="md-button md-raised md-accent btn-lg btn-block" @click="showDialog = true" v-if="$gate.isAdmin()">
                                <span>Add Session</span>
                            </md-button>
                        </div>
                    </div>
                    <div class="col-md-6" style="position: relative; bottom: 0px;" >

                        <div></div>
                        <simulation-panel-evos :interval="5"></simulation-panel-evos>

                    </div>
                    <div class="col-md-3 card" style="position: relative; bottom: 0">
                        <div class="row-md-2 mt-4">
                            <label> Scenario 1</label>
                            <div></div>
                            <md-button class="md-button md-raised md-accent btn-lg btn-block" @click.native="LoadCurves(1.5, 0)" v-if="$gate.isAdmin()">
                                <span>Best Case Scenario</span>
                            </md-button>
                        </div>

                        <div class="row-md-2 mt-3">
                            <label>Scenario 2</label>
                            <div></div>
                            <md-button class="md-button md-raised md-accent btn-lg btn-block" @click.native="LoadCurves(0.5, 1)" v-if="$gate.isAdmin()">
                                <span>Worst Case Scenario</span>
                            </md-button>
                        </div>
                    </div>
                </div>
            </md-card-content>

        </md-card>

        <md-card>
            <md-card-header>
                <div class="pull-left md-title">Sessions</div>
            </md-card-header>

            <md-card-content>
                <p class="m-b-none" v-if="ChargingSessions.length === 0">
                    No charging session
                </p>

                <table class="table table-hover m-b-none" v-if="ChargingSessions.length > 0">
                    <thead>
                    <tr>
                        <th></th>
                        <th>Session ID</th>
                        <th>Start of Charging</th>
                        <th>Departure Time</th>
                        <th>Initial SOC</th>
                        <th>Current SOC</th>
                        <th>Desired SOC</th>
                        <th>Completion of Charging Session</th>
                        <th>Session Status</th>
                        <th>Connector Status</th>
                        <th>Session Final Info</th>

                        <!--th>Current Cost</th-->
                        <!--th>Final Cost</th-->
                        <th></th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr v-for="(session, index) in ChargingSessions">
                        <td>
                            <md-button class="md-icon-button md-raised mt-4"
                                       v-bind:style="{ 'background-color': Colors[index]}"
                                       @click.native="changeSessionView(index)">
                                <i class="fas fa-charging-station" v-bind:style="{ 'color': 'white'}"></i>
                            </md-button>
                        </td>

                        <td style="vertical-align: middle;">
                            {{ session.SmartChargingSessionID }}
                        </td>

                        <td style="vertical-align: middle;">
                            {{ session.UserChargingRequest.StartOfCharging | moment("DD/MM/YYYY HH:mm:ss") }}
                        </td>

                        <td style="vertical-align: middle;">
                            {{ session.UserChargingRequest.DesiredDepartureTime | moment("DD/MM/YYYY HH:mm:ss") }}
                        </td>

                        <td style="vertical-align: middle;">
                            {{ SOC(session.UserChargingRequest.InitialSOC,
                            session.SmartChargingSessionSettings.BatteryCapacity) }}
                        </td>

                        <td style="vertical-align: middle;">
                            {{ SOC(session.CurrentState,
                            session.SmartChargingSessionSettings.BatteryCapacity) }}
                        </td>

                        <td style="vertical-align: middle;">
                            {{ SOC(session.UserChargingRequest.DesiredEnergyLevel,
                            session.SmartChargingSessionSettings.BatteryCapacity) }}
                        </td>

                        <td style="vertical-align: middle;">
                            {{ session.CompletionChargingTime }}
                        </td>

                        <td style="vertical-align: middle;">
                            {{ session.SessionStatus }}
                        </td>

                        <td style="vertical-align: middle;">
                            {{ getPODStatus(session.SmartChargingSessionID) }}
                        </td>

                        <td style="vertical-align: middle;">
                            {{ parseFinalSessionData(session.AccomplishedSessionData) }}
                        </td>





                        <!--td style="vertical-align: middle;">
                            {{ session.CurrentCost.toFixed(2) }}
                        </td-->

                        <!--td style="vertical-align: middle;">
                            {{ session.ExpectedRechargeCost }}
                        </td-->

                        <td>
                            <div class="col">


                            <md-menu md-direction="top-start">
                                <md-button class="md-icon-button" md-menu-trigger>
                                    <i class="fas fa-info"></i>
                                </md-button>

                                <md-menu-content>
                                    <md-menu-item><i class="fas fa-battery-full"></i><small><span><b>{{ session.SmartChargingSessionSettings.BatteryCapacity }}kWh</b></span></small></md-menu-item>
                                    <md-menu-item><i class="fas fa-arrow-up"></i><small><span><b>{{ session.SmartChargingSessionSettings.MaximumPower }}kW</b></span></small></md-menu-item>
                                    <md-menu-item><i class="fas fa-arrow-down"></i><small><span><b>{{ session.SmartChargingSessionSettings.MinimumPower }}kW</b></span></small></md-menu-item>
                                    <md-menu-item><i class="fas fa-bolt"></i><small><b> {{  ChargingMode(session.SmartChargingSessionSettings.ACDCMode) }}</b></small></md-menu-item>

                                </md-menu-content>
                            </md-menu>



                            <md-button class="md-icon-button" v-if="(application == 'Testing' ||  application == '5G-Solutions')" @click.native="remoteStop(session.SmartChargingSessionID)"><i class="fas fa-stop"></i></md-button>



                            <md-button class="md-icon-button" v-if="(application == 'Testing' ||  application == '5G-Solutions')" @click.native="removeSession(session.SmartChargingSessionID)"><i class="fas fa-trash"></i></md-button>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </md-card-content>
        </md-card>

        <md-dialog ref="dialog_notification" :md-active.sync="showDialog">
            <md-dialog-title>Add Charging session</md-dialog-title>

            <md-dialog-content>
            <request-notification ref="request_notification"></request-notification>
            </md-dialog-content>

            <md-dialog-actions>
                <md-button class="md-primary" @click="closeDialog">Close</md-button>
            </md-dialog-actions>
        </md-dialog>

        <!--md-dialog ref="dialog_notification" :md-active.sync="showDialog_Simulation">
            <h2 v-if="this.application=='Production'"> Production </h2>
            <simulation-panel :interval="5"></simulation-panel>
        </md-dialog-->



        <!--md-dialog ref="dialog_notification" :md-active.sync="showDialog_Simulation">
            <h2 v-if="this.application=='Testing'"> Simulation </h2>
            <simulation-panel-evos :interval="5"></simulation-panel-evos>
        </md-dialog-->

        <md-dialog ref="dialog_notification" :md-active.sync="showDialog_LA_Target">
            <md-dialog-content>
            <lm-target></lm-target>
            </md-dialog-content>
        </md-dialog>
    </div>


</template>

<script>
    var chart_utils = require('../../utils/chart');
    var curve_utils = require('../../utils/curve');
    var helper_utils = require('../../utils/helper');
    var config = require('../../config');

    export default {

        props: {
            loadareadata:{
                type: Object,
                required: false,
                 default: function () { return {} }
            },
            timestamp: {
                type: Number,
                required: false
            },
            application:  String,

            iteration: {
                type: Number,
                required: false
            }
        },

        watch: {
        'timestamp': {
        handler (newData, oldData) {
                if (newData !== oldData) {
                //console.log('timestamp_changed_detected for ChartTimestamp')
                this.ChartTimestamp = newData;
                }
                this.getSessions()
                }

        },
        'loadareadata': {
            handler (newData, oldData) {
                if (newData !== oldData) {
                this.ChargingSessions = newData.ChargingSessions;
                this.PowerThresholdCurve = newData.PowerThresholdCurve;
                this.MinPowerThresholdCurve = newData.MinPowerThresholdCurve;
                this.TargetLoadCurve = newData.TargetLoadCurve;
                this.LoadAreaID = newData.LoadAreaID;
                this.class_name = this.$store.state.loadArea.class_name;
                this.PodData = newData.PodData;

                console.log("Bew session pod data: "+JSON.stringify(this.PodData))

                if (this.checkForValidSessionView()) { this.updateChart(); }
                else { // RESET VIEW IF SESSION DOESN'T EXIST
                    this.changeChartView('loadarea');
                    this.changeSessionView(-1);}
                }
                }
        }, deep: true},

        ready() {

        },
        /**
         * Prepare the component (Vue 2.x).
         */
        mounted() {
            this.getSessions()
            console.log(this.application)
            console.log('Application Loaded')
        },
        data() {
            return {
                class_name: "",
                LoadAreaID: "",
                ChargingSessions: [],
                TargetLoadCurve: [],
                PowerThresholdCurve: [],
                MinPowerThresholdCurve: [],
                ChartSeries: [],
                ChartLabel: "",
                ChartTimestamp: 0,
                ChartType: { view: 'loadarea', type: 'computed' },
                Colors : config.COLORS.SESSIONS,
                _Timer : null,
                showDialog: false,
                showDialog_Simulation: false,
                showDialog_LA_Target: false,
                sessions: {},
                sessionID: '',
                Sessions_data: {},
                csv: '',
                }
         },
         computed:{
             running: function () {
                 return this.$store.state.simulation.running;
             },
             scheduling: function () {
                 return this.$store.state.simulation.scheduling;
             },
             computing: function () {
                 return this.$store.state.simulation.computing;
             },
             simulation: function () {
                 return this.$store.state.simulation.fast;
             }
         },
         methods: {
            ChargingMode(mode){
                if (mode == 0)
                {
                    return 'AC Single-Phase'
                }
                else if (mode == 1)
                {
                    return 'AC Three-Phase'
                }
                else {
                    return 'DC Charging'
                }
            },

            openDialog(){
                this.$refs['dialog_notification'].open();
            },
             closeDialog(){
                 //this.$refs['dialog_notification'].close();
                 this.showDialog = false;
                 this.refresh();
             },
             onOpenDialog(){
                 this.$refs['request_notification'].reset();
             },
             onCloseDialog(){
                 this.refresh();
             },
            formatDate(date) {
                return moment(date).format("ll")
            },
            toggleIfActive: function (type) {
                return ( this.ChartType.type === type ? true:false )
            },
            changeSessionView(i){
                this.$refs.bottombar.$children[i+1].setActive(true);
            },
            SOC(kw, capacity) {
                var result = "";
                result += helper_utils.kw2soc(kw, capacity) + "%";
                result += " (" + kw + " kWh)";
                return result;
            },
            getPODStatus(session_id){
                let sessin_to_pod = this.PodData;

                console.log("POD data: "+ JSON.stringify(this.PodData) + "for session id "+JSON.stringify(session_id))

                let pod_data = sessin_to_pod[session_id]

                if(pod_data === undefined){
                    return "POD status Not Available"
                }else{
                    return pod_data.data.OCPPStatus
                }

            },
            parseFinalSessionData(final_session_data){

                if(final_session_data === null){
                    return ""
                }else{
                    final_session_data = JSON.parse(final_session_data)


                    var result_string = "" +
                        `MeterInitialEnergy = ${final_session_data.MeterSessionInitialEnergy.value} ${final_session_data.MeterSessionInitialEnergy.unit_of_measure}, \n
                    MeterFinalEnergy = ${final_session_data.MeterSessionFinalEnergy.value} ${final_session_data.MeterSessionFinalEnergy.unit_of_measure}, \n
                    EndTime (UTC) = ${final_session_data.TimeOfComplete}, \n
                    Reason of Session End = ${final_session_data.CompleteReasonDescription}`

                    return result_string
                }



            },
            checkForValidSessionView(){
                 if (this.ChartType.view === 'loadarea') return true;
                 for (var i = 0; i < this.ChargingSessions.length; i++){
                   if (this.ChartType.view == this.ChargingSessions[i].SmartChargingSessionID)   return true;
                }
                return false;
            },
            changeChartView(s) {
                if (this.ChartType.view != s) {
                this.ChartType.view = s;
                if (this.ChartType.view === 'loadarea' && this.ChartType.type === 'evolution')
                    this.changeChartType('computed');
                else
                    this.updateChart();
                }
            },
            changeChartType(t) {
                if (this.ChartType.type != t) {
                this.ChartType.type = t;
                this.updateChart();
                }
            },
            refresh(){
                EventBus.$emit("timechanged");
                this.$store.state.loadArea.start = 0;
                //console.log("OK");
            },
            remoteStop(sessionId){
                Swal.fire({
                    title: "Remote Stop",
                    text: "You'll stop the charging session.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#f44336",
                    confirmButtonText: "Continue",
                    closeOnConfirm: false,
                    showLoaderOnConfirm: true,
                }).then((result) =>
                {
                    if(result.value) {
                        axios.post('api/CPO/remoteStop', {SmartChargingSessionID: sessionId})
                            .then((response) => {
                                helper_utils.alert(response.data);
                                // success callback
                            }, (response) => {
                                // error callback
                                helper_utils.alert(response.data);
                            });
                    }
                })
            },
             removeSession(sessionId){
                     Swal.fire({
                         title: "Remove session",
                         text: "You'll remove the charging session.",
                         type: "warning",
                         showCancelButton: true,
                         confirmButtonColor: "#f44336",
                         confirmButtonText: "Continue",
                         closeOnConfirm: false,
                         showLoaderOnConfirm: true,
                     }).then((result) =>
                     {
                         if (result.value) {

                             axios.delete('api/SCS/' + sessionId)
                                 .then((response) => {
                                     this.refresh();
                                     Swal.fire(
                                         'Deleted!',
                                         'Your file has been deleted.',
                                         'success'
                                     );
                                     // success callback
                                 }, (response) => {
                                     // error callback
                                     helper_utils.alert(response.data);
                                 });
                         }
                     })
                 },
            updateChart(){
                            var tmp_series = [];

                            if (this.ChartType.type !== 'evolution'){
                                tmp_series.push(chart_utils.line('PowerThreshold', curve_utils.curveToSerie(this.PowerThresholdCurve), {color:config.COLORS.CURVES[0]}));
                                tmp_series.push(chart_utils.line('TargetLoadCurve', curve_utils.curveToSerie(this.TargetLoadCurve), {color:config.COLORS.CURVES[1]}));
                                if(this.$store.state.loadArea.class_name == 'MCAAlgorithm') {
                                    tmp_series.push(chart_utils.line('PowerThreshold V2G', curve_utils.curveToSerie(this.MinPowerThresholdCurve), {color: config.COLORS.CURVES[2]}));
                                }
                                this.ChartLabel = "Power (kW)";
                            } else {
                                this.ChartLabel = "Energy (kWh)";
                            }

                            //this.ChartTimestamp = moment().unix();

                            var i;
                                    switch (this.ChartType.type){
                                            case 'evolution':
                                                for (i = 0; i < this.ChargingSessions.length; i++){
                                                    if (this.ChartType.view == this.ChargingSessions[i].SmartChargingSessionID)
                                                        tmp_series.push(chart_utils.area(this.ChargingSessions[i].SmartChargingSessionID, curve_utils.energyCurveToSerie(this.ChargingSessions[i].EstimatedStateCurve), {color:config.COLORS.SESSIONS[i], pointPlacement: +0.5}));
                                                }
                                                break;
                                            case 'computed':
                                                for (i = 0; i < this.ChargingSessions.length; i++){
                                                    if (this.ChartType.view == 'loadarea' ||  this.ChartType.view == this.ChargingSessions[i].SmartChargingSessionID)
                                                        tmp_series.push(chart_utils.column(this.ChargingSessions[i].SmartChargingSessionID, curve_utils.curveToSerie(this.ChargingSessions[i].ComputedLoadCurve), {color:config.COLORS.SESSIONS[i], pointPlacement: +0.5}));
                                                }
                                                break;
                                            case 'real':
                                                for (i = 0; i < this.ChargingSessions.length; i++){
                                                    if (this.ChartType.view == 'loadarea' ||  this.ChartType.view == this.ChargingSessions[i].SmartChargingSessionID)
                                                        tmp_series.push(chart_utils.column(this.ChargingSessions[i].SmartChargingSessionID, curve_utils.curveToSerie(this.ChargingSessions[i].RealLoadCurve), {pointPadding: 0.1, color:config.COLORS.SESSIONS[i], pointPlacement: +0.5}));
                                                }
                                                break;
                                            case 'comparison':
                                                var real_to_aggregate = [];
                                                var computed_to_aggregate = [];
                                                for (i = 0; i < this.ChargingSessions.length; i++){
                                                if (this.ChargingSessions[i].ComputedLoadCurve != null && this.ChartType.view == 'loadarea') {
                                                        computed_to_aggregate.push(this.ChargingSessions[i].ComputedLoadCurve.LoadCurve);
                                                    }
                                                else if (this.ChartType.view == this.ChargingSessions[i].SmartChargingSessionID) {
                                                        tmp_series.push(chart_utils.area('Computed', curve_utils.curveToSerie(this.ChargingSessions[i].ComputedLoadCurve), {color:config.COLORS.SESSIONS[i]}));
                                                    }
                                                if (this.ChargingSessions[i].RealLoadCurve != null && this.ChartType.view == 'loadarea') {
                                                        real_to_aggregate.push(this.ChargingSessions[i].RealLoadCurve.LoadCurve );
                                                    }
                                                else if (this.ChartType.view == this.ChargingSessions[i].SmartChargingSessionID){
                                                        tmp_series.push(chart_utils.column("Real", curve_utils.curveToSerie(this.ChargingSessions[i].RealLoadCurve), {pointPadding: 1, color:config.COLORS.SESSIONS[i], pointPlacement: 0}));
                                                    }
                                                }
                                                if (this.ChartType.view == 'loadarea') {
                                                    tmp_series.push(chart_utils.area('Computed (Aggregated)', curve_utils.arrayToSerie(curve_utils.aggregate(computed_to_aggregate)), {color: config.COLORS.CURVES[0], pointPlacement: +0.5}));
                                                    tmp_series.push(chart_utils.column('Real (Aggregated)', curve_utils.arrayToSerie(curve_utils.aggregate(real_to_aggregate)), {pointPadding: 1, color: config.COLORS.CURVES[0], pointPlacement: 0}));
                                                }
                                                break;
                                    }
                            this.ChartSeries = tmp_series;
            },

             downloadResults() {

                axios.get('api/LA/getResults/' + this.sessionID.replace(/\//g,"-")).then(
                    (response) => {
                        this.csv = response.data
                        //console.log(this.csv)
                        var blob = new Blob([this.csv]);
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = this.sessionID.replace(/\//g,"-") + '.csv';
                        link.click();
                    }
                )
             },
             downloadLogs() {

                 axios.get('api/LA/getLogs/' + this.sessionID.replace(/\//g,"-")).then(
                     (response) => {
                         this.csv = response.data
                         //console.log(this.csv)
                         var blob = new Blob([this.csv]);
                         var link = document.createElement('a');
                         link.href = window.URL.createObjectURL(blob);
                         link.download = this.sessionID.replace(/\//g,"-")+ "_Logs" + '.txt';
                         link.click();
                     }
                 )
             },

             select_Session() {
                this.sessionID = this.Sessions_data.SmartChargingSessionID
             },

             getSessions() {
                axios.get('api/LA/getSessions', { params: {timeframe: this.$store.state.timeframe}}).then((response) => {

                    this.sessions = response.data;
                    this.Sessions_data = this.sessions[0]
                    if (response.data.length != 0)
                    {
                        this.sessionID = this.sessions[0].SmartChargingSessionID
                    }

                })
             },

             LoadCurves(multiplier, power_collapse){
                 axios.post('api/LA/' + this.LoadAreaID + '/LoadCurves', {'multiplier': multiplier, 'power_collapse': power_collapse})
                     .then((response) => {
                         this.refresh();
                         this.Response = response.data;
                         helper_utils.success()
                     }).catch((error) => {
                     // error callback
                     helper_utils.alert(error.response);
                 });
             },
             SetBestCaseScenario(){
                 axios.post('api/LA/' + this.LoadAreaID + '/SetBestCaseScenario')
                     .then((response) => {
                         this.refresh();
                         this.Response = response.data;
                         helper_utils.success();
                     }).catch((error) => {
                     // error callback
                     helper_utils.alert(error.response);
                 });
             },
             SetWorstCaseScenario(){
                 axios.post('api/LA/' + this.LoadAreaID + '/SetWorstCaseScenario')
                     .then((response) => {
                         this.refresh();
                         this.Response = response.data;
                         helper_utils.success();
                     }).catch((error) => {
                     // error callback
                     helper_utils.alert(error.response);
                 });

             }





        },
  beforeDestroy: function() {

  }
}



</script>

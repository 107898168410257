<template>
    <div class="card">
        <h3>Simulation Evos</h3>
        <div class="card-body">
            <div class="row">
                <div class="col-4">

                    <div>
                        <label class="mt-2 control-sidebar-heading">Current time</label>
                        <div class="row">
                            <div class="col-md-12 pt-2">

                                <kendo-datetimepicker class="form-control"
                                                      :default-value="new Date()"
                                                      :format="'yyyy-MM-dd HH:mm:ss'"
                                                      :value="currDateTime.format()"
                                                      @change="updateDateTime"
                                >
                                </kendo-datetimepicker>

                                <md-button class="md-button md-raised md-accent button-no-min" @click.native="set"
                                >Set
                                </md-button>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-4">
                    <label class="control-sidebar-heading">Scheduling</label>
                    <div class="row">
                        <md-button class="md-button md-raised md-accent" @click.native="schedule">
                            <span>run</span>
                        </md-button>

                        <md-button class="md-button md-raised md-warn" @click.native="clear" >
                            <span>clear</span>
                        </md-button>
                    </div>
                </div>

                <div class="col-4">



                    <label class="control-sidebar-heading">Simulation Fast</label>
                    <div class="row">
                        <md-button class="md-button md-raised md-accent" @click.native="startFast">
                            <span>Start</span>
                        </md-button>
                    </div>




                    <label class="control-sidebar-heading">Simulation Real</label>
                    <div class="row">
                        <md-button class="md-button md-raised md-accent" @click.native="startReal" >
                            <span>Start</span>
                        </md-button>


                        <md-button class="md-button md-raised md-accent" @click.native="stop" >
                            <span>stop</span>
                        </md-button>
                    </div>



                </div>
            </div>
            <!--<md-button class="md-icon-button md-raised md-primary" @click="test" :disabled="disabled">
                <md-icon>file_download</md-icon>
                <md-tooltip md-direction="top">get time</md-tooltip>
            </md-button>-->
        </div>
    </div>
</template>

<script>
import Form from "../core/Form";
import {DateTimePicker} from "@progress/kendo-dateinputs-vue-wrapper";
var helper_utils = require('../../utils/helper');
var config = require('../../config');
import moment from 'moment'
export default {
    components: {
        Form,
        'kendo-datetimepicker': DateTimePicker
    },
    props: {
        interval: {
            type: Number,
            required: true,
            default: 5
        }
    },
    mounted() {
        //console.log('SimulationPanel ready.');
        window.onbeforeunload = this.leaving;
        //console.log(this.currDateTime);
        this.get();

    },
    data() {
        return {
            status: false,
            currDateTime: moment(new Date()),
            scheduling_interval: 60,
            firstIteration: false,
            energy: 0,
            power: 0,
            addEnergy: 3,
            addPower:0.01
            //currTimestamp: null
        }
    },
    computed: {
        disabled: function () {
            return !this.status;
        },
        currTimestamp: {
            // getter
            get: function () {
                return this.currDateTime.unix();
            },
            // setter
            set: function (newValue) {
                this.currDateTime = moment.unix(newValue);
            }
        },
        LoadAreaID: function () {
            return this.$store.state.loadArea.id
        },
        running: {
            // getter
            get: function () {
                return this.$store.state.simulation.running
            },
            // setter
            set: function (newValue) {
                this.$store.state.simulation.running = newValue
            }
        },
        scheduling: {
            // getter
            get: function () {
                return this.$store.state.simulation.scheduling
            },
            // setter
            set: function (newValue) {
                this.$store.state.simulation.scheduling = newValue
            }
        }
    },

    methods: {
        updateStatus(enabled){
            this.status = enabled;
            if (enabled) this.set(function() {
                EventBus.$emit("simstatechanged", this.status);
            }.bind(this));
            else this.reset();

        },
        updateDateTime(date) {
            //this.currTimestamp= moment(date.sender._value).unix();
            //console.log(moment(date.sender._value).unix()); //date.sender._value
            this.currTimestamp = moment(date.sender._value).unix();
            //console.log(this.currTimestamp);
            //console.log(moment(this.currDateTime).unix());
        },
        startFast() {
            // compute timestamp
            this.energy = 0
            this.clear(function() {
                this.running = true;
                this.$store.state.simulation.fast = true;
                this.currTimestamp = this.$store.state.loadArea.start;

                this.set(function () {
                    this.stepFast();
                }.bind(this));
            }.bind(this));
        },

        async startReal() {
            // compute timestamp

            this.clear(async function() {
                this.running = true;
                this.timecurrent = moment(new Date()).unix();
                this.firstIteration = true;
                this.currTimestamp = this.$store.state.loadArea.start;
                console.log('start() method started')
                let response = await axios.get('api/evos/getSessions_LA', {params: {'LoadAreaID': this.$store.state.loadArea.id}})
                this.session_ids = response.data
                console.log('The session_ids are: ', response.data)
                for (let i = 0; i < this.session_ids.length; i++) {
                    console.log('Waiting for response from RemoteSmartStart Module for session ', this.session_ids[i])
                    let response = await axios.post('api/evos/remoteSmartStart', { "session_id": this.session_ids[i]} )
                    console.log(response)
                }

                this.set(function () {
                    this.stepReal();
                }.bind(this));

            }.bind(this));
        },

        resolveAfter1Second() {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve('resolved');
                }, 999);
            });
        },

        resolveAfterSecond(sec) {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve('resolved');
                }, sec*1000);
            });
        },

        async executeRightMinute(n) {
            while ((Math.floor(moment(new Date()).unix() / 60) % 5) != n )
            {
                await this.resolveAfter1Second()
                console.log('execute right time: ', (Math.floor(moment(new Date()).unix() / 60) % 5) != n )
            }
            console.log('EXECUTION: ', new Date())
        },

        async stepReal() {
            this.$store.state.iteration += 1
            //await this.resolveAfterSecond(120) //WAIT 2 MINUTES

            //GET ENERGY DATA -----
            await this.executeRightMinute(2)
            console.log('Start Pull Energy Data')

            axios.post('api/evos/pullEnergyDataSessionForLA', { "LoadAreaID": this.$store.state.loadArea.id} )
                .then(response => {
                    console.log('RESPONSE FROM EVOS (PULL ENERGY):', response);
            });

            await this.executeRightMinute(3)

            this.schedule( async function() {  //SCHEDULING -----
                    if (this.firstIteration){
                        this.startscheduling = this.timecurrent;
                        this.firstIteration = false;
                    }
                    if (this.running && this.currTimestamp < this.$store.state.loadArea.end) {
                        //WAIT 2 MINUTES
                        //MODULATE SESSIONS ------
                        axios.post('api/evos/modulateSessionForLA', { "LoadAreaID": this.$store.state.loadArea.id, "Schedule": this.Schedule} )
                            .then(response => {
                                console.log('RESPONSE FROM EVOS (MODULATE SESSION)', response);
                                // code for response
                                //
                                //
                            });
                        this.nextReal();

                        this.set( async function() {
                            if (moment(new Date()).unix() < this.startscheduling + this.scheduling_interval*this.$store.state.samplingTime)
                            {
                                console.log('Next Iteration in ', this.startscheduling + this.scheduling_interval*this.$store.state.samplingTime - moment(new Date()).unix(), ' seconds')
                            }
                            while (moment(new Date()).unix() < this.startscheduling + this.scheduling_interval*this.$store.state.samplingTime)
                            {
                                await this.resolveAfter1Second();
                            }
                            this.startscheduling = moment(new Date()).unix();


                            this.stepReal();

                        }.bind(this));
                    } else {
                        this.stop();
                    }
                }.bind(this)
            );
        },

        async stepFast(){

            //await this.MeterReadings() // USED TO TEST "x0" input variable for the algorithm.
            this.schedule( function() {
                setTimeout(function() {
                    if (this.running && this.currTimestamp < this.$store.state.loadArea.end) {
                        this.nextFast();
                        this.set(function() {this.stepFast();}.bind(this));
                    } else {
                        this.stop();
                    }
                }.bind(this), 5000);
            }.bind(this));
        },
        set(cb) {
            console.log('set() method started. this.currTimestamp is ', new Date(this.currTimestamp*1000).toISOString())
            axios.post('api/Simulation/set', { timestamp: this.currTimestamp} )
                .then(response => {
                    this.status = response.data.status === 1;
                    this.currTimestamp= response.data.timestamp;
                    //console.log('(AFTER SET) timestamp is: ',this.currTimestamp);
                    //console.log('system status: ', this.status);
                    //EventBus.$emit("timechanged");
                    if (cb != null && typeof cb == 'function') cb();
                });
        },
        get() {
            axios.get('api/Simulation/get')
                .then(response => {
                    //console.log(response.data.timestamp);
                    //console.log(this.currDateTime);
                    this.status = (response.data.status == 1);
                    this.currTimestamp = response.data.timestamp;
                    console.log(moment.unix(response.data.timestamp).format());
                    EventBus.$emit("simstatechanged", this.status);
                    EventBus.$emit("timechanged");
                });
        },
        reset() {
            axios.post('api/Simulation/reset')
                .then(response => {
                    this.status = (response.data.status == 1)
                    this.currTimestamp = response.data.timestamp;
                    this.$store.state.loadArea.start = 0
                    EventBus.$emit("simstatechanged", this.status);
                    EventBus.$emit("timechanged");
                });
        },

        add(accumulator, a) {
            //console.log('a is', a)
            //console.log('accumulator is', accumulator)
            return accumulator + a;
        },

        schedule(cb) {
            console.log('scheduling() method started')
            this.$store.state.simulation.computing = true;
            axios.post('api/Simulation/schedule', {
                //InternalSimulation: true, SimulationTime: this.currTimestamp,
                LoadAreaID: this.LoadAreaID
            }, { before : function() {this.scheduling = true;  this.$Progress.start(); } } )
                .then(response => {
                    let ack = response.data.ack;
                    this.Schedule = response.data.Schedule;
                    console.log('RESPONSE FROM SCHEDULING: ', response)
                    if (ack === 0) {
                        this.stop();
                    }
                    else {
                        this.$store.state.simulation.computing = false;
                        this.$Progress.finish();
                        this.scheduling = false;
                        if (cb != null && typeof cb == 'function') cb();
                        EventBus.$emit("timechanged");
                    }
                });

        },
        clear(cb) {
            Swal.fire({
                title: "Warning",
                text: "You'll lose all data.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f44336",
                confirmButtonText: "Continue",
                closeOnConfirm: true
            }).then((result) => {
                if(result.value) {
                    axios.post('api/LA/' + this.$store.state.loadArea.id + '/clear', {
                        AuthToken: "AAA",
                        LoadAreaID: this.LoadAreaID
                    }).then(response => {
                        //axios.post('api/Simulation/set', { timestamp: moment(new Date()).unix()} )
                        //}).then(response => {
                        if (cb != null && typeof cb == 'function') cb();
                        else EventBus.$emit("timechanged");
                    });
                }
            });
        },
        start_delayed() {
            // compute timestamp

            this.clear(function() {
                this.running = true;
                this.currTimestamp = this.$store.state.loadArea.start;

                this.set(function () {
                    setTimeout(function(){
                        this.step();
                    }.bind(this), 10000);
                }.bind(this));
            }.bind(this));

        },
        stop() {
            this.$store.state.iteration = 0;
            this.running = false;
            this.$store.state.simulation.fast = false;
            this.$store.state.computing = false
            this.reset()
        },
        prev() {
            this.currTimestamp -= (config.SLOT_SAMPLE*config.MINUTE);
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        nextReal() {
            this.add =  moment(new Date()).unix() - this.timecurrent;
            this.currTimestamp += this.add;
            this.timecurrent = moment(new Date()).unix();
        },

        nextFast() {
            this.currTimestamp += (this.$store.state.samplingTime*config.MINUTE);
        },

        leaving(e) {
            if (this.running || this.scheduling) {
                e = e || window.event;
                e.preventDefault = true;
                e.cancelBubble = true;
                e.returnValue = 'test';
            }
        },

        async MeterReadings() {
            this.request = this.createMeterReadinglist();
            console.log('REQUEST METER: ', this.request)
            await axios.post('api/CPO/LA_Meter_Readings', this.request).then(
                (response) => console.log('RESPONSE METER: ', response.data)
            )
        },

        createMeterReadinglist() {

            var request = {
                'MeterReadingsList' : []
            }
            for (let i = 1; i <= 5; i++) {

                request['MeterReadingsList'].push(
                    {
                        "SmartChargingSessionID": "1",
                        "RealLoadCurve": {
                            "LoadCurve": [
                                {
                                    "timestamp": new Date((this.currTimestamp -(60*this.$store.state.samplingTime) + 50*i)*1000).toISOString(),
                                    "power": this.energy,
                                    "energy": this.energy + (this.addEnergy/5)*i,
                                    "powerFactor": this.energy + (this.addEnergy/5)*i,
                                    "ConnectorStatus": "Active",
                                    "ThreePhaseVoltage": {
                                        "VoltageL1N": 0,
                                        "VoltageL2N": 0,
                                        "VoltageL3N": 0,
                                        "VoltageL1L2": 0,
                                        "VoltageL2L3": 0,
                                        "VoltageL3L1": 0
                                    },
                                    "ThreePhaseCurrent": {
                                        "CurrentL1": 0,
                                        "CurrentL2": 0,
                                        "CurrentL3": 0
                                    }
                                }
                            ],
                        },
                    }
                )
            }

            console.log('START METER READINGS:', new Date((this.currTimestamp - 60)*1000).toISOString())
            this.energy += this.addEnergy;
            this.power += this.addPower;
            return request;
        }

    }
}

</script>

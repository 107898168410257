<template>
    <div>
        <div class="row">
            <div class="countdown__item">
                <span>Time Remaining to the next scheduling: {{ remaining.minutes }}:{{remaining.seconds}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    props: {
        expiredText: { default: 'Now Expired' }
    },

    data () {
        return {
            now: new Date(),
            until: moment(this.now).add(1, 'm').toDate(),
            started: 0,
            SAMPLING_TIME: ''
        };
    },
    created () {
        this.refreshEverySecond();

    },
    computed: {
        remaining () {

            if (this.started !== this.$store.state.iteration) {
                this.until = moment(new Date()).add(this.$store.state.samplingTime, 'm').toDate();
                this.started = this.$store.state.iteration;
            }
            if (this.until <= new Date())
            {
                this.until = moment(new Date()).add(this.$store.state.samplingTime, 'm').toDate()
            }
            let remaining = moment.duration(Date.parse(this.until) - this.now);
            return {
                total: remaining,
                years: this.pad(remaining.years(),2),
                months: this.pad(remaining.months(),2),
                days: this.pad(remaining.days(),2),
                hours: this.pad(remaining.hours(),2),
                minutes: this.pad(remaining.minutes(),2),
                seconds: this.pad(remaining.seconds(),2)
            };
        }
    },
    methods: {

        pad(num, size) {
            var s = "000000000" + num;
            return s.substr(s.length-size);
        },
        refreshEverySecond () {
            let interval = setInterval(() => this.now = new Date(), 1000);
        }
    }
}
</script>

<template>
    <div ref="scheduler" id="scheduler"></div>
</template>

<script>
    require('../../libs/scheduler');

    export default {
        props: {
            list: {
                default() {
                    return []
                },
            }
        },

        mounted() {
            const cal = $(this.$el),
                self = this

var defaultStartTime = '0:00';
var defaultEndTime = '0:00';
var onClickAppointment = function(payload){
    // Do something with the payload
};

cal.schedulerjs({
    'list': this.list,
    'snapTo': 1,
    'pixelsPerHour': 45,
    'start': defaultStartTime,
    'end': defaultEndTime,
    'headName': 'POD',
    'onClickAppointment': onClickAppointment
});
cal.schedulerjs('toggleSelector');
        },

        watch: {
            list: {
                deep: true,
                handler(val) {
                    $(this.$el).schedulerjs('update', val)
                },
            }
        }
}


</script>

<style>

</style>
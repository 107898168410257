<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12">

                    <div class="card" v-if="$gate.isAdmin()">
                        <div class="card-header">
                            <h3 class="card-title">EV Vehicles List</h3>

                            <div class="card-tools">

                                <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                                    <i class="fa fa-plus-square"></i>
                                    Add New
                                </button>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th>Plate</th>
                                    <th>Model</th>
                                    <th>Max Power(kW)</th>
                                    <th>Min Power(kW)</th>
                                    <th>Battery Capacity</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="EV in EV_vehicles.data" :key="EV.id">
                                    <td class="text-capitalize">{{EV.plate}}</td>
                                    <td class="text-capitalize">{{EV.ev_model_id}}</td>
                                    <td class="text-capitalize">{{EV.max_power}}</td>
                                    <td class="text-capitalize">{{EV.min_power}}</td>
                                    <td>{{EV.battery_capacity}}</td>



                                        <a href="#" @click="editModal(EV)">
                                            <i class="fa fa-edit blue"></i>
                                        </a>
                                        /
                                        <a href="#" @click="deleteEV(EV.id)">
                                            <i class="fa fa-trash red"></i>
                                        </a>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer">
                            <pagination :data="EV_vehicles" @pagination-change-page="getResults"></pagination>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>


            <div v-if="!$gate.isAdmin()">
                <not-found></not-found>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" v-show="!editmode">Create New EV</h5>
                            <h5 class="modal-title" v-show="editmode">Update EV's Info</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <!-- <form @submit.prevent="createUser"> -->

                        <form @submit.prevent="editmode ? updateEV() : createEV()">
                            <div class="modal-body">
                                <div class="form-group">

                                    <label>Model</label>
                                    <select class="form-control" v-model="form.ev_model_id">
                                        <option
                                            v-for="(ev,index) in ev_models" :key="index"
                                            :value="index"
                                            :selected="index == form.ev_model_id">{{ ev }}</option>
                                    </select>
                                    <has-error :form="form" field="model"></has-error>
                                </div>
                                <div class="form-group">
                                    <label>Plate</label>
                                    <input v-model="form.plate" type="text" name="plate"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('plate') }">
                                    <has-error :form="form" field="plate"></has-error>
                                </div>
                                <div class="form-group">
                                    <label>max_power</label>
                                    <input v-model="form.max_power" type="text" name="email"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('max_power') }">
                                    <has-error :form="form" field="email"></has-error>
                                </div>

                                <div class="form-group">
                                    <label>min_power</label>
                                    <input v-model="form.min_power" type="text" name="min_power"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('min_power') }" autocomplete="false">
                                    <has-error :form="form" field="password"></has-error>
                                </div>
                                <div class="form-group">
                                    <label>battery capacity</label>
                                    <input v-model="form.battery_capacity" type="text" name="battery_capacity"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('battery_capacity') }">
                                    <has-error :form="form" field="email"></has-error>
                                </div>

                                <div class="form-group">
                                    <label>Phase type</label>
                                    <select name="three_phase_mode" v-model="form.three_phase_mode" id="three_phase_select" class="form-control" :class="{ 'is-invalid': form.errors.has('three_phase_mode') }">
                                        <option value="">Select Phase</option>
                                        <option value="1">Three Phase</option>
                                        <option value="0">Single Phase</option>
                                    </select>
                                    <has-error :form="form" field="type"></has-error>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                                <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data () {
        return {
            editmode: false,
            EV_vehicles : {},
            form: new Form({
                id: '',
                ev_model_id: '',
                plate: '',
                min_power: '',
                max_power: '',
                battery_capacity: '',
                three_phase_mode: ''
            }),
            ev_models: [],
        }
    },
    methods: {

        getResults(page = 1) {

            this.$Progress.start();

            axios.get('api/EV/vehicles').then(({ data }) => (this.EV_vehicles = data.data));

            this.$Progress.finish();
        },
        updateEV(){
            this.$Progress.start();
            // console.log('Editing data');
            this.form.put('api/EV/vehicles/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.$Progress.finish();
                    //  Fire.$emit('AfterCreate');

                    this.loadEV();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

        },
        editModal(EV){
            this.editmode = true;
            this.form.reset();
            $('#addNew').modal('show');
            this.form.fill(EV);
        },
        newModal(){
            this.editmode = false;
            this.form.reset();
            $('#addNew').modal('show');
        },
        deleteEV(id){
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {

                // Send request to the server
                if (result.value) {
                    this.form.delete('/api/EV/vehicles/'+id).then(()=>{
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        );
                        // Fire.$emit('AfterCreate');
                        this.loadEV();
                    }).catch((data)=> {
                        Swal.fire("Failed!", data.message, "warning");
                    });
                }
            })
        },
        loadEV(){
            this.$Progress.start();

            if(this.$gate.isAdmin()){
                //console.log(this.EVs);
                axios.get("/api/EV/vehicles").then(({ data }) => (this.EV_vehicles = data.data));

            }

            this.$Progress.finish();
        },

        loadEVModel(){
            axios.get("/api/models/list").then(({ data }) => (this.ev_models = data.data));
        },

        createEV(){
            this.$Progress.start();
            this.form.post('api/EV/vehicles')
                .then((response)=>{
                    $('#addNew').modal('hide');

                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });

                    this.$Progress.finish();
                    this.loadEV();

                })
                .catch(()=>{

                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            this.$Progress.failed();
        }

    },
    mounted() {
        console.log('EV Component mounted.')
    },
    created() {

        this.$Progress.start();
        this.loadEV();
        this.loadEVModel();
        this.$Progress.finish();
    }
}
</script>

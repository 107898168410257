<template>
    <section class="content">
        <div class="container-fluid">
            <div>
                <div class="row-12">
                    <div class="card" v-if="$gate.isAdmin()">
                        <div class="card-header">
                            <div class="mb-3">
                                <label for="userFilter">Filter by Planner:</label>
                                <select v-model="selectedUserId" @change="filterByUser">
                                    <option value="">All Planners</option>
                                    <option v-for="user in plannerUsers" :key="user.id" :value="user.id">{{ user.name }}</option>
                                </select>
                            </div>
                            <h3 class="card-title">Runs Failed Data</h3>
                            <i class="fas fa-arrow-left" @click="redirectToAnalytics" style="cursor: pointer; float: right;"></i>
                        </div>
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>User_id</th>
                                    <th>Activity Details</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="run in runs.data" :key="run.id">
                                    <td>{{ run.id }}</td>
                                    <td>{{ run.user_id }}</td>
                                    <td>{{ run.activity_details }}</td>
                                    <td>{{ run.file_contents }}</td>
                                </tr>
                                </tbody>
                            </table>
                            <div class="card-footer">
                                <pagination :data="runs" @pagination-change-page="getResults"></pagination>
                                <button class="btn btn-success" @click="exportToCSV">Export CSV</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {DateTimePicker} from "@progress/kendo-dateinputs-vue-wrapper";
import axios from "axios";
import moment from "moment";

export default {
    components: {
        'kendo-datetimepicker': DateTimePicker
    },
    data() {
        return {
            runs: {},
            plannerUsers: [], // Array to hold users with 'planner' role
            selectedUserId: '', // Selected user ID for filtering
            form: new Form({
                id: '',
                user_id: '',
                activity_details: '',
            }),
        };
    },
    filters: {
        moment: function (date) {
            // Assuming date is in UTC, format it for display in local timezone
            return moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss');
        }
    },
    methods: {
        async fetchPlannerUsers() {
            try {
                const response = await axios.get('/api/user/planners');
                if (response.data) {
                    this.plannerUsers = response.data;
                }
            } catch (error) {
                console.error('Error fetching planner users:', error);
            }
        },
        filterByUser() {
            this.getResults(); // Trigger getResults to apply filter
        },
        async getResults(page = 1) {
            this.loadRunsFailed(page, this.selectedUserId); // Pass the selectedUserId as userId
        },
        async loadRunsFailed(page = 1, userId = '') {
            try {
                const response = await axios.get('/analytics/runsFailedData', {
                    params: {
                        page: page,
                        userId: userId // Ensure this matches the Laravel function
                    }
                });
                this.runs = response.data;
            } catch (error) {
                console.error('Error fetching uploads:', error);
            }
        },
        redirectToAnalytics() {
            this.$router.push('/analytics');
        },
        exportToCSV() {
            // Define the headers for the CSV
            const headers = 'Id,User_id,Status,Activity Type,File Contents,Created At\n';

            // Map each entry in this.runs.data to a CSV row
            const csvData = this.runs.data.map(entry => {
                // Convert each field's value to a string representation
                const id = entry.id;
                const user_id = entry.user_id;

                // Extract nested fields from activity_details if it exists
                const status = entry.activity_details && entry.activity_details.status ? entry.activity_details.status : '';
                const activity_type = entry.activity_details && entry.activity_details.activity_type ? entry.activity_details.activity_type : '';

                const file_contents = entry.file_contents ? JSON.stringify(entry.file_contents) : ''; // Ensure file_contents is properly handled
                const created_at = entry.created_at ? moment(entry.created_at).format('YYYY-MM-DD hh:mm:ss') : ''; // Format created_at properly

                // Return the CSV row as a string
                return `${id},${user_id},${status},${activity_type},${file_contents},${created_at}`;
            }).join('\n');

            // Create a Blob containing the CSV data
            const blob = new Blob([headers + csvData], {type: 'text/csv'});

            // Create a temporary URL for the Blob
            const url = window.URL.createObjectURL(blob);

            // Create a temporary <a> element to trigger the download
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'runsFailed_data.csv'; // Adjust the filename as needed
            document.body.appendChild(a);
            a.click();

            // Clean up by revoking the temporary URL
            window.URL.revokeObjectURL(url);
            }
    },
    created() {
        this.loadRunsFailed();
        this.fetchPlannerUsers();
    }
}
</script>

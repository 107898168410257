<template>

    <div>

        <div class="container-fluid">

            <md-card-header>
                <div class="pull-left md-title">LM Charging Request Notification</div>

            </md-card-header>

            <form>
                <div class="row">
                    <div class="col" >
                        <label>Smart Charging SessionID</label>
                        <div class="input-group">
                            <input class="form-control" v-model="SmartChargingSessionID" type="number"></input>
                        </div>
                    </div>

                    <div class="col">
                        <div class="form-group">
                            <label>Load Area ID</label>
                            <input type="text" class="form-control" :disabled=true v-model="Request.LoadAreaID" :placeholder="Request.LoadAreaID">
                            </input>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <label>POD ID</label>
                        <div class="input-group">
                            <select class="form-control" v-model="Request.PODID"  v-bind:disabled="Request.LoadAreaID == ''">
                                <option v-for="(pod, index) in PODs" v-bind:value="pod.PODID">{{ pod.PODID }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col">
                        <label>Event Type</label>
                        <div class="form-check">
                            <input class="form-check-input" v-model="Request.ChargingSessionEventType" type="radio" name="flexRadioDefault1" id="flexRadioDefault1" checked
                                   value="1"
                                   unchecked-value="0">
                            <label class="form-check-label" for="flexRadioDefault1">
                                Start
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" v-model="Request.ChargingSessionEventType" type="radio" name="flexRadioDefault2" id="flexRadioDefault2"
                                   value="0"
                                   unchecked-value="1">
                            <label class="form-check-label" for="flexRadioDefault2">
                                Stop
                            </label>
                        </div>
                    </div>

                </div>

                <div class="row">

                    <div class="col" style="width: 5px">
                        <label>Start of Charging</label>
                        <kendo-datetimepicker class="form-control"
                                              :default-value="new Date()"
                                              :format="'yyyy-MM-dd HH:mm:ss'"
                                              :value="Request.UserChargingRequest.StartOfCharging"
                                              @change="timeFormatStart"
                                              style="width: 230px"
                        >
                        </kendo-datetimepicker>

                        <!--date-picker v-model:value="Request.UserChargingRequest.StartOfCharging" type="datetime"></date-picker-->
                    </div>

                    <div class="col" style="width: 5px">
                        <label>Departure Time</label>

                            <kendo-datetimepicker class="form-control"
                                                  :default-value="new Date()"
                                                  :format="'yyyy-MM-dd HH:mm:ss'"
                                                  :value="Request.UserChargingRequest.DesiredDepartureTime"
                                                  @change="timeFormatStop"
                                                  style="width: 230px"
                            >
                            </kendo-datetimepicker>

                        <!--date-picker v-model:value="Request.UserChargingRequest.DesiredDepartureTime" type="datetime"></date-picker-->



                    </div>
                </div>

                <div class="row">

                    <div class="col">
                        <label> Initial SOC (%)</label>
                            <div class="input-group">
                                <input class="form-control" required v-model="InitialSOC" type="number" min="0" :max="DesiredSOC"
                                          step="1" @input="updateSOCs"></input>
                            </div>
                    </div>

                    <div class="col">
                        <label>Desired SOC (%)</label>
                        <div class="input-group">
                            <input class="form-control" required v-model="DesiredSOC" :min="InitialSOC" max="100" type="number"
                                      step="1" @input="updateSOCs"></input>
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col">
                        <label> Maximum Power (kW)</label>
                            <div class="input-group">
                                <input class="form-control" required v-model="Request.SessionSettings.MaximumPower" type="number"
                                         step="0.1"></input>
                            </div>
                    </div>

                    <div class="col">
                        <label>Minimum Power (kW)</label>
                            <div class="input-group">
                                <input class="form-control" required v-model="Request.SessionSettings.MinimumPower" type="number"
                                          step="0.1"></input>
                            </div>
                    </div>
                </div>

                <div class="row" v-show="this.class_name == 'MCAAlgorithm'">
                    <div class="col">
                        <label> Maximum Power V2G (kW)</label>
                        <div class="input-group">
                            <input class="form-control" required v-model="Request.SessionSettings.Max_Power_V2G" type="number"
                                   step="0.1"></input>
                        </div>
                    </div>

                    <div class="col">
                        <label>Minimum Power V2G (kW)</label>
                        <div class="input-group">
                            <input class="form-control" required v-model="Request.SessionSettings.Min_Power_V2G" type="number"
                                   step="0.1"></input>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <label>Battery Capacity (kWh)</label>
                        <div class="input-group">
                            <input class="form-control" required v-model="Request.SessionSettings.BatteryCapacity" type="number"
                                      step="0.1" @input="updateSOCs"></input>
                        </div>
                    </div>

                    <div class="col">
                        <label>Mode</label>
                            <div class="form-check">
                                <input class="form-check-input" v-model="Request.SessionSettings.ACDCMode" type="radio" name="flexRadioDefault3" id="flexRadioDefault3" checked
                                       value="0"
                                       unchecked-value="1"

                                >
                                <label class="form-check-label" for="flexRadioDefault3">
                                    AC Single-Phase
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" v-model="Request.SessionSettings.ACDCMode" type="radio" name="flexRadioDefault4" id="flexRadioDefault4"
                                       value="1"
                                       unchecked-value="0"

                                >
                                <label class="form-check-label" for="flexRadioDefault4">
                                    AC Three-phase
                                </label>
                            </div>
                        <div class="form-check">
                            <input class="form-check-input" v-model="Request.SessionSettings.ACDCMode" type="radio" name="flexRadioDefault4" id="flexRadioDefault4"
                                   value="2"
                                   unchecked-value="0"

                            >
                            <label class="form-check-label" for="flexRadioDefault4">
                                DC Charging
                            </label>
                        </div>
                    </div>
                </div>
            </form>

            <md-button class="md-raised md-accent pull-right" @click.native="submitData">Submit
            </md-button>
        </div>

        <div class="row">
            <textarea class="col-md-6" style="min-height: 150px; background-color: #eeeeee"
                      readonly>{{ Request }}</textarea>
            <textarea class="col-md-6" style="min-height: 150px; background-color: #eeeeee"
                      readonly>{{ Response }}</textarea>
        </div>


    </div>

</template>

<script>
    import {DateTimePicker} from "@progress/kendo-dateinputs-vue-wrapper";

    import moment from 'moment'
    import Form from "../core/Form";
    var chart_utils = require('../../utils/chart');
    var curve_utils = require('../../utils/curve');
    var helper_utils = require('../../utils/helper');
    var config = require('../../config')

    export default {
        components: {
            Form,
            'kendo-datetimepicker': DateTimePicker
        },
        ready() {

        },
        /**
         * Prepare the component (Vue 2.x).
         */
        mounted() {
            this.getLoadAreas();
            this.Request.LoadAreaID = this.$store.state.loadArea.id;
            this.class_name = this.$store.state.loadArea.class_name;
            this.departureTime = new Date();
            this.departureTime.setHours( this.departureTime.getHours() + 5 );
            this.Request.UserChargingRequest.StartOfCharging = moment(new Date()).format();
            this.Request.UserChargingRequest.DesiredDepartureTime = moment(this.departureTime).format();
            //this.updatePODs();
            console.log('this.date', this.date)
            EventBus.$on('applicationchanged', () => {
                this.getLoadAreas();
                });
        },
        created(){
            //console.log(this.LoadAreas);
            this.SmartChargingSessionID = this.generateID(100, 10000);
        },
        data() {
            return {

                PODs: [],
                InitialSOC: 20,
                DesiredSOC: 99,
                SmartChargingSessionID: "",
                class_name:"",
                Request : {
                            SmartChargingSessionID: "",
                            LoadAreaID: "",
                            PODID: "",
                            ChargingSessionEventType: "1",
                            UserChargingRequest:
                                {
                                StartOfCharging: null,
                                DesiredDepartureTime: null,
                                InitialSOC: "19.2",
                                DesiredEnergyLevel: "80",
                                Smartness: "1"
                                },
                            ElectricityTariff: { energyTariffCurve: [] },
                            SessionSettings:
                                {
                                MaximumPower: "22",
                                MinimumPower: "3",
                                    Max_Power_V2G: "3",
                                    Min_Power_V2G: "0",
                                BatteryCapacity: "80",
                                    ACDCMode: "1"
                                }
                          },
                Response : {},
                LoadAreas: [],
                Algorithm: []
                }
         },
         watch: {
            'SmartChargingSessionID': {
                handler (newData, oldData) {
                     this.Request.SmartChargingSessionID = newData.toString();
                 }
            }
         },
         methods: {
            timeFormatStart(event){
                console.log("EIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIII")
                this.Request.UserChargingRequest.StartOfCharging = moment(event.target.value).format();

            },
            timeFormatEnd(event){
                console.log("EIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIII")

                this.Request.UserChargingRequest.DesiredDepartureTime = moment(event.target.value).format();
            },
            /*
            onOpen: function (e) {
                console.log("DateTimePicker is opened");
            },
            onClose: function (e) {
                console.log("DateTimePicker is closed");
            },
            */
            reset() {
                this.SmartChargingSessionID = this.generateID(100, 10000);
            },
            updateSOCs(){
                this.Request.UserChargingRequest.InitialSOC = helper_utils.soc2kw(this.InitialSOC, this.Request.SessionSettings.BatteryCapacity);
                this.Request.UserChargingRequest.DesiredEnergyLevel = helper_utils.soc2kw(this.DesiredSOC, this.Request.SessionSettings.BatteryCapacity);

            },
            generateID(min, max) {
                min = Math.ceil(min);
                max = Math.floor(max);
                return Math.floor(Math.random() * (max - min)) + min;
            },
            /*
            updateStartOfCharging(date){
               this.Request.UserChargingRequest.StartOfCharging = moment(date).format();
            },
            updateDesiredDepartureTime(date){
               this.Request.UserChargingRequest.DesiredDepartureTime = moment(date).format();
            },
            */
            updatePODs(){

                this.Request.PODID = "";
                var tmp = [];

                for (var i=0; i < this.LoadAreas.length; i++){
                    var LoadArea = this.LoadAreas[i];
                    if (LoadArea.LoadAreaID === this.Request.LoadAreaID){
                         this.PODs = LoadArea.PODList;
                        }
                }
                console.log(this.PODs);



            },
            getLoadAreas() {
                axios.get('api/LA/getPodsLoadAreaID')
                        .then(response => {
                            this.LoadAreas = response.data.data;
                            this.updatePODs();
                        });



            },
            prepareComponent() {

            },
            submitData() {
                axios.post('api/CPO/LM_Charging_Request_Notification', this.Request)
                        .then((response) => {
                        this.Response = response.data;
                        helper_utils.success();
                        EventBus.$emit("timechanged");
                        // success callback
                }).catch((error) => {
                        // error callback
                        //this.Response = response.data;
                        //console.log(error.response);
                        helper_utils.alert(error.response);
                });
            },


        }

    }







</script>
